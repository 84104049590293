import React from 'react';
import Select from 'react-select';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import Loader from 'views/components/loader/Loader';
import buildUrl from 'build-url';
import Pagination from 'views/components/pagination/CustomPagination';

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Row, Table as RSTable
} from 'reactstrap';
import { requestGet } from 'utils/fetchHelper';

import ConfirmAlert from 'views/components/alert/confirmAlert/ConfirmAlert';
import MiniAlert from 'views/components/alert/miniAlert/MiniAlert';

import CompanyFilter from 'views/components/searchFilter/CompanyFilter';
import DepartmentFilter from 'views/components/searchFilter/DepartmentFilter';

import { isValidResponse } from 'utils/responseValidChecker';

import { inject, observer } from 'mobx-react';

@inject('searchFilter', 'deviceAuthSummary', 'confirmAlertModal', 'miniAlertModal', 'loader', 'table')
@observer
class DeviceAuthSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPeriodDay: 5
    };
    moment.updateLocale(moment.locale(), { invalidDate: '' });
  }

  async componentDidMount() {
    const { searchFilter } = this.props;
    searchFilter.companyList.length === 0 && (await searchFilter.getCompanyList());

    this.fetchRefresh();
  }

  componentWillUnmount() {
    const { searchFilter, deviceAuthSummary } = this.props;
    searchFilter.clear();
    deviceAuthSummary.set('data', null);
  }

  // 인증 현황 조회 API 호출
  fetchRefresh = async () => {
    const { searchFilter, miniAlertModal, loader, deviceAuthSummary } = this.props;
    loader.show(true);

    let queryParams = {};
    queryParams.companyId = searchFilter.selectCompany.value;
    searchFilter.selectDepartment.value !== '' && (queryParams.departmentId = searchFilter.selectDepartment.value);
    queryParams.startDate = moment().subtract(this.state.searchPeriodDay - 1, 'days').format('YYYY-MM-DD');
    queryParams.endDate = moment().format('YYYY-MM-DD');

    console.log('[searchPeriodDay]' + this.state.searchPeriodDay);
    console.log('[startDate]' + queryParams.startDate);
    console.log('[endDate]' + queryParams.endDate);

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/v1/admin/api/auth/summary/devices',
      queryParams: queryParams
    });

    let responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    deviceAuthSummary.set('data', responseData.data);
  };

  // 구간 버튼 클릭 이벤트 처리
  handleDateButtonClick = async (e, range) => {
    const { searchFilter } = this.props;

    let from = moment().subtract(range, 'days').format('YYYY-MM-DD');
    let to = moment().format('YYYY-MM-DD');
    await this.setState({
      searchPeriodDay: range
    });

    await searchFilter.set('startDate', from);
    await searchFilter.set('endDate', to);

    this.fetchRefresh();
  };

  // 검색 버튼 클릭 이벤트
  handleSearchClick = async () => {
    this.fetchRefresh();
  };

  render() {
    let { confirmAlertModal, miniAlertModal, loader, deviceAuthSummary } = this.props;

    return (
      <>
        {loader.showLoader && <Loader></Loader>}
        <div className="content">
          <h4 className="__marginTop">지점별 단말 인증현황 집계</h4>
          <h6>최근 일주일치의 지점별 단말 인증시도/성공/실패 건수 및 단말인증실패율을 확인할 수 있습니다.</h6>
          <Card className="card-stats">
            <CardBody className="__padding">
              <Form className="form-horizontal">
                <Row md="12">
                  <Col md={{ size: 10, offset: 1 }} className="text-center">
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        회사명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <CompanyFilter refreshData={this.fetchRefresh}></CompanyFilter>
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        지점명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <DepartmentFilter refreshData={this.fetchRefresh}></DepartmentFilter>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>

                      <Col md="12">
                        <ButtonGroup data-toggle="buttons">
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '1');
                            }}
                          >
                            today
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '3');
                            }}
                          >
                            3 일
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '5');
                            }}
                          >
                            5 일
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '7');
                            }}
                          >
                            7 일
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '10');
                            }}
                          >
                            10 일
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="12">
                        <Button className="btn-round __marginTop" color="primary" onClick={this.handleSearchClick}>
                          조회
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="form-horizontal __marginBottom5">
                <Col md="12" className="text-right"></Col>
              </Row>
              <Row className="form-horizontal">
                <Col md="12">
                  <RSTable bordered striped>
                    <thead>
                      <tr className="table-header text-center underline-dategroup-tr">
                        <th rowSpan="2">
                          <span style={{ whiteSpace: 'pre-line' }}>순번</span>
                        </th>
                        <th rowSpan="2">
                          <span style={{ whiteSpace: 'pre-line' }}>지점</span>
                        </th>
                        <th className="divider-dategroup-td" rowSpan="2">
                          <span style={{ whiteSpace: 'pre-line' }}>등록단말수</span>
                        </th>
                        {this.renderDateTableHeader()}
                      </tr>
                      <tr className="table-header text-center underline-dategroup-tr">{this.renderDateSubColTableHeader()}</tr>
                    </thead>
                    <tbody className="tbody-fontsize-sm">
                      {deviceAuthSummary.data !== null ? this.renderTable(deviceAuthSummary.data) : null}
                    </tbody>
                  </RSTable>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col lg="12" xl="12" className="text-left" style={{ fontSize: '14px', marginTop: '5px' }}>
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {`
                      - 지점명순으로 조회됩니다
                      - 기본적으로 현재일 기준, 이전 5일치의 정보가 조회됩니다. ( 현재일/3일/5일/7일/10일전 조회가능 )
                      - 실패율(%) = 실패단말수 / 시도단말수 * 100 (실패율이 80%이상인 경우, 붉은색으로 표시됩니다.)
                    `}
                  </span>
                  <span style={{ whiteSpace: 'pre-line', color: 'red' }}>
                    {` 
                      - 실패한 단말수가 등록된 단말수에 근접하고, 실패율이 100%인 경우에는 해당지점의 AP점검이 필요하니 고객센터로 연락 주시기 바랍니다.
                    `}
                  </span>
                </Col>
              </Row>
            </CardFooter>
          </Card>

          {/* 알림 팝업 */}
          {miniAlertModal.isOpen && <MiniAlert />}

          {/* 사용자 확인 알림 모달 */}
          {confirmAlertModal.isOpen && <ConfirmAlert />}
        </div>
      </>
    );
  }

  renderDateTableHeader = () => {
    const periodDateList = [];

    // console.log('[searchPeriodDay]' + this.state.searchPeriodDay);

    for (let index = this.state.searchPeriodDay; index > 0; index--) {
      let date = moment()
        .subtract(index - 1, 'd')
        .format('YYYY/MM/DD');
      periodDateList.push(date);
    }

    const tableHeader = periodDateList.map((date, index) => {
      return (
        <th key={index} className="divider-dategroup-td underline-dategroup-td" colSpan="3">
          <span style={{ whiteSpace: 'pre-line' }}>{date}</span>
        </th>
      );
    });

    return tableHeader;
  };

  renderDateSubColTableHeader = () => {
    const dateColList = [];

    for (let index = 0; index < this.state.searchPeriodDay; index++) {
      dateColList.push(
        <>
          <th>
            <span style={{ whiteSpace: 'pre-line' }}>시도</span>
          </th>
          <th>
            <span style={{ whiteSpace: 'pre-line' }}>실패</span>
          </th>
          <th className="divider-dategroup-td">
            <span style={{ whiteSpace: 'pre-line' }}>실패율</span>
          </th>
        </>
      );
    }

    return dateColList;
  };

  renderTable = data => {
    const rowData = data.map((row, index) => {
      return (
        <tr className="text-center" key={index}>
          <td>{(index + 1).toString()}</td>
          <td style={{ fontWeight: 'bold' }}>{row.departmentName}</td>
          <td className="divider-dategroup-td" style={{ fontWeight: 'bold' }}>{row.registPhoneCount}</td>
          {this.authInfoPerDate(row.dailyAuthSummaryList)}
        </tr>
      );
    });

    return rowData;
  };

  authInfoPerDate = deptAuthData => {
    const array = [];

    for (let index = this.state.searchPeriodDay; index > 0; index--) {
      let date = moment()
        .subtract(index - 1, 'd')
        .format('YYYYMMDD');

      let authData = deptAuthData
        .filter(authData => {
          return authData.date === date;
        })
        .map(authData => {
          return authData;
        });

      if (authData.length === 0) {
        array.push(
          <>
            <td>-</td>
            <td>-</td>
            <td className="divider-dategroup-td">-</td>
          </>
        );
      } else {
        let failRateTextColor = '#35a6ff';
        let countTextColor = '';
        let failRateFontWeight = '';

        const failRate = authData[0].failRate.toFixed(0);

        if (failRate >= 80) {
          failRateTextColor = '#FF9C2A';
          countTextColor = failRateTextColor;
          failRateFontWeight = 'bold';
        }
        if (failRate >= 90) {
          failRateTextColor = '#FF803E';
          countTextColor = failRateTextColor;
        }
        if (failRate >= 100) {
          failRateTextColor = '#FF5F49';
          countTextColor = failRateTextColor;
        }

        array.push(
          <>
            <td>
              <span style={{ whiteSpace: 'pre-line', color: countTextColor, fontWeight: failRateFontWeight }}>{authData[0].tryPhoneCount}</span>
            </td>
            <td>
              <span style={{ whiteSpace: 'pre-line', color: countTextColor, fontWeight: failRateFontWeight }}>{authData[0].failPhoneCount}</span>
            </td>
            <td className="divider-dategroup-td" >
              <span style={{ whiteSpace: 'pre-line', color: failRateTextColor, fontWeight: failRateFontWeight }}>{authData[0].failRate.toFixed(2)}</span>
            </td>
          </>
        );
      }
    }

    return array;
  };
}

export default DeviceAuthSummary;
