import React from 'react';
import classnames from 'classnames';
import { NavbarBrand, Navbar, Container } from 'reactstrap';

import logo from 'assets/images/commons/logo/logo-blue.png';

class IndexNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: 'navbar-transparent'
    };
  }
  render() {
    return (
      <>
        <Navbar className={classnames('__RootNavbar')} expand="lg" color="white">
          <Container>
            <div>
              <NavbarBrand href="/">
                <img src={logo} alt="logo" />
              </NavbarBrand>
            </div>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default IndexNavbar;
