import { observable, action } from 'mobx';

// 지점 관리
export class MobxStore {
  constructor() {
    this.data = null;
    this.selectedData = [];
    this.registerDepartment = {
      companyId: '',
      name: '',
      code: '',
      wifi: '',
      lat: 0.0,
      lon: 0.0,
      address: '',
      addressDetail: '',
      roadAddress: '',
      roadAddressDetail: '',
      zip: '',
      superPassYn: 'N'
    };
    this.editDepartment = {
      companyId: '',
      departmentId: '',
      name: '',
      code: '',
      wifi: '',
      lat: 0.0,
      lon: 0.0,
      address: '',
      addressDetail: '',
      roadAddress: '',
      roadAddressDetail: '',
      zip: '',
      superPassYn: 'N'
    };
    this.registerModalIsOpen = false;
    this.modifyModalIsOpen = false;
  }

  @observable data; // 지점 데이터
  @observable selectedData; // 선택 지점 데이터
  @observable registerDepartment; // 지점 등록
  @observable editDepartment; // 지점 수정
  @observable registerModalIsOpen; // 등록 모달 토글
  @observable modifyModalIsOpen; // 수정 모달 토글

  // 등록 데이터셋 초기화
  @action clearRegisterDepartment = () => {
    this.registerDepartment = {
      companyId: '',
      name: '',
      code: '',
      wifi: '',
      lat: '',
      lon: '',
      address: '',
      addressDetail: '',
      roadAddress: '',
      roadAddressDetail: '',
      zip: '',
      superPassYn: 'N'
    };
  };
ㅑ
  // 등록&수정 데이터 변경
  @action setDepartment = (objName, key, value, newObj) => {
    if (newObj !== undefined && newObj !== null) {
      this[objName] = newObj;
    } else {
      this[objName] = {
        ...this[objName],
        [key]: value
      };
    }
  };

  // 선택 지점 데이터 리스트에 추가
  @action addSelectedData = rowId => {
    this.selectedData = this.selectedData.concat({
      id: rowId
    });
  };

  // 선택 지점 데이터 리스트에서 제거
  @action deleteSelectedData = rowId => {
    this.selectedData = this.selectedData.filter(data => data.id !== rowId);
  };

  @action set = (key, value) => {
    this[key] = value;
  };
}
