import React from 'react';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import Logger from 'utils/logger';

@inject('searchFilter')
@observer
class DepartmentFilter extends React.Component {
  handleSelectChange = async e => {
    const { searchFilter } = this.props;
    const department = e;
    searchFilter.set('selectDepartment', {
      label: department.label,
      value: department.value
    });
    await searchFilter.set('page', 1);
    await this.props.refreshData();
  };

  render() {
    const { searchFilter } = this.props;
    return (
      <FormGroup>
        <Select
          {...(searchFilter.departmentList.length > 0 ? '' : { isDisabled: true })}
          className="react-select text-left"
          classNamePrefix="react-select"
          name="selectDepartment"
          value={searchFilter.selectDepartment}
          onChange={this.handleSelectChange}
          options={searchFilter.departmentList}
          placeholder="지점을 선택하세요."
        />
      </FormGroup>
    );
  }
}

DepartmentFilter.defaultProps = {
  refreshData: () => {
    Logger.log('[DepartmentFilter] - refresh function is undefined');
  }
};

export default DepartmentFilter;
