import React from 'react';
// javascript plugin used to create scrollbars on windows
import { Route, Switch } from 'react-router-dom';

import CategoryNavbar from 'src/views/layouts/category/CategoryNavbar';
import Sidebar from 'views/components/sidebar/Sidebar.jsx';

// import routes from 'routes.js';
// import nroutes from '../noSidebarRoutes.js';

import routesByAuth from 'commons/routes/routesByAuth.js';
import nroutesByAuth from 'commons/routes/noSidebarRoutesByAuth.js';

class CategoryLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: 'custom',
      activeColor: 'success',
      sidebarMini: false,
      routes: routesByAuth(),
      nroutes: nroutesByAuth()
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
    }
  }

  async componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
    await this.setState({
      ...this.state,
      routes: [],
      nroutes: []
    });
  }

  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      // document.documentElement.scrollTop = 0;
      // document.scrollingElement.scrollTop = 0;
      // this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = (routes, path) => {
    const concatRouters = this.state.nroutes.concat(routes);
    return concatRouters.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === path) {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => <prop.component endPoint={this.props.endPoint} {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };

  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };

  handleMiniClick = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle('sidebar-mini');
  };

  render() {
    const { path } = this.props;
    if (localStorage.getItem('token') !== null && localStorage.getItem('token') !== '') {
      return (
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={this.state.routes.filter(prop => prop.layout === path)}
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
          />
          <div className="main-panel" ref="mainPanel">
            <CategoryNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
            <Switch>{this.getRoutes(this.state.routes, path)}</Switch>
          </div>
        </div>
      );
    } else {
      this.props.history.push({
        pathname: '/login/in'
      });
      return null;
    }
  }
}

export default CategoryLayout;
