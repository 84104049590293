import React from 'react';
import Select from 'react-select';
import Loader from 'views/components/loader/Loader';
import buildUrl from 'build-url';
import Pagination from 'views/components/pagination/CustomPagination';
import { Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { requestDelete, requestGet } from 'utils/fetchHelper';

import RegisterUnAuthDeviceModal from './Register';

import ConfirmAlert from 'views/components/alert/confirmAlert/ConfirmAlert';
import MiniAlert from 'views/components/alert/miniAlert/MiniAlert';
import ToastAlert from 'views/components/alert/toastAlert/ToastAlert';

import CompanyFilter from 'views/components/searchFilter/CompanyFilter';

import { UNAUTH_TABLE_HEADER } from 'commons/conts/tableHeader';
import Table from 'views/components/table/checkbox/table';

import { isValidResponse } from 'utils/responseValidChecker';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import TotalCount from 'views/components/common/totalCount/TotalCount';
import { getUserType } from 'utils/tokenHelper';

@inject('searchFilter', 'unAuth', 'confirmAlertModal', 'miniAlertModal', 'toastAlert', 'loader', 'table')
@observer
class UnAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { searchFilter } = this.props;
    searchFilter.set('page', 1);
    searchFilter.companyList.length === 0 && (await searchFilter.getCompanyList());
    this.fetchRefresh();
  }

  componentWillUnmount() {
    const { unAuth, searchFilter, table } = this.props;
    unAuth.clearRegisterDevices();
    table.set('selectedData', []);
    searchFilter.clear();
  }

  // 미등록 디바이스 조회 API 호출
  fetchRefresh = async () => {
    const { searchFilter, miniAlertModal, loader, unAuth } = this.props;
    loader.show(true);

    let queryParams = {};
    queryParams.companyId = searchFilter.selectCompany.value;
    searchFilter.phone !== '' && (queryParams.phone = searchFilter.phone);
    queryParams.useYn = 'H';
    queryParams.sort = 'desc';
    queryParams.page = searchFilter.page !== '' ? searchFilter.page : 1;
    queryParams.size = searchFilter.pageSize.value !== '' ? searchFilter.pageSize.value : 10;

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/v1/admin/api/devices',
      queryParams: queryParams
    });

    const responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    unAuth.set('data', responseData.data);
  };

  // 등록 버튼 이벤트 처리
  handleRegisterButtonClick = async () => {
    const { unAuth, table, miniAlertModal } = this.props;
    await unAuth.clearRegisterDevices();
    if (toJS(table.selectedData).length === 0) {
      miniAlertModal.show('등록할 디바이스를 선택해주세요.');
    } else {
      table.selectedData.map(data => {
        unAuth.addRegisterDevices({
          deviceId: data.deviceId,
          department: '',
          date: data.date,
          telecom: data.telecom,
          phone: data.phone
        });
      });
      unAuth.set('registerModalIsOpen', !unAuth.registerModalIsOpen);
    }
  };

  // 삭제 버튼 클릭 이벤트 처리
  handleDeleteButtonClick = async () => {
    const { table, miniAlertModal, confirmAlertModal } = this.props;
    if (toJS(table.selectedData).length === 0) {
      miniAlertModal.show('삭제할 디바이스를 선택해주세요.');
    } else {
      confirmAlertModal.show(
        '선택한 데이터를 삭제합니다.\n',
        '삭제된 데이터는 복원할 수 없습니다.\n그래도 삭제 하시겠습니까?',
        '',
        this.fetchDeleteDevices
      );
    }
  };

  // 디바이스 삭제 API 호출
  fetchDeleteDevices = async () => {
    const { table, miniAlertModal, loader, searchFilter } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/devices';
    const body = {
      devices: toJS(table.selectedData)
    };
    const responseData = await requestDelete(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      miniAlertModal.show('삭제되었습니다.');
      await searchFilter.set('page', 1);
      this.fetchRefresh();
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  // 데이터 테이블 클릭 - 디바이스 등록
  handleEditRowClick = async data => {
    if (getUserType().slice(2, 4) === 'RO') {
      return;
    }
    const { unAuth } = this.props;
    await unAuth.clearRegisterDevices();
    const editData = {
      deviceId: data.deviceId,
      department: '',
      date: data.date,
      phone: data.phone,
      telecom: data.telecom
    };
    unAuth.addRegisterDevices(editData);
    unAuth.set('registerModalIsOpen', !unAuth.registerModalIsOpen);
  };

  // 검색 버튼 클릭 이벤트
  handleSearchClick = async () => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    this.fetchRefresh();
  };

  // 페이징 컴포넌트 콜백 함수
  handlePaginationCallback = async page => {
    const { searchFilter } = this.props;
    searchFilter.set('page', page);
    await this.fetchRefresh();
  };

  // 페이지 당 검색 결과 수 변경 이벤트 함수
  handlePageSizeChanged = async e => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    await searchFilter.setPageSize(e);
    await this.fetchRefresh();
  };

  render() {
    let { confirmAlertModal, miniAlertModal, loader, unAuth, searchFilter, toastAlert } = this.props;
    return (
      <>
        {loader.showLoader && <Loader></Loader>}
        <div className="content">
          <h4>미등록 디바이스 관리</h4>
          <h6>미 등록된 디바이스의 정보를 확인 후, 추가하실 수 있습니다.</h6>
          <Card className="card-stats">
            <CardBody className="__padding">
              <Form className="form-horizontal">
                <Row md="12">
                  <Col md={{ size: 10, offset: 1 }} className="text-center">
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        회사명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <CompanyFilter refreshData={this.fetchRefresh} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="12">
                        <Button className="btn-round" color="primary" onClick={this.handleSearchClick}>
                          조회
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="form-horizontal __marginBottom5">
                <Col md="12" className="text-right">
                  <div className="__inline-div">
                    <Select
                      className="react-select __pageSelect"
                      classNamePrefix="react-select"
                      name="pageSize"
                      placeholder=""
                      value={searchFilter.pageSize}
                      options={searchFilter.pageSizeList}
                      onChange={this.handlePageSizeChanged}
                    />
                    <Label className="label-page">페이지 사이즈</Label>
                    <TotalCount totalElements={unAuth.data && unAuth.data.totalElements} />
                  </div>
                </Col>
              </Row>
              <Row className="form-horizontal">
                <Col md="12">
                  <Table header={UNAUTH_TABLE_HEADER} data={unAuth.data} handleRowClick={this.handleEditRowClick} />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={{ offset: 3, size: 6 }}>
                  {unAuth.data && (
                    <Pagination
                      currentPage={searchFilter.page}
                      totalPages={unAuth.data.totalPages}
                      pageSize={unAuth.data.pageable.pageSize}
                      callback={this.handlePaginationCallback}
                    />
                  )}
                </Col>
                {getUserType().slice(2, 4) !== 'RO' && (
                  <Col lg="12" xl="12" className="text-right">
                    <div className="__inline-div __button-margin">
                      <Button
                        className="btn-round __marginTop"
                        onClick={this.handleRegisterButtonClick}
                        color="primary"
                      >
                        등록
                      </Button>
                    </div>
                    <div className="__inline-div __button-margin">
                      <Button className="btn-round __marginTop" onClick={this.handleDeleteButtonClick} color="primary">
                        삭제
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>
            </CardFooter>
          </Card>
        </div>

        {/* 등록 모달 */}
        {unAuth.registerModalIsOpen && <RegisterUnAuthDeviceModal {...this.props} fetchRefresh={this.fetchRefresh} />}

        {/* 알림 팝업 */}
        {miniAlertModal.isOpen && <MiniAlert />}

        {/* 사용자 확인 알림 모달 */}
        {confirmAlertModal.isOpen && <ConfirmAlert />}

        {/* 토스트 메시지 팝업 */}
        {toastAlert.isOpen && <ToastAlert />}
      </>
    );
  }
}

export default UnAuth;
