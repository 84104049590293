import React from 'react';
import loader from 'assets/images/commons/loader/loader.gif';
const Loader = () => {
  return (
    <div className="__loader">
      <img src={loader} alt="loader" className="__loaderImg" />
    </div>
  );
};

export default Loader;
