import React from 'react';
import Select from 'react-select';
import { Button, Modal, Table } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { autoPhoneFormatter } from 'utils/fomatter';
import Logger from 'utils/logger';
import { requestPut } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';

// 디바이스 등록 모달
@inject('unAuth', 'searchFilter', 'miniAlertModal', 'loader')
@observer
class RegisterUnAuthDeviceModal extends React.Component {
  toggleModal = () => {
    const { unAuth } = this.props;
    unAuth.set('registerModalIsOpen', !unAuth.registerModalIsOpen);
  };

  // 모달 내 테이블 데이터 렌더링
  renderModalTable = () => {
    const { unAuth, searchFilter } = this.props;
    const rowData = toJS(unAuth.registerDevices).map((data, key) => {
      return (
        <tr className="text-center" key={key}>
          <td>{data.date}</td>
          <td>{data.telecom}</td>
          <td>{autoPhoneFormatter(data.phone)}</td>
          <td>
            <Select
              className="react-select text-left"
              classNamePrefix="react-select"
              name="department"
              placeholder="지점"
              value={unAuth.registerDevices[key].department}
              onChange={data => {
                unAuth.setRegisterDevices(key, 'department', data);
              }}
              options={toJS(searchFilter.departmentList).length > 1 ? searchFilter.departmentList.slice(1) : []}
            />
          </td>
        </tr>
      );
    });
    return rowData;
  };

  // 등록 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = async () => {
    const { unAuth, miniAlertModal } = this.props;
    const result = toJS(unAuth.registerDevices).map(data => {
      if (data.department !== '') {
        return true;
      } else {
        return false;
      }
    });
    if (result.includes(false)) {
      miniAlertModal.show('지점을 모두 선택해주세요.');
    } else {
      this.fetchRegisterDevices();
    }
  };

  // 디바이스 등록 API 호출
  fetchRegisterDevices = async () => {
    const { unAuth, miniAlertModal, loader, searchFilter, fetchRefresh } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/devices';
    const body = {
      devices: toJS(unAuth.registerDevices).map(data => {
        if (data.department !== '') {
          return {
            companyId: searchFilter.selectCompany.value,
            departmentId: data.department.value,
            deviceId: data.deviceId,
            type: 'TABLET',
            telecom: data.telecom,
            phone: data.phone,
            useYn: 'Y',
            deletedYn: 'N'
          };
        }
      })
    };
    const responseData = await requestPut(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      unAuth.clearRegisterDevices();
      await searchFilter.set('page', 1);
      fetchRefresh();
      miniAlertModal.show('등록되었습니다.');
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  render() {
    const { unAuth } = this.props;
    return (
      <Modal className="modal-lg" backdrop={'static'} isOpen={unAuth.registerModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">디바이스 등록</h4>
        </div>
        <div className="modal-body __paddingModalBody">
          <Table bordered striped>
            <thead>
              <tr className="text-center">
                <th>시간</th>
                <th>통신사</th>
                <th>전화번호</th>
                <th width="30%">등록 지점 선택 *</th>
              </tr>
            </thead>
            <tbody>{this.renderModalTable()}</tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}>
              저장
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
              닫기
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

RegisterUnAuthDeviceModal.defaultProps = {
  fetchRefresh: async () => {
    Logger.log('[RegisterUnAuthDeviceModal] - refresh function is undefined');
  }
};

export default RegisterUnAuthDeviceModal;
