import React, { Fragment } from 'react';
import Select from 'react-select';
import { Button, Modal, Table, Row, Col, FormGroup, Input } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import Logger from 'utils/logger';
import { requestPost } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';
import { getUserType } from 'commons/utils/tokenHelper';
import { autoBssidFormatter, autoRssiFormatter } from 'utils/fomatter';

// 위치인증 Ap 등록 모달
@inject('ap', 'searchFilter', 'miniAlertModal', 'loader')
@observer
class RegisterApModal extends React.Component {
  toggleModal = () => {
    const { ap } = this.props;
    ap.set('registerModalIsOpen', !ap.registerModalIsOpen);
  };

  // 등록 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    const { ap, miniAlertModal } = this.props;
    // 등록할 Ap 개수 체크
    if (ap.registerAps.length < 1) {
      miniAlertModal.show('등록할 AP가 없습니다.');
      return false;
    }
    // 빈 항목이 있는지 체크
    const result = ap.registerAps.map(data => {
      if (data.type.value === 'NFC') {
        // NFC - SSID null 체크 제외
        if (data.department === '' || data.type === '' || data.bssid === '') {
          miniAlertModal.show('빈 항목이 있습니다.');
          return false;
        }
      } else {
        // WiFi 및 BLE Beacon - RSSI 필터 값 외 모든 입력 값 null 체크
        if (data.department === '' || data.type === '' || data.ssid === '' || data.bssid === '') {
          miniAlertModal.show('빈 항목이 있습니다.');
          return false;
        }
        // NFC 이외 AP만 bssid 길이 체크
        if (data.bssid.length <= 16) {
          miniAlertModal.show('올바른 BSSID를 입력해주세요.');
          return false;
        }
      }
      return true;
    });

    if (!result.includes(false)) {
      this.fetchRegisterAps();
    }
  };

  // 위치 인증 Ap 등록 API 처리
  fetchRegisterAps = async () => {
    const { ap, miniAlertModal, loader, searchFilter, fetchRefresh } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/devices';
    let body = {};
    if (getUserType().slice(0, 2) === 'SA' || getUserType() === 'CDSU') {
      body = {
        devices: ap.registerAps.map(data => {
          return {
            companyId: searchFilter.selectCompany.value,
            departmentId: data.department.value,
            type: data.type.value,
            ssid: data.ssid,
            bssid: data.bssid,
            rssiFilter: parseInt(data.rssiFilter),
            useYn: 'Y'
          };
        })
      };
    } else {
      body = {
        devices: ap.registerAps.map(data => {
          return {
            companyId: searchFilter.selectCompany.value,
            departmentId: data.department.value,
            type: data.type.value,
            ssid: data.ssid,
            bssid: data.bssid,
            useYn: 'Y'
          };
        })
      };
    }
    const responseData = await requestPost(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      ap.clearRegisterAps();
      await searchFilter.set('page', 1);
      fetchRefresh();
      const addInfo = await this.getAddResultInfo(responseData.data);
      miniAlertModal.show('처리되었습니다.\n\n<등록 상세 결과>\n' + addInfo);
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  getAddResultInfo = async registerApInfo => {
    let addInfo = '';
    registerApInfo.registWifiCount > 0 &&
      (addInfo = addInfo.concat(`WiFi 신규 등록: ${registerApInfo.registWifiCount}건\n`));
    registerApInfo.dupWifiCount > 0 && (addInfo = addInfo.concat(`WiFi 중복 등록: ${registerApInfo.dupWifiCount}건\n`));
    registerApInfo.registBleCount > 0 &&
      (addInfo = addInfo.concat(`Beacon 신규 등록: ${registerApInfo.registBleCount}건\n`));
    registerApInfo.dupBleCount > 0 && (addInfo = addInfo.concat(`Beacon 중복 등록: ${registerApInfo.dupBleCount}건\n`));
    registerApInfo.registNfcCount > 0 &&
      (addInfo = addInfo.concat(`NFC 신규 등록: ${registerApInfo.registNfcCount}건\n`));
    registerApInfo.dupNfcCount > 0 && (addInfo = addInfo.concat(`Beacon 중복 등록: ${registerApInfo.dupNfcCount}건\n`));
    addInfo = addInfo.slice(0, addInfo.length - 1);
    return addInfo;
  };

  renderTableRow = () => {
    const devices = toJS(this.props.ap.registerAps).map((row, key) => {
      let { ap, searchFilter } = this.props;
      return (
        <Fragment key={key}>
          <tr>
            <td>
              <FormGroup>
                <Select
                  className="react-select __tableInputSelect __tableInputSelectColor"
                  classNamePrefix="react-select"
                  name="regDepartment"
                  value={row.department}
                  onChange={e => {
                    ap.setRegisterAps(key, 'department', e);
                  }}
                  placeholder="지점을 선택하세요."
                  options={searchFilter.departmentList.length > 1 ? searchFilter.departmentList.slice(1) : []}
                />
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <Select
                  className="react-select __tableInputSelect __tableInputSelectColor"
                  classNamePrefix="react-select"
                  name="regType"
                  value={row.type}
                  onChange={e => {
                    ap.setRegisterAps(key, 'type', e);
                  }}
                  placeholder=""
                  options={[
                    { label: 'WIFI', value: 'WIFI' },
                    { label: 'BLE Beacon', value: 'BLE' },
                    { label: 'NFC', value: 'NFC' }
                  ]}
                />
              </FormGroup>
            </td>
            <td>
              <div className="__tableInputSelect">
                <FormGroup>
                  <Input
                    className="__textAlignCenter __inline-input"
                    type="text"
                    name="regSsid"
                    value={row.ssid}
                    onChange={e => {
                      ap.setRegisterAps(key, 'ssid', e.target.value);
                    }}
                    maxLength={32}
                  />
                </FormGroup>
              </div>
            </td>
            <td className="text-center">
              <div className="__tableInputSelect">
                <FormGroup>
                  <Input
                    className="__textAlignCenter __inline-input"
                    type="text"
                    name="regBssid"
                    value={row.bssid}
                    onChange={e => {
                      ap.setRegisterAps(key, 'bssid', autoBssidFormatter(e.target.value.replace(/[^a-f0-9]/gi, '')));
                    }}
                    maxLength={17}
                  />
                </FormGroup>
              </div>
            </td>
            {((getUserType().slice(0, 2) === 'SA' ||
              getUserType() === 'CDSU') && (
                <td className="text-center">
                  <div className="__tableInputSelect">
                    <FormGroup>
                      <Input
                        className="__textAlignCenter __inline-input"
                        type="text"
                        name="regRssiFilter"
                        value={row.rssiFilter}
                        onChange={e => {
                          ap.setRegisterAps(key, 'rssiFilter', autoRssiFormatter(e.target.value.replace(/[^0-9]+/,'')));
                        }}
                        maxLength={4}
                      />
                    </FormGroup>
                  </div>
                </td>
              ))}
            <td className="text-center">
              <Button
                className="btn-round btn-icon"
                color="google"
                onClick={() => {
                  ap.deleteRegisterAps(key);
                }}
              >
                <i className="nc-icon nc-simple-delete" />
              </Button>
            </td>
          </tr>
        </Fragment>
      );
    });
    return devices;
  };

  render() {
    const { ap, miniAlertModal } = this.props;
    return (
      <Modal className="modal-ex-lg" backdrop={'static'} isOpen={ap.registerModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">위치인증 AP 등록</h4>
        </div>
        <div className="modal-body __paddingModalBody">
          <Row>
            <Col md="12">
              <Table bordered>
                <thead>
                  <tr className="text-center">
                    {getUserType().slice(0, 2) === 'SA' || getUserType() === 'CDSU' ? (
                      <>
                        <th width="25%">지점 *</th>
                        <th width="15%">타입 *</th>
                        <th width="25%">SSID *</th>
                        <th width="20%">BSSID *</th>
                        <th width="10%">RSSI</th>
                        <th width="5%"></th>
                      </>
                    ) : (
                      <>
                        <th width="25%">지점 *</th>
                        <th width="15%">타입 *</th>
                        <th width="25%">SSID *</th>
                        <th width="25%">BSSID *</th>
                        <th width="10%"></th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>{this.renderTableRow()}</tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="text-center">
              <Button
                className="btn-round btn-icon"
                color="success"
                onClick={async () => {
                  if (toJS(ap.registerAps).length < 10) {
                    ap.addRegisterAps();
                  } else {
                    miniAlertModal.show('더 이상 추가할 수 없습니다.');
                  }
                }}
              >
                <i className="nc-icon nc-simple-add" />
              </Button>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}
            >
              저장
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
              닫기
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

RegisterApModal.defaultProps = {
  fetchRefresh: async () => {
    Logger.log('[RegisterApModal] - refresh function is undefined');
  }
};

export default RegisterApModal;
