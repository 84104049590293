import React, { Fragment } from 'react';
import Select from 'react-select';
import { Button, Modal, Row, Col, Table, FormGroup, Input } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

import { requestPost } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';


// 인증 현황 상세 기지국 등록 모달
@inject('status', 'cell', 'searchFilter', 'miniAlertModal', 'loader')
@observer
class DetailModalRegisterCellTower extends React.Component {
  // status.detailStatus에 저장된 내용을 registerCells로 옮기기 위함
  // 등록할 데이터가 하나이기 때문에 key는 0
  componentDidMount() {
    const { cell, status } = this.props;
    cell.setRegisterCells(0, 'department',
      { label: status.detailStatus.departmentName, value: status.detailStatus.departmentId });
    cell.setRegisterCells(0, 'telecom',
      { label: status.detailStatus.telecom, value: status.detailStatus.telecom });
    cell.setRegisterCells(0, 'lac', status.detailStatus.lac);
    cell.setRegisterCells(0, 'cellId', status.detailStatus.cellId);
  }

  toggleModal = () => {
    const { status } = this.props;
    status.set('detailModalRegisterCell', !status.detailModalRegisterCell);
  };

  // 등록 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    const { cell, miniAlertModal } = this.props;
    // 등록할 디바이스 개수 체크
    if(cell.registerCells.length < 1) {
      miniAlertModal.show('등록할 기지국이 없습니다.');
      return false;
    }
    // 빈 데이터가 있는지 체크
    const result = toJS(cell.registerCells).map((data) => {
      if (data.department === '' || data.telecom === '' || data.lac === '' || data.cellId === '') {
        miniAlertModal.show('모든 값을 입력해주세요.');
        return false;
      }
      return true;
    });

    if (!result.includes(false)) {
      this.fetchRegisterCellTowers();
    }
  };

  // 등록 API 호출
  fetchRegisterCellTowers = async () => {
    const { status, miniAlertModal, loader, searchFilter, cell, fetchRefresh } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/cells';
    const body = {
      cells: [{
        companyId: searchFilter.selectCompany.value,
        departmentId: cell.registerCells[0].department.value,
        telecom: cell.registerCells[0].telecom.value,
        lac: cell.registerCells[0].lac,
        cellId: cell.registerCells[0].cellId
      }]
    };
    const responseData = await requestPost(url, body);
    const {registCellCount, dupCellCount, skipCellCount} = responseData.data;
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      cell.clearRegisterCells();

      await searchFilter.set('page', 1);
      fetchRefresh();
      miniAlertModal.show(`처리되었습니다. 
      
      성공: ${registCellCount}건, 중복: ${dupCellCount}건`);
    } else {
      miniAlertModal.show(responseData.message);
    }

    // 지점 없거나 해당 지점 권한 없음에 대한 message
    if(responseData.code === '1001') {
      miniAlertModal.show(responseData.message);
    }
  };

  renderTableRow = () => {
    const { status, cell, searchFilter } = this.props;
    return (
      <>
        <tr>
          <td>
            <FormGroup>
              <Select
                className="react-select __tableInputSelect __tableInputSelectColor"
                classNamePrefix="react-select"
                name="registerDepartment"
                defaultValue={toJS(cell.registerCells[0].department)}
                onChange={e => {
                  cell.setRegisterCells(0, 'department', toJS(e));
                }}
                value={cell.registerCells[0].department}
                options={searchFilter.departmentList.length > 1 ? searchFilter.departmentList.slice(1) : []}
                isClearable
              />
            </FormGroup>
          </td>
          <td>
            <FormGroup>
              <Input
                readOnly
                className="__textAlignCenter __inline-input"
                type="text"
                name="registerTelecom"
                value={cell.registerCells[0].telecom.value || ''}
              />
            </FormGroup>
          </td>
          <td className="text-center">
            <div className="__inline-div __tableInputSelect">
              <FormGroup>
                <Input
                  readOnly
                  className="__textAlignCenter __inline-input"
                  type="text"
                  name="registerLAC"
                  value={cell.registerCells[0].lac}
                />
              </FormGroup>
            </div>
          </td>
        </tr>
        <tr>
          <th className="text-center">CELLID</th>
          <td colSpan={2}>
            <div className="__tableInputSelect">
              <FormGroup>
                <Input
                  readOnly
                  className="__textAlignCenter"
                  type="text"
                  name="registerCellId"
                  value={cell.registerCells[0].cellId}
                  maxLength={50}
                />
              </FormGroup>
            </div>
          </td>
        </tr>
      </>
    );
  };

  render() {
    const { status, cell, miniAlertModal } = this.props;
    return (
      <Modal className="modal-lg" backdrop={'static'} isOpen={status.detailModalRegisterCell} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove"/>
          </button>
          <h4 className="title title-up">기지국 등록</h4>
        </div>
        <div className="modal-body __paddingModalBody">
          <Row>
            <Col md="12">
              <Table bordered>
                <thead>
                <tr className="text-center">
                  <th width="40%">지점</th>
                  <th width="30%">통신사</th>
                  <th width="40%">LAC</th>
                </tr>
                </thead>
                <tbody>{this.renderTableRow()}</tbody>
              </Table>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}>
              저장
            </Button>
          </div>
          <div className="divider"/>
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
              닫기
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

DetailModalRegisterCellTower.defaultProps = {
  fetchRefresh: async () => {
    console.log('[RegisterCellTower] - refresh function is undefined');
  }
};

export default DetailModalRegisterCellTower;
