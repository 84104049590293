import Login from 'views/pages/login/Login.jsx';
import Index from 'views/pages/index/Index.jsx';
import Dashboard from 'views/pages/dashboard/Dashboard.jsx';
import Contact from 'views/pages/contact/Contact.jsx';
import UserInfo from 'views/pages/userInfo/UserInfo.jsx';
import Status from 'views/pages/status/Status.jsx';
import { getUserType } from 'utils/tokenHelper';

export default function getNoSidebarRoutes() {
  let routes = [];
  const userType = getUserType();
  if (userType === 'SARW' || userType === 'SARO') {
    routes = [
      {
        path: '/login/:path',
        icon: 'nc-icon nc-bank',
        component: Login,
        layout: ''
      },
      {
        path: '/dashboard',
        icon: 'nc-icon nc-layout-11',
        component: Dashboard,
        layout: ''
      },
      {
        path: '/contact',
        icon: 'nc-icon nc-layout-11',
        component: Contact,
        layout: ''
      },
      {
        path: '/userinfo/:state',
        icon: 'nc-icon nc-layout-11',
        component: UserInfo,
        layout: ''
      },
      {
        path: '/index',
        icon: 'nc-icon nc-book-bookmark',
        component: Index,
        layout: ''
      }
    ];
  } else if (userType === 'CDRW' || userType === 'CDRO' || userType === 'CDSU') {
    routes = [
      {
        path: '/login/:path',
        icon: 'nc-icon nc-bank',
        component: Login,
        layout: ''
      },
      {
        path: '/dashboard',
        icon: 'nc-icon nc-layout-11',
        component: Dashboard,
        layout: ''
      },
      {
        path: '/contact',
        icon: 'nc-icon nc-layout-11',
        component: Contact,
        layout: ''
      },
      {
        path: '/userinfo/:state',
        icon: 'nc-icon nc-layout-11',
        component: UserInfo,
        layout: ''
      },
      {
        path: '/devices/:state',
        icon: 'nc-icon nc-bank',
        component: Status,
        layout: ''
      },
      {
        path: '/index',
        icon: 'nc-icon nc-book-bookmark',
        component: Index,
        layout: ''
      }
    ];
  } else {
    routes = [
      {
        path: '/login/:path',
        icon: 'nc-icon nc-bank',
        component: Login,
        layout: ''
      },
      {
        path: '/contact',
        icon: 'nc-icon nc-layout-11',
        component: Contact,
        layout: ''
      },
      {
        path: '/index',
        icon: 'nc-icon nc-book-bookmark',
        component: Index,
        layout: ''
      }
    ];
  }
  return routes;
}
