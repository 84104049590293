import React from 'react';
import { Button, Modal, Row, Col, FormGroup, Input } from 'reactstrap';
import { observer, inject } from 'mobx-react';

// 사용자 확인 알림 모달 컴포넌트
@inject('confirmAlertModal')
@observer
class ConfirmAlert extends React.Component {
  handleChange = e => {
    let { confirmAlertModal } = this.props;
    confirmAlertModal.isShowInputHelpMessage = false;
    confirmAlertModal.confirmInput = e.target.value;
  };

  render() {
    let { confirmAlertModal } = this.props;
    return (
      <Modal className={'modal-md'} backdrop={'static'} isOpen={confirmAlertModal.isOpen}>
        <div className="modal-body justify-content-center __textAlignCenter">
          <Row>
            <Col md="12">
              {/* 일반 안내 문구 출력 */}
              {confirmAlertModal.alertMessage.split('\n').map((line, index) => {
                return (
                  <div key={index}>
                    {line}
                    <br />
                  </div>
                );
              })}
              {/* 강조 안내 문구 출력 */}
              {confirmAlertModal.highlightMessage &&
                confirmAlertModal.highlightMessage.split('\n').map((line, index) => {
                  return (
                    <div key={index} style={{ color: 'red', fontWeight: 'bold' }}>
                      {line}
                      <br />
                    </div>
                  );
                })}
            </Col>
          </Row>
          {/* 사용자 문구 입력을 통한 확인 처리 */}
          {confirmAlertModal.userCheckMessage !== '' && (
            <Row>
              <Col md="12">
                <Input
                  name="confirmInput"
                  type="text"
                  className="__textAlignCenter"
                  placeholder={confirmAlertModal.userCheckMessage}
                  onChange={this.handleChange}
                  value={confirmAlertModal.confirmInput}
                />
              </Col>
              <Col md="12" style={{ color: 'red', fontSize: '12px' }}>
                {confirmAlertModal.confirmInput !== '' &&
                  confirmAlertModal.isShowInputHelpMessage !== false &&
                  confirmAlertModal.confirmInput !== confirmAlertModal.userCheckMessage &&
                  '올바른 문구를 입력하세요.'}
              </Col>
            </Row>
          )}
        </div>
        <div className="modal-footer justify-content-center">
          <Button
            className="btn-round"
            data-dismiss="modal"
            color="default"
            outline
            type="button"
            onClick={async () => {
              if (confirmAlertModal.userCheckMessage !== '') {
                if (confirmAlertModal.userCheckMessage !== confirmAlertModal.confirmInput) {
                  confirmAlertModal.isShowInputHelpMessage = true;
                  return;
                } else {
                  confirmAlertModal.confirmInput = '';
                  confirmAlertModal.isValidConfirmInput = false;
                  confirmAlertModal.isShowInputHelpMessage = false;
                }
              }
              confirmAlertModal.isOpen = !confirmAlertModal.isOpen;
              if (confirmAlertModal.confirmAction !== null) {
                await confirmAlertModal.confirmAction();
              }
            }}>
            확인
          </Button>
          <Button
            className="btn-round"
            data-dismiss="modal"
            color="default"
            outline
            type="button"
            onClick={async () => {
              confirmAlertModal.isOpen = !confirmAlertModal.isOpen;
            }}>
            취소
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ConfirmAlert;
