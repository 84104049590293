import React from 'react';
import { Table as RSTable, Label, FormGroup, Input } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { handleCopyClick } from 'views/components/alert/toastAlert/copy';


// 체크박스가 있는 테이블
@inject('table', 'searchFilter', 'toastAlert')
@observer
class Table extends React.Component {
  inputs = [];
  allSelectCheckBox = null;

  componentDidUpdate(prevProps, prevState, snapshot) {}

  getSnapshotBeforeUpdate = (prevProps, prevState) => {
    // 테이블 데이터 갱신시 체크박스 초기화
    this.allSelectCheckBox.checked = false;
    if (toJS(this.props.data) !== null) {
      this.handleChangeCheckBox(false);
    }
    // table Store - SelectedData 초기화
    this.props.table.set('selectedData', []);
    return null;
  };

  // 테이블 헤더 영역
  renderTableHeader = () => {
    const { header } = this.props;
    let tableHeader = [];
    tableHeader.push(
      <th width="5%" key={0}>
        <FormGroup check>
          <Label check>
            <Input
              innerRef={ref => (this.allSelectCheckBox = ref)}
              type="checkbox"
              onChange={async e => {
                e.target.checked ? this.handleChangeCheckBox(true) : this.handleChangeCheckBox(false);
              }}
            />
            <span className="form-check-sign" />
          </Label>
        </FormGroup>
      </th>
    );
    tableHeader = tableHeader.concat(
      header.map((data, key) => {
        return (
          <th key={key + 1} width={data.width}>
            {data.label}
          </th>
        );
      })
    );
    return tableHeader;
  };

  // 테이블 데이터 영역
  // 테이블 셀 클릭 시 복사를 위해 전화번호, 메모, bssid는 따로 처리..
  // 기지국 테이블 필드 중 등록 유형
  renderData = data => {
    const { header, handleRowClick, toastAlert } = this.props;
    const rowData = header.map((row, key) => {
      if (row.key === 'phone' || row.key === 'referral' || row.key === 'bssid') {
        return (
          <td
            key={key}
            onClick={() => {
              handleRowClick && handleRowClick(data);
            }}
          >
            <p
              style={{marginBottom: 0, display: 'inline'}}
              onClick={(e) => {
                e.stopPropagation();
                let mousePosition = { mouseX: e.clientX, mouseY: e.clientY };
                handleCopyClick(toastAlert, `copy${data.rowNum}of${row.key}`, mousePosition);
            }}>
              {data[row.key]}
            </p>
            <input
              id={`copy${data.rowNum}of${row.key}`}
              value={data[row.key]}
              readOnly
              style={{position: 'absolute', top: -999, left: -999}}
            />
          </td>
        );
      } else if (row.key === 'inertType') {
        return (
          <td
            key={key}
            onClick={() => {
              handleRowClick && handleRowClick(data);
            }}
          >
            {data[row.key] === 'A' ? '자동' : '수동'}
          </td>
        );
      } else if (row.key === 'superPassYn') {
        return (
          <td
            key={key}
            onClick={() => {
              handleRowClick && handleRowClick(data);
            }}
          >
            {data[row.key] === 'Y' ? '✅' : '-'}
          </td>
        );
      } else {
        return (
          <td
            key={key}
            onClick={() => {
              handleRowClick && handleRowClick(data);
            }}
          >
            {data[row.key]}
          </td>
        );
      }
    });
    return rowData;
  };

  // 테이블 영역
  renderTableBody = () => {
    const { table, data, handleRowClick } = this.props;
    const bodyData = toJS(data.content).map((row, key) => {
      return (
        <tr className={handleRowClick ? 'clickable-tr' : 'non-clickable-tr'} key={key}>
          {data && (
            <td>
              <FormGroup check>
                <Label check>
                  <Input
                    innerRef={ref => (this.inputs[key] = ref)}
                    type="checkbox"
                    value={row.rowNum}
                    onChange={e => {
                      this.allSelectCheckBox.checked = false;
                      e.target.checked ? table.addSelectedData(row) : table.deleteSelectedData(row);
                    }}
                  />
                  <span className="form-check-sign" />
                </Label>
              </FormGroup>
            </td>
          )}
          {this.renderData(row)}
        </tr>
      );
    });
    return bodyData;
  };

  handleChangeCheckBox = willChangeStatus => {
    const { table, data } = this.props;
    const tableDatas = data.content;
    this.allSelectCheckBox.checked = willChangeStatus;
    for (const item of this.inputs) {
      if (item !== null) {
        item.checked = willChangeStatus;
      }
    }

    if (willChangeStatus === true) {
      // 전체 선택하는 경우
      // 1. 상태 초기화 후
      table.set('selectedData', []);
      // 2. 전부 선택
      for (const item of this.inputs) {
        if (item !== null) {
          const rowIndex = tableDatas.findIndex(x => x.rowNum.toString() === item.value.toString());
          table.addSelectedData(tableDatas[rowIndex]);
        }
      }
    } else if (willChangeStatus === false) {
      // 전체 선택 해제하는 경우
      table.set('selectedData', []);
    }
  };

  render() {
    const { header, data } = this.props;

    return (
      <>
        <RSTable bordered striped className="__tableFixed">
          <thead>
            <tr className="text-center">{header && this.renderTableHeader()}</tr>
          </thead>
          <tbody>{data && this.renderTableBody()}</tbody>
        </RSTable>
      </>
    );
  }
}

Table.defaultProps = {
  header: null,
  data: null,
  handleRowClick: null
};

export default Table;
