import { refreshToken } from 'utils/tokenHelper';
import { checkTokenValid, logout } from 'utils/tokenValidChecker';
import { RESPONSE_STATUS } from 'commons/conts/lpinConts';

// 1. response.code !== '0000'인 경우에 대한 예외 처리
// 2. Token Refresh
// 3. miniAlertModal 및 history props 필요
export const isValidResponse = async (response, miniAlertModal, history) => {
  // Fetch 에러 체크
  if (typeof response === 'string' && response === 'failedToFetch') {
    await logout(history, RESPONSE_STATUS.FETCH_ERROR);
    return;
  }

  // response code 체크
  switch (response.code) {
  case '9000':
    await miniAlertModal.show('서버 에러가 발생하였습니다.');
    break;
  case '0000':
  case '0401':
  case '0403':
    break;
  default:
    await miniAlertModal.show(response.message);
    break;
  }

  // 토큰 검증
  let isTokenValid = await checkTokenValid(response);

  // 유효하지 않은 토큰인 경우
  if (isTokenValid === false) {
    if (response.code === '0401') {
      await logout(history, RESPONSE_STATUS.TOKEN_EXPIRED);
    } else if (response.code === '0403') {
      await logout(history, RESPONSE_STATUS.PERMISSION_DENIED);
    }
  } else {
    // 토큰 갱신
    await refreshToken();
  }
};
