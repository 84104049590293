import { RESPONSE_STATUS } from 'commons/conts/lpinConts';

// reponse body code 값 체크
export async function checkTokenValid(response) {
  let isValidToken = true;
  switch (response.code) {
  // case '0000':
  // case '4100': -> 담당자명 중복 검사 코드로 사용중
  case '0400':
  case '0401':
  case '0403':
    // case '0404':
    isValidToken = false;
    break;
  default:
    isValidToken = true;
    break;
  }
  return isValidToken;
}

// 로그아웃
export async function logout(history, status) {
  localStorage.removeItem('token');
  localStorage.removeItem('companyId');
  localStorage.removeItem('companyName');
  localStorage.removeItem('ip');

  let pathName = '';

  switch (status) {
  case RESPONSE_STATUS.FETCH_ERROR:
    pathName = '/login/:path';
    break;
  case RESPONSE_STATUS.TOKEN_EXPIRED:
    pathName = '/login/expire';
    break;
  case RESPONSE_STATUS.PERMISSION_DENIED:
    pathName = '/login/permission';
    break;
  default:
    pathName = '/login/:path';
    break;
  }

  history.push({
    pathname: pathName
  });
}
