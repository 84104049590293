import { observable, action } from 'mobx';

// 등록 디바이스 관리
export class MobxStore {
  constructor() {
    this.data = null;
    this.registerDevices = [
      {
        department: '',
        telecom: '',
        phone: '',
        adid: ''
      }
    ];
    this.editDevices = {
      deviceId: '',
      department: '',
      telecom: '',
      phone: '',
      adid: ''
    };
    this.registerModalIsOpen = false;
    this.modifyModalIsOpen = false;
    this.fileUploadModalIsOpen = false;
  }

  @observable data; // 등록 디바이스 데이터
  @observable registerDevices; // 등록 디바이스
  @observable editDevices; // 수정 디바이스
  @observable registerModalIsOpen; // 등록 모달 토글
  @observable modifyModalIsOpen; // 수정 모달 토글
  @observable fileUploadModalIsOpen; // 파일 업로드 모달 토글

  // 등록 데이터 변경
  @action setRegisterDevices = (index, key, value) => {
    this.registerDevices[index][key] = value;
  };

  // 등록 데이터 추가
  @action addRegisterDevices = () => {
    this.registerDevices.push({
      department: '',
      telecom: '',
      phone: '',
      adid: ''
    });
  };

  // 등록 데이터 삭제
  @action deleteRegisterDevices = index => {
    this.registerDevices.splice(index, 1);
  };

  // 등록 데이터셋 초기화
  @action clearRegisterDevices = async () => {
    this.registerDevices = [
      {
        department: '',
        telecom: '',
        phone: '',
        adid: ''
      }
    ];
  };

  // 수정 데이터 변경
  @action setEditDevices = (objName, key, value, newObj) => {
    if (newObj !== undefined && newObj !== null) {
      this[objName] = newObj;
    } else {
      this[objName] = {
        ...this[objName],
        [key]: value
      };
    }
  };

  @action set = (key, value) => {
    this[key] = value;
  };
}
