export function phoneFomatter(num, type) {
  let formatNum = '';
  if (num === null || num.replace(' ', '') === ''){
    return '';
  }

  if (num.length === 11) {
    if (type === 0) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
    } else {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
  } else if (num.length === 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (num.indexOf('02') === 0) {
      if (type === 0) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else {
      if (type === 0) {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }
  }
  return formatNum;
}

// 연락처 입력 값 포매팅 처리
export function autoPhoneFormatter(value) {
  if (value === null || value.replace(' ', '') === '') {
    return '';
  }
  let returnValue = value.replace(/[^0-9]/g, '');
  if (returnValue.length < 4) {
    return returnValue;
  } else if (returnValue.length < 6) {
    return `${returnValue.slice(0, 3)}-${returnValue.slice(3, returnValue.length)}`;
  } else if (returnValue.length === 6) {
    if (returnValue.slice(0, 2) === '02') {
      return `${returnValue.slice(0, 2)}-${returnValue.slice(2, returnValue.length)}`;
    } else {
      return `${returnValue.slice(0, 3)}-${returnValue.slice(3, returnValue.length)}`;
    }
  } else if (returnValue.length < 10) {
    if (returnValue.slice(0, 2) === '02') {
      return `${returnValue.slice(0, 2)}-${returnValue.slice(2, 5)}-${returnValue.slice(5, returnValue.lenght)}`;
    } else {
      return `${returnValue.slice(0, 3)}-${returnValue.slice(3, 6)}-${returnValue.slice(6, returnValue.lenght)}`;
    }
  } else if (returnValue.length < 11) {
    if (returnValue.slice(0, 2) === '02') {
      return `${returnValue.slice(0, 2)}-${returnValue.slice(2, 6)}-${returnValue.slice(6, returnValue.lenght)}`;
    } else {
      return `${returnValue.slice(0, 3)}-${returnValue.slice(3, 6)}-${returnValue.slice(6, returnValue.lenght)}`;
    }
  } else if (returnValue.length < 12) {
    return `${returnValue.slice(0, 3)}-${returnValue.slice(3, 7)}-${returnValue.slice(7, returnValue.lenght)}`;
  }
  return returnValue;
}

// BSSID 자동 지정
export function autoBssidFormatter(value) {
  let returnValue = value.replace(/[^a-f0-9]/gi, '');
  if (returnValue.length <= 2) {
    return returnValue;
  } else if (returnValue.length <= 4) {
    return `${returnValue.slice(0, 2)}:${returnValue.slice(2, returnValue.length)}`;
  } else if (returnValue.length <= 6) {
    return `${returnValue.slice(0, 2)}:${returnValue.slice(2, 4)}:${returnValue.slice(4, returnValue.length)}`;
  } else if (returnValue.length <= 8) {
    return `${returnValue.slice(0, 2)}:${returnValue.slice(2, 4)}:${returnValue.slice(4, 6)}:${returnValue.slice(
      6,
      returnValue.length
    )}`;
  } else if (returnValue.length <= 10) {
    return `${returnValue.slice(0, 2)}:${returnValue.slice(2, 4)}:${returnValue.slice(4, 6)}:${returnValue.slice(
      6,
      8
    )}:${returnValue.slice(8, returnValue.length)}`;
  } else if (returnValue.length <= 12) {
    return `${returnValue.slice(0, 2)}:${returnValue.slice(2, 4)}:${returnValue.slice(4, 6)}:${returnValue.slice(
      6,
      8
    )}:${returnValue.slice(8, 10)}:${returnValue.slice(10, returnValue.length)}`;
  } else if (returnValue.length <= 14) {
    return `${returnValue.slice(0, 2)}:${returnValue.slice(2, 4)}:${returnValue.slice(4, 6)}:${returnValue.slice(
      6,
      8
    )}:${returnValue.slice(8, 10)}:${returnValue.slice(10, 12)}:${returnValue.slice(12, returnValue.length)}`;
  } else if (returnValue.length <= 16) {
    return `${returnValue.slice(0, 2)}:${returnValue.slice(2, 4)}:${returnValue.slice(4, 6)}:${returnValue.slice(
      6,
      8
    )}:${returnValue.slice(8, 10)}:${returnValue.slice(10, 12)}:${returnValue.slice(12, 14)}:${returnValue.slice(
      14,
      returnValue.length
    )}`;
  }
  return returnValue;
}

// 통신사 드롭박스 설정을 위한 통신사 객체 선택 함수
export async function commonGetTelecomObj(telecomId) {
  let telecomObj = {};
  switch (telecomId) {
  case 'SKT':
    telecomObj = {
      label: 'SKT',
      value: 'SKT'
    };
    break;
  case 'KT':
    telecomObj = {
      label: 'KT',
      value: 'KT'
    };
    break;
  case 'LGT':
    telecomObj = {
      label: 'LGT',
      value: 'LGT'
    };
    break;
  case 'SKMV':
    telecomObj = {
      label: 'SKT 알뜰폰',
      value: 'SKMV'
    };
    break;
  case 'KTMV':
    telecomObj = {
      label: 'KT 알뜰폰',
      value: 'KTMV'
    };
    break;
  case 'LGMV':
    telecomObj = {
      label: 'LGT 알뜰폰',
      value: 'LGMV'
    };
    break;
  default:
    telecomObj = {
      label: '통신사',
      value: ''
    };
    break;
  }
  return telecomObj;
}

export function autoRssiFormatter(value) {
  if (value === null || value.replace(' ', '') === '') {
    return '';
  }
  let returnValue = `-${value.replace(/[^0-9]+/,'')}`;
  return returnValue;
}