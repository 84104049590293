import React from 'react';
import classnames from 'classnames';
import logo from 'assets/images/commons/logo/logo-white.png';

// reactstrap components
import { NavbarBrand, Navbar, Container, NavLink } from 'reactstrap';

class LoginNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: 'navbar-transparent'
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateColor);
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState['color'] = 'bg-white';
    } else {
      newState['color'] = 'navbar-transparent';
    }
    this.setState(newState);
  };
  render() {
    return (
      <Navbar className={classnames('navbar-absolute fixed-top', this.state.color)} expand="lg">
        <Container>
          <div className="navbar-wrapper">
            <NavbarBrand>
              <img src={logo} alt="logo" />
            </NavbarBrand>
            <NavLink to="/index" className="nav-link">
              <h5 className="__textWhite">L-PIN Admin site</h5>
            </NavLink>
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default LoginNavbar;
