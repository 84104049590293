import React from 'react';
import Loader from 'views/components/loader/Loader';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Modal
} from 'reactstrap';
import { requestPost } from 'utils/fetchHelper';
import { getJwtBodyValue, getUserType } from 'utils/tokenHelper';
import Logger from 'utils/logger';

import { observer, inject } from 'mobx-react';
import { getLocalIp } from 'utils/ipHelper';

@inject('searchFilter', 'userInfo')
@observer
class Login extends React.Component {
  state = {
    loadToggle: false,
    username: '',
    password: '',
    alert: '',
    modal: false,
    expireModal: false,
    lockModal: false,
    cardTitle: '',
    message: '',
    miniModal: false,
    comp: ''
    // info: info
  };

  // enter click event - login button 과 같은 기능
  KeyPress = e => {
    if (e.charCode === 13) {
      this.handleClick();
    }
  };

  async componentDidMount() {
    // PLAC-289 - 로그인 페이지 진입시 Local Storage 초기화
    localStorage.removeItem('companyId');
    localStorage.removeItem('companyName');
    localStorage.removeItem('token');
    localStorage.removeItem('resetOption');

    // 로그인 페이지로 넘어왔을 때 local Ip 갱신
    getLocalIp();

    this.username.focus();
    if (this.props.match.params.path === 'expire') {
      this.setState({
        miniModal: true,
        comp: '로그인 시간이 만료되었습니다.\n다시 로그인하세요.'
      });
    } else if (this.props.match.params.path === 'permission') {
      this.setState({
        miniModal: true,
        comp: '권한이 없습니다.\n로그인 후 사용하세요.'
      });
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      alert: ''
    });
  };

  handleClick = async () => {
    if (this.state.username.trim() === '') {
      this.setState({
        alert: '아이디와 비밀번호를 모두 입력하세요.',
        loadToggle: false
      });
      this.username.focus();
      return false;
    }
    if (this.state.password === '') {
      this.setState({
        alert: '아이디와 비밀번호를 모두 입력하세요.',
        loadToggle: false
      });
      this.password.focus();
      return false;
    }

    await this.callApi();
  };

  callApi = async () => {
    this.setState({
      loadToggle: true
    });
    let url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/users/signin';
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Auth-Local-Address': getLocalIp()
      },
      body: JSON.stringify({
        username: this.state.username,
        password: this.state.password
      })
    };

    let response = await fetch(url, options).then(res => res.json());
    if (response.code === '0000') {
      this.setState({
        loadToggle: false
      });

      // PLAC-289 - 페이지별 기존 코드 수정 전까지 유지
      localStorage.setItem('companyId', response.data.companyId);
      localStorage.setItem('companyName', response.data.companyName);

      // PLAC-289 - 토큰만 저장 & payload 값 필요시 tokenHelper에서 꺼내서 사용
      localStorage.setItem('token', response.data.token);

      Logger.log(getJwtBodyValue(localStorage.getItem('token'), 'companyName') + ' 관리자님 환영합니다.');

      // 기업 정보 갱신
      await this.props.searchFilter.getCompanyList();

      if (response.data.expireType === 'TEMP_PWD' && response.data.resetOption === 'MUST' && response.data.isTempPassword === 'Y') {
        // 임시 비밀번호인 경우 expireType: TEMP_PWD, resetOption: MUST -> 비밀번호 변경
        // PLAC-641 - resetOption을 저장하여 'MUST'일 경우 다픈 페이지 접속이 되지 않도록 함
        await this.props.userInfo.set('resetOption', response.data.resetOption);
        await this.props.userInfo.set('editStatus', 'changePassword');
        // PLAC-641 - expireType에 따른 마이페이지 문구 변경을 위함
        await this.props.userInfo.set('expireType', response.data.expireType);
        this.props.history.push({
          pathname: '/userinfo/true'
        });
        return;
      }

      if (response.data.expireType === 'PWD' && response.data.resetOption === 'MUST' && response.data.expireTime < 0) {
        // 비밀번호 만료인 경우 expireType: PWD, resetOption: MUST -> 비밀번호 변경
        await this.props.userInfo.set('resetOption', response.data.resetOption);
        await this.props.userInfo.set('editStatus', 'changePassword');
        await this.props.userInfo.set('expireType', response.data.expireType);
        this.setState({
          expireModal: !this.state.expireModal
        });
      } else if (response.data.expireType === 'PWD' && response.data.resetOption === 'MUST') {
        // 비밀번호를 3번 틀릴 경우 expireType: PWDW, resetOption: MUST -> 비밀번호 변경
        await this.props.userInfo.set('resetOption', response.data.resetOption);
        await this.props.userInfo.set('editStatus', 'changePassword');
        await this.props.userInfo.set('expireType', response.data.expireType + 'W');
        this.setState({
          loadToggle: false,
          lockModal: !this.state.lockModal,
          message: response.message,
          cardTitle: '비밀번호 횟수 초과'
        });
      } else if (response.data.expireType === 'PWD' && response.data.resetOption === 'OPTION' && response.data.expireTime < 2592000000) {
        // 비밀번호 만료 30일 전인 경우 expireType: PWD, resetOption: OPTION -> 마이페이지
        this.setState({
          modal: !this.state.Modal
        });
      } else if (response.data.expireType === 'ACC' && response.data.resetOption === 'MUST' && response.data.expireTime < 0) {
        // 계정 유효기간 만료인 경우 expireType: ACC, resetOption: MUST -> 비밀번호 변경
        await this.props.userInfo.set('resetOption', response.data.resetOption);
        await this.props.userInfo.set('editStatus', 'changePassword');
        await this.props.userInfo.set('expireType', response.data.expireType);
        this.setState({
          lockModal: !this.state.lockModal
        });
      } else {
        await this.props.userInfo.set('resetOption', '');
        this.props.history.push({
          pathname: '/dashboard'
        });
      }
    } else if (response.code === '9999') {
      await this.setState({
        ...this.state,
        loadToggle: false,
        alertMessage: '서버 에러가 발생하였습니다.',
        alertMiniModalHandler: {
          ...this.state.alertMiniModalHandler,
          isOpenAlertMiniModal: true
        }
      });
    } else {
      this.setState({
        loadToggle: false,
        alert: '정보를 다시 확인하세요.'
      });
      this.username.focus();
    }
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  toggleExpireModal = () => {
    this.setState({
      expireModal: !this.state.expireModal
    });
  };

  toggleLockModal = () => {
    this.setState({
      lockModal: !this.state.lockModal
    });
  };

  handleSendMailClick = async () => {
    this.setState({
      loadToggle: true
    });

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/users/sendmail';

    const body = {
      username: this.state.username
    };

    const responseData = await requestPost(url, body);
    // Fetch 에러 체크

    // 서버 에러 체크
    if (responseData.code === '9000') {
      await this.setState({
        ...this.state,
        loadToggle: false,
        alertMessage: '서버 에러가 발생하였습니다.',
        alertMiniModalHandler: {
          ...this.state.alertMiniModalHandler,
          isOpenAlertMiniModal: true
        }
      });
      return;
    }

    if (responseData.code === '0000') {
      this.setState({
        loadToggle: false,
        username: '',
        password: '',
        alert: '',
        modal: false,
        lockModal: false,
        miniModal: true,
        comp: responseData.description
      });
    }
  };

  handleMiniModal = () => {
    this.setState({
      miniModal: false,
      comp: ''
    });
  };

  render() {
    return (
      <div className="login-page __loginSection __loginAndMyPageFont-size">
        {this.state.loadToggle && <Loader />}
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              {/* <Form className=""> */}
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">Login</h3>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02 __custom-icon" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="username"
                        className="__loginAndMyPageFont-size"
                        innerRef={ref => (this.username = ref)}
                        onChange={this.handleChange}
                        placeholder="ID"
                        type="text"
                        onKeyPress={this.KeyPress}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25 __custom-icon" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="password"
                        className="__loginAndMyPageFont-size"
                        size="24"
                        innerRef={ref => (this.password = ref)}
                        onChange={this.handleChange}
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        onKeyPress={this.KeyPress}
                      />
                    </InputGroup>
                  </FormGroup>
                  {this.state.alert === '' ? (
                    <div className="__badgeBlank" />
                  ) : (
                    <Badge className="__width100" color="danger">
                      {this.state.alert.split('\n').map((line, index) => {
                        return (
                          <div key={index}>
                            {line}
                            <br />
                          </div>
                        );
                      })}
                    </Badge>
                  )}
                </CardBody>
                <CardFooter>
                  <Button block className="btn-round mb-3 __loginButtonColor" onClick={this.handleClick}>
                    GET STARTED
                  </Button>
                </CardFooter>
              </Card>
              {/* </Form> */}
            </Col>
          </Row>
        </Container>
        <Modal className="modal" isOpen={this.state.modal} toggle={this.toggleModal}>
          <div className="modal-header justify-content-center">
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="title title-up">비밀번호 변경</h4>
          </div>
          <div className="modal-body __textAlignCenter">비밀번호를 변경하시겠습니까?</div>
          <div className="modal-footer">
            <div className="left-side">
              <Button
                className="btn-link"
                color="info"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  this.props.history.push({
                    pathname: '/userinfo/:path'
                  });
                }}>
                지금 변경하기
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                className="btn-link"
                color="danger"
                type="button"
                onClick={() => {
                  this.props.history.push({
                    pathname: '/dashboard'
                  });
                }}>
                나중에 변경하기
              </Button>
            </div>
          </div>
        </Modal>
        <Modal className="modal" isOpen={this.state.expireModal} toggle={this.toggleExpireModal}>
          <div className="modal-header justify-content-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleExpireModal}>
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="title title-up">비밀번호 만료</h4>
          </div>
          <div className="modal-body __textAlignCenter">비밀번호가 만료되었습니다. 비밀번호를 변경해 주세요.</div>
          <div className="modal-footer">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                this.props.history.push({
                  pathname: '/userinfo/true'
                });
              }}>
              변경하기
            </Button>
          </div>
        </Modal>
        <Modal className="modal" isOpen={this.state.lockModal} toggle={this.toggleLockModal}>
          <div className="modal-header justify-content-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleLockModal}>
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="title title-up">{
              this.state.cardTitle.length !== 0 ? (
                <p>{this.state.cardTitle}</p>
              ) : (
                <p>계정만료</p>
              )
            }</h4>
          </div>
          <div className="modal-body __textAlignCenter">
            { this.state.message.length !== 0 ? (
              this.state.message.split('\n').map((line, index) => {
                return (
                  <div key={index}>
                    {line}
                    <br />
                  </div>
                );
              })
              ) : (
              <p>일정기간 사용하지 않아 계정을 사용할 수 없습니다.<br/>
              비밀번호를 변경하면 다시 사용할 수 있습니다.</p>
            )}
          </div>
          <div className="modal-footer">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                this.props.history.push({
                  pathname: '/userinfo/true'
                });
              }}>
              변경하기
            </Button>
          </div>
        </Modal>
        <Modal className="modal-sm" isOpen={this.state.miniModal} toggle={this.handleMiniModal}>
          <div className="modal-body justify-content-center __textAlignCenter">
            <Row>
              <Col md="12">
                {this.state.comp.split('\n').map((line, index) => {
                  return (
                    <div key={index}>
                      {line}
                      <br />
                    </div>
                  );
                })}
              </Col>
            </Row>
          </div>
          <div className="modal-footer justify-content-center">
            <Button
              className="btn-round"
              data-dismiss="modal"
              color="default"
              outline
              type="button"
              onClick={this.handleMiniModal}>
              확인
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Login;
