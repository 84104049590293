import { observable, action } from 'mobx';

export class MobxStore {
  constructor() {
    this.data = null;
    this.registerDevices = [];
    this.registerModalIsOpen = false;
  }

  @observable data; // 미등록 디바이스 데이터
  @observable registerDevices; // 미인증 디바이스 등록 데이터셋
  @observable registerModalIsOpen; // 등록 모달 토글

  // 등록 데이터셋 초기화
  @action clearRegisterDevices = async () => {
    this.registerDevices = [];
  };

  // 등록 데이터 추가
  @action addRegisterDevices = device => {
    this.registerDevices.push(device);
  };

  // 등록 데이터 변경
  @action setRegisterDevices = (index, key, value) => {
    this.registerDevices[index][key] = value;
  };

  @action set = (key, value) => {
    this[key] = value;
  };
}
