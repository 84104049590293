import React from 'react';
import Select from 'react-select';
import Loader from 'views/components/loader/Loader';
import buildUrl from 'build-url';
import Pagination from 'views/components/pagination/CustomPagination';
import { Button, Card, CardBody, Col, Form, Input, Label, Row } from 'reactstrap';
import { requestDelete, requestGet } from 'utils/fetchHelper';

import ConfirmAlert from 'views/components/alert/confirmAlert/ConfirmAlert';
import MiniAlert from 'views/components/alert/miniAlert/MiniAlert';

import CompanyFilter from 'views/components/searchFilter/CompanyFilter';
import DepartmentFilter from 'views/components/searchFilter/DepartmentFilter';

import RegisterDepartmentModal from 'src/views/pages/department/Register';
import ModifyDepartmentModal from 'src/views/pages/department/Modify';

import { DEPARTMENT_TABLE_HEADER } from 'commons/conts/tableHeader';
import Table from 'views/components/table/checkbox/table';

import { isValidResponse } from 'utils/responseValidChecker';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import TotalCount from 'views/components/common/totalCount/TotalCount';
import { getUserType } from 'utils/tokenHelper';

@inject('searchFilter', 'department', 'confirmAlertModal', 'miniAlertModal', 'loader', 'table')
@observer
class Department extends React.Component {
  radioAll = '';

  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.radioAll.checked = true;
    const { searchFilter } = this.props;
    searchFilter.set('page', 1);
    searchFilter.companyList.length === 0 && (await searchFilter.getCompanyList());
    this.fetchRefresh();
  }

  componentWillUnmount() {
    const { searchFilter, table, department } = this.props;
    searchFilter.clear();
    table.set('selectedData', []);
    department.set('data', null);
  }

  // 지점 데이터 조회 API 호출
  fetchRefresh = async () => {
    const { department, searchFilter, miniAlertModal, loader, table } = this.props;
    loader.show(true);

    const selectCompanyId = searchFilter.selectCompany.value;
    const selectDepartmentId = searchFilter.selectDepartment.value;
    const superPassStatus = searchFilter.superPassStatus;

    let queryParams = {};
    selectDepartmentId !== '' && (queryParams.departmentId = selectDepartmentId);
    superPassStatus !== '' && (queryParams.superPassYn = superPassStatus);
    queryParams.page = searchFilter.page !== '' ? searchFilter.page : 1;
    queryParams.size = searchFilter.pageSize.value !== '' ? searchFilter.pageSize.value : 10;

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: 'v1/admin/api/companies/' + selectCompanyId + '/departments/list',
      queryParams: queryParams
    });

    const responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    const editedResponseData = await this.addCustomColumn(responseData);

    department.set('data', editedResponseData.data);
  };

  // 지점명(지점ID) 및 Ap 타입 칼럼 추가
  addCustomColumn = async response => {
    response.data.content = response.data.content.map(item => {
      return {
        ...item,
        customDepartmentInfo: item.name + ' (' + item.id + ')'
      };
    });
    return response;
  };

  // 등록 버튼 클릭
  handleRegisterButtonClick = () => {
    const { department, searchFilter } = this.props;
    department.setDepartment('registerDepartment', 'companyId', searchFilter.selectCompany.value);
    department.set('registerModalIsOpen', !department.registerModalIsOpen);
  };

  // 데이터 테이블 클릭 - 지점 수정
  handleEditRowClick = data => {
    if (getUserType().slice(2, 4) === 'RO') {
      return;
    }
    const { department, searchFilter } = this.props;
    const editData = {
      companyId: searchFilter.selectCompany.value,
      departmentId: data.id,
      name: data.name,
      code: data.code,
      wifi: data.wifi,
      lat: data.lat,
      lon: data.lon,
      address: data.address,
      addressDetail: data.addressDetail,
      roadAddress: data.roadAddress,
      roadAddressDetail: data.roadAddressDetail,
      zip: data.zip,
      superPassYn: data.superPassYn
    };
    department.setDepartment('editDepartment', null, null, editData);
    department.set('modifyModalIsOpen', !department.modifyModalIsOpen);
  };

  // 삭제 버튼 클릭 이벤트 처리
  handleDeleteButtonClick = async () => {
    const { miniAlertModal, confirmAlertModal, table } = this.props;
    if (toJS(table.selectedData).length === 0) {
      miniAlertModal.show('삭제할 데이터를 선택해주세요.');
    } else {
      confirmAlertModal.show(
        '선택한 데이터를 삭제합니다.\n',
        '삭제된 데이터는 복원할 수 없습니다.\n그래도 삭제 하시겠습니까?',
        '',
        this.fetchDeleteDepartment
      );
    }
  };

  // 지점 삭제 API 호출
  fetchDeleteDepartment = async () => {
    const { table, miniAlertModal, loader, searchFilter } = this.props;
    loader.show(true);

    const url =
      process.env.REACT_APP_ENDPOINT + '/v1/admin/api/companies/' + searchFilter.selectCompany.value + '/departments';
    const body = {
      department: toJS(table.selectedData).map(data => {
        return {
          id: data.id
        };
      })
    };
    const responseData = await requestDelete(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      miniAlertModal.show('삭제가 완료되었습니다.');
      await searchFilter.set('page', 1);
      this.fetchRefresh();
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  // 식별자 유무 라디오 버튼 클릭 이벤트 처리
  handleRadioChange = async e => {
    const { searchFilter } = this.props;
    const superPassStatus = e.target.value;
    await searchFilter.set('page', 1);
    await searchFilter.set('superPassStatus', superPassStatus);
    await this.fetchRefresh();
  };

  // 페이징 컴포넌트 콜백 함수
  handlePaginationCallback = async page => {
    const { searchFilter } = this.props;
    searchFilter.set('page', page);
    await this.fetchRefresh();
  };

  // 페이지 당 검색 결과 수 변경 이벤트 함수
  handlePageSizeChanged = async e => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    await searchFilter.setPageSize(e);
    await this.fetchRefresh();
  };

  render() {
    let { department, confirmAlertModal, miniAlertModal, loader, searchFilter } = this.props;
    return (
      <>
        {loader.showLoader && <Loader></Loader>}
        <div className="content">
          <h4 className="__marginTop">지점 관리</h4>
          <h6>지점에 대한 정보를 변경 또는 삭제할 수 있습니다.</h6>
          <Card className="card-stats">
            <CardBody className="__padding">
              <Form className="form-horizontal">
                <Row md="12">
                  <Col md={{ size: 10, offset: 1 }} className="text-center">
                    <Row md="12">
                      <Label md="1" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        회사명
                      </Label>
                      <Col md="3" sm="8" xs="8">
                        <CompanyFilter refreshData={this.fetchRefresh}></CompanyFilter>
                      </Col>
                      <Label md="1" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        지점명
                      </Label>
                      <Col md="3" sm="8" xs="8">
                        <DepartmentFilter refreshData={this.fetchRefresh}></DepartmentFilter>
                      </Col>
                      <Label md="1" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        슈퍼패스
                      </Label>
                      <Col md="3" sm="8" xs="8">
                        <div className="form-check-radio form-check-inline">
                          <Label className="form-check-label">
                            <Input
                              innerRef={ref => (this.radioAll = ref)}
                              type="radio"
                              name="superPassYn"
                              onChange={this.handleRadioChange}
                              value=""
                            />
                            전체 조회
                            <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div className="form-check-radio form-check-inline">
                          <Label className="form-check-label">
                            <Input type="radio" name="superPassYn" onChange={this.handleRadioChange} value="Y" />
                            Y
                            <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div className="form-check-radio form-check-inline">
                          <Label className="form-check-label">
                            <Input type="radio" name="superPassYn" onChange={this.handleRadioChange} value="N" />
                            N
                            <span className="form-check-sign" />
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="form-horizontal __marginBottom5">
                <Col md="12" className="text-right">
                  <div className="__inline-div">
                    <Select
                      className="react-select __pageSelect"
                      classNamePrefix="react-select"
                      name="pageSize"
                      placeholder=""
                      value={searchFilter.pageSize}
                      options={searchFilter.pageSizeList}
                      onChange={this.handlePageSizeChanged}
                    />
                    <Label className="label-page">페이지 사이즈</Label>
                    <TotalCount totalElements={department.data && department.data.totalElements} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Table
                    header={DEPARTMENT_TABLE_HEADER}
                    data={department.data}
                    handleRowClick={this.handleEditRowClick}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={{ offset: 3, size: 6 }}>
                  {department.data && (
                    <Pagination
                      currentPage={searchFilter.page}
                      totalPages={department.data.totalPages}
                      pageSize={department.data.pageable.pageSize}
                      callback={this.handlePaginationCallback}
                    />
                  )}
                </Col>
              </Row>
              {getUserType().slice(2, 4) !== 'RO' && (
                <Row>
                  <Col className="text-right" md={{ offset: 8, size: 4 }}>
                    <div className="__inline-div __button-margin">
                      <Button
                        className="btn-round __marginTop __button-margin"
                        onClick={this.handleRegisterButtonClick}
                        color="primary"
                      >
                        등록
                      </Button>
                    </div>
                    <div className="__inline-div __button-margin">
                      <Button className="btn-round __marginTop" onClick={this.handleDeleteButtonClick} color="primary">
                        삭제
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </div>

        {/* 지점 등록 */}
        {department.registerModalIsOpen && <RegisterDepartmentModal {...this.props} fetchRefresh={this.fetchRefresh} />}

        {/* 지점 수정 */}
        {department.modifyModalIsOpen && <ModifyDepartmentModal {...this.props} fetchRefresh={this.fetchRefresh} />}

        {/* 알림 팝업 */}
        {miniAlertModal.isOpen && <MiniAlert />}

        {/* 사용자 확인 알림 모달 */}
        {confirmAlertModal.isOpen && <ConfirmAlert />}
      </>
    );
  }
}

export default Department;
