import React from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch } from 'react-router-dom';

import LoginNavbar from 'src/views/layouts/login/LoginNavbar.jsx';
import Footer from 'views/components/footer/Footer.jsx';

import routesByAuth from 'commons/routes/routesByAuth.js';
import nroutesByAuth from 'commons/routes/noSidebarRoutesByAuth.js';

var ps;

class LoginLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: routesByAuth(),
      nroutes: nroutesByAuth()
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }

  async componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    await this.setState({
      ...this.state,
      routes: [],
      nroutes: []
    });
  }

  getRoutes = routes => {
    const concatRouters = this.state.nroutes.concat(routes);
    return concatRouters.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '') {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => <prop.component endPoint={this.props.endPoint} {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <>
        <LoginNavbar />
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className="full-page section-image">
            <Switch>{this.getRoutes(this.state.routes)}</Switch>
            <Footer fluid />
          </div>
        </div>
      </>
    );
  }
}

export default LoginLayout;
