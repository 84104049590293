import { observable, action } from 'mobx';

// Loader 컴포넌트
export class MobxStore {
  constructor() {
    this.showLoader = false;
  }

  @observable showLoader; // Loading 이미지 출력 여부

  // Loader 활성화/비활성화 체크
  @action show = isShow => {
    this.showLoader = isShow;
  };
}
