import Logger from 'utils/logger';
import { getLocalIp } from 'utils/ipHelper';

export async function requestGet(url) {
  const option = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Auth-Local-Address': getLocalIp()
    }
  };
  return executeFetch(url, option);
}

export async function requestPost(url, body) {
  body = JSON.stringify(body);
  const option = {
    method: 'POST',
    body,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'content-Type': 'application/json;Charset=UTF-8',
      'X-Auth-Local-Address': getLocalIp()
    }
  };
  return executeFetch(url, option);
}

export async function requestPut(url, body) {
  body = JSON.stringify(body);
  const option = {
    method: 'PUT',
    body,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'content-Type': 'application/json;Charset=UTF-8',
      'X-Auth-Local-Address': getLocalIp()
    }
  };
  return executeFetch(url, option);
}

export async function requestDelete(url, body) {
  body = JSON.stringify(body);
  const option =
    body !== null
      ? {
        method: 'DELETE',
        body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'content-Type': 'application/json;Charset=UTF-8',
          'X-Auth-Local-Address': getLocalIp()
        }
      }
      : {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-Auth-Local-Address': getLocalIp()
        }
      };
  return executeFetch(url, option);
}

export async function executeFetch(url, option) {
  Logger.log(url);
  return fetch(url, option)
    .then(res => {
      return res.json();
    })
    .then(json => {
      Logger.log(json);
      return json;
    })
    .catch(err => {
      Logger.log(err);
      return 'failedToFetch';
    });
}
