import React from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch } from 'react-router-dom';

import MainNavbar from 'views/layouts/main/MainNavbar';
import MainFooter from 'views/layouts/main/MainFooter';

// import routes from 'routes.js';
// import nroutes from '../noSidebarRoutes.js';

import routesByAuth from 'commons/routes/routesByAuth.js';
import nroutesByAuth from 'commons/routes/noSidebarRoutesByAuth.js';

var ps;

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: 'brown',
      activeColor: 'info',
      sidebarMini: false,
      routes: routesByAuth(),
      nroutes: nroutesByAuth()
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
    this.setState({
      ...this.state,
      routes: [],
      nroutes: []
    });
  }

  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  getRoutes = routes => {
    const concatRouters = this.state.nroutes.concat(routes);
    return concatRouters.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '') {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => <prop.component endPoint={this.props.endPoint} {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    if (localStorage.getItem('token') !== null && localStorage.getItem('token') !== '') {
      return (
        <>
          <div ref="fullPages">
            <MainNavbar {...this.props} />
            <div>
              <Switch>{this.getRoutes(this.state.routes)}</Switch>
            </div>
          </div>
          <MainFooter />
        </>
      );
    } else {
      this.props.history.push({
        pathname: '/login/in'
      });
      return null;
    }
  }
}

export default MainLayout;
