import React, { Component } from 'react';
import { Label } from 'reactstrap';

class TotalCount extends Component {
  render() {
    const { totalElements } = this.props;
    return (
      <Label className="label-page" style={{ color: 'black' }}>
        총 {totalElements && totalElements.toLocaleString()}건
      </Label>
    );
  }
}

export default TotalCount;
