import React from 'react';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import Loader from 'views/components/loader/Loader';
import buildUrl from 'build-url';

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Table as RSTable
} from 'reactstrap';
import { requestGet } from 'utils/fetchHelper';

import ConfirmAlert from 'views/components/alert/confirmAlert/ConfirmAlert';
import MiniAlert from 'views/components/alert/miniAlert/MiniAlert';

import CompanyFilter from 'views/components/searchFilter/CompanyFilter';
import DepartmentFilter from 'views/components/searchFilter/DepartmentFilter';

import DetailAuthSummaryModal from './Detail';

import { isValidResponse } from 'utils/responseValidChecker';

import { inject, observer } from 'mobx-react';
import { getUserType } from 'utils/tokenHelper';

@inject('searchFilter', 'authSummary', 'confirmAlertModal', 'miniAlertModal', 'loader', 'table')
@observer
class AuthSummary extends React.Component {
  constructor(props) {
    super(props);
    moment.updateLocale(moment.locale(), { invalidDate: '' });
  }

  async componentDidMount() {
    const { searchFilter } = this.props;
    searchFilter.companyList.length === 0 && (await searchFilter.getCompanyList());
    searchFilter.set('startDate', moment().format('YYYY-MM-DD 00:00:00'));
    searchFilter.set('endDate', moment().format('YYYY-MM-DD 23:59:59'));

    this.fetchRefresh();
  }

  componentWillUnmount() {
    const { searchFilter, authSummary } = this.props;
    searchFilter.clear();
    authSummary.set('data', null);
  }

  // 인증 현황 조회 API 호출
  fetchRefresh = async () => {
    const { searchFilter, miniAlertModal, loader, authSummary } = this.props;
    loader.show(true);

    let queryParams = {};
    queryParams.companyId = searchFilter.selectCompany.value;
    searchFilter.selectDepartment.value !== '' && (queryParams.departmentId = searchFilter.selectDepartment.value);
    (await searchFilter.startDate) !== '' &&
      (queryParams.startDate = moment(searchFilter.startDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
    (await searchFilter.endDate) !== '' &&
      (queryParams.endDate = moment(searchFilter.endDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/v1/admin/api/auth/summary',
      queryParams: queryParams
    });

    let responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    authSummary.set('data', responseData.data);
  };

  // 구간 버튼 클릭 이벤트 처리
  handleDateButtonClick = async (e, range) => {
    const { searchFilter } = this.props;
    let from = moment();
    let to = moment();
    switch (range) {
      case '0days':
        from = moment()
          .subtract(0, 'days')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case '1days':
        from = moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case '3days':
        from = moment()
          .subtract(3, 'days')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case '7days':
        from = moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
      default:
        from = moment()
          .subtract(3, 'days')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
    }

    await searchFilter.set('startDate', from);
    await searchFilter.set('endDate', to);
    await searchFilter.set('page', 1);

    this.fetchRefresh();
  };

  // 데이터 테이블 클릭 - 인증 현황 상세 모달
  handleEditRowClick = async data => {
    const { searchFilter, miniAlertModal, loader, authSummary } = this.props;

    if (getUserType().slice(2, 4) === 'RO') {
      return;
    }

    loader.show(true);

    let queryParams = {};
    queryParams.companyId = searchFilter.selectCompany.value;
    searchFilter.startDate !== '' &&
      (queryParams.startDate = moment(searchFilter.startDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
    searchFilter.endDate !== '' &&
      (queryParams.endDate = moment(searchFilter.endDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/v1/admin/api/auth/summary/' + data.departmentId,
      queryParams: queryParams
    });

    console.log('[url]' + url);

    let responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    authSummary.setDetailAuthSummary('detailAuthSummary', null, null, responseData.data);
    authSummary.set('departmentName', data.departmentName);
    authSummary.set('detailModalIsOpen', !authSummary.detailModalIsOpen);
  };

  // 검색 버튼 클릭 이벤트
  handleSearchClick = async () => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    this.fetchRefresh();
  };

  render() {
    let { confirmAlertModal, miniAlertModal, loader, authSummary, searchFilter } = this.props;
    return (
      <>
        {loader.showLoader && <Loader></Loader>}
        <div className="content">
          <h4 className="__marginTop">지점별 인증현황 집계</h4>
          <h6>날짜 별로 지점별 인증시도/성공/실패 건수 및 인증실패율을 확인할 수 있습니다.</h6>
          <Card className="card-stats">
            <CardBody className="__padding">
              <Form className="form-horizontal">
                <Row md="12">
                  <Col md={{ size: 10, offset: 1 }} className="text-center">
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        회사명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <CompanyFilter refreshData={this.fetchRefresh}></CompanyFilter>
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        지점명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <DepartmentFilter refreshData={this.fetchRefresh}></DepartmentFilter>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        조회 시작일
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <ReactDatetime
                            inputProps={{
                              className: 'form-control datetime-custom',
                              placeholder: moment(this.props.searchFilter.startDate, 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              value: moment(this.props.searchFilter.startDate, 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              readOnly: true
                            }}
                            dateFormat="YYYYMMDD"
                            timeFormat="HH:mm"
                            onChange={e => {
                              this.props.searchFilter.set('startDate', moment(e).format('YYYY-MM-DD HH:mm:ss'));
                            }}
                            locale="ko"
                            // timeFormat={true}
                            // input={true}
                          />
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        조회 종료일
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <ReactDatetime
                            inputProps={{
                              className: 'form-control datetime-custom',
                              placeholder: moment(this.props.searchFilter.endDate, 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              value: moment(this.props.searchFilter.endDate, 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              readOnly: true
                            }}
                            dateFormat="YYYYMMDD"
                            timeFormat="HH:mm"
                            onChange={e => {
                              this.props.searchFilter.set('endDate', moment(e).format('YYYY-MM-DD HH:mm:ss'));
                            }}
                            locale="ko"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="12">
                        <ButtonGroup data-toggle="buttons">
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '0days');
                            }}
                          >
                            today
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '1days');
                            }}
                          >
                            1 일
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '3days');
                            }}
                          >
                            3 일
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '7days');
                            }}
                          >
                            7 일
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="12">
                        <Button className="btn-round __marginTop" color="primary" onClick={this.handleSearchClick}>
                          조회
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="form-horizontal __marginBottom5">
                <Col md="12" className="text-right"></Col>
              </Row>
              <Row className="form-horizontal">
                <Col md="12">
                  <RSTable bordered striped>
                    <thead>
                      <tr className="table-header text-center underline-dategroup-tr">
                        <th width="5%">
                          <span style={{ whiteSpace: 'pre-line' }}>순번</span>
                        </th>
                        <th width="35%">
                          <span style={{ whiteSpace: 'pre-line' }}>지점명</span>
                        </th>
                        <th width="15%">
                          <span style={{ whiteSpace: 'pre-line' }}>인증실패율</span>
                        </th>
                        <th width="15%">
                          <span style={{ whiteSpace: 'pre-line' }}>총인증시도건수</span>
                        </th>
                        <th width="15%">
                          <span style={{ whiteSpace: 'pre-line' }}>인증실패건수</span>
                        </th>
                        <th width="15%">
                          <span style={{ whiteSpace: 'pre-line' }}>서버에러건수</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tbody-fontsize-sm">
                      {authSummary.data !== null ? this.renderTable(authSummary.data) : null}
                    </tbody>
                  </RSTable>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col lg="12" xl="12" className="text-left" style={{ fontSize: '14px', marginTop: '5px' }}>
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {`
                      - 인증실패율이 높은 지점순으로 조회됩니다.
                      - 총인증시도건수, 인증실패건수, 서버에러건수는 검색기간의 합계건수를 의미합니다.
                      - 실패율(%) = 실패건수 / 시도건수 * 100 (실패율이 80%이상인 경우, 해당 Row가 붉은색으로 표시됩니다.)
                      - ROW 클릭시 해당지점의 검색기간내 일자별 인증현황 집계정보가 팝업으로 조회됩니다.
                    `}
                  </span>
                  <span style={{ whiteSpace: 'pre-line', color: 'red' }}>
                    {` 
                      - 인증시도건수가 유의미하고 인증실패율이 100%인 경우에는 '지점별 단말인증현황 집계'화면으로 이동하여 해당지점의 단말인증실패율을 확인하시기 바랍니다.
                    `}
                  </span>
                </Col>
              </Row>
            </CardFooter>
          </Card>

          {/* 상세 현황 모달 */}
          {authSummary.detailModalIsOpen && <DetailAuthSummaryModal {...this.props} fetchRefresh={this.fetchRefresh} />}

          {/* 알림 팝업 */}
          {miniAlertModal.isOpen && <MiniAlert />}

          {/* 사용자 확인 알림 모달 */}
          {confirmAlertModal.isOpen && <ConfirmAlert />}
        </div>
      </>
    );
  }

  renderTable = data => {
    const rowData = data.map((row, index) => {
      let failRateTextColor = '#35a6ff';
      let countTextColor = '';
      let backGroundColor = '';

      const failRate = row.failRate.toFixed(2);

      if (failRate >= 80) {
        failRateTextColor = '#FFFFFF';
        countTextColor = failRateTextColor;

        backGroundColor = '#ffc61e';
      }
      if (failRate >= 90) {
        failRateTextColor = '#FFFFFF';
        countTextColor = failRateTextColor;

        backGroundColor = '#ffa81e';
      }
      if (failRate >= 100) {
        failRateTextColor = '#FFFFFF';
        countTextColor = failRateTextColor;

        backGroundColor = '#ff803e';
      }

      return (
        <tr key={index} onClick={() => this.handleEditRowClick(row)} style={{ backgroundColor: backGroundColor }}>
          <td className="text-center">{(index + 1).toString()}</td>
          <td className="text-center" style={{ fontWeight: 'bold', color: countTextColor }}>
            {row.departmentName}
          </td>
          <td className="text-right" style={{ fontWeight: 'bold', color: failRateTextColor }}>
            {failRate}
          </td>
          <td className="text-right" style={{ color: countTextColor }}>
            {row.authTotCount}
          </td>
          <td className="text-right" style={{ color: countTextColor }}>
            {row.authFailCount}
          </td>
          <td className="text-right" style={{ color: countTextColor }}>
            {row.serverErrorCount}
          </td>
        </tr>
      );
    });

    return rowData;
  };
}

export default AuthSummary;
