function setLocalIp() {
  // 브라우저마다 prefix가 달라 아래와 같이 처리한다.
  let RTCPeerConnection =
        window.RTCPeerConnection ||
        window.webkitRTCPeerConnection ||
        window.mozRTCPeerConnection ||
        window.msRTCPeerConnection;

  // RTCPeerConnection 객체 생성
  let rtc = new RTCPeerConnection();

  // 임의의 이름으로 채널 생성
  rtc.createDataChannel('TEMP');

  // 이벤트 핸들러 설정, ice 후보가 감지 되었을때 호출됩니다.
  // 원래는 이곳에서 해당 candidate를 현재 커넥션에 연결해야 하나, ip를 알아내는 것이 목적이기 때문에 별다른 행동을 하지 않습니다.
  rtc.onicecandidate = function(iceevent) {
    if (iceevent && iceevent.candidate && iceevent.candidate.candidate) {
      let r = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;
      let t = iceevent.candidate.candidate.match(r);
      // localStorage.setItem('ip', t[0]); // t[0] == ip addressauth
      localStorage.setItem('ip', '127.0.0.1'); // t[0] == ip address
    }
  };

  // createOffer를 호출하면 연결하고자 하는 SDP가 생성됩니다. createOffer()의 결과로 offer가 생성되는데 이를 다시 setLocalDescription를 통해 설정하면
  // 로컬의 엔드포인트가 생성이 완료됩니다. 이렇게 생성된 Description을 연결하고자 하는 원격지에 전달해야 하나 이역시 해당 예제에서는 필요가 없습니다.
  // setLocalDescription후에 onicecandidate가 호출됩니다.
  rtc.createOffer().then(offer => rtc.setLocalDescription(offer));// Local Ip 저장
}


export function getLocalIp() {
  if (localStorage.getItem('ip') === null) {
    setLocalIp();
  }
  return localStorage.getItem('ip');
}