import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Button, Collapse, Navbar, NavItem, NavLink as NavLink2, Nav, Container } from 'reactstrap';

import { getUserType } from 'utils/tokenHelper';
import { inject, observer } from 'mobx-react';

@inject('userInfo')
@observer
class CategoryNavbar extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: 'navbar-transparent'
    };
  }
  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this.updateColor);
  }
  componentDidUpdate(e) {
    if (window.outerWidth < 993 && e.history.location.pathname !== e.location.pathname && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this._isMounted &&
        this.setState({
          color: 'bg-white'
        });
    } else {
      this._isMounted &&
        this.setState({
          color: 'navbar-transparent'
        });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState['color'] = 'bg-white';
    } else {
      newState['color'] = 'navbar-transparent';
    }
    this.setState(newState);
  };

  logoutClick = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('companyId');
    localStorage.removeItem('companyName');
    localStorage.removeItem('ip');
    this.props.userInfo.set('resetOption', '');

    this.props.history.push({
      pathname: '/login/logout'
    });
  };

  navLink2Style = {
    cursor: 'pointer'
  };

  render() {
    return (
      <>
        <Navbar className={classnames('navbar-absolute fixed-top', this.state.color)} expand="lg">
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize">
                <Button className="btn-icon btn-round" color="custom" id="minimizeSidebar" onClick={this.props.handleMiniClick}>
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button>
              </div>
              <div
                className={classnames('navbar-toggle', {
                  toggled: this.state.sidebarOpen
                })}>
                <button className="navbar-toggler" type="button" onClick={this.toggleSidebar}>
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}>
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse className="justify-content-end" navbar isOpen={this.state.collapseOpen}>
              <Nav navbar>
                <NavItem>
                  <NavLink to="/dashboard" className="nav-link">
                    HOME
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/devices/department" className="nav-link">
                    인증 서비스
                  </NavLink>
                </NavItem>
                {(getUserType().slice(0, 2) === 'SA' || getUserType() === 'CDSU')  && (
                  <NavItem>
                    <NavLink to="/management/user" className="nav-link">
                      관리
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink to="/contact" className="nav-link">
                    서비스 문의
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav navbar className="ml-auto">
                <NavItem>
                  <NavLink to="/userinfo/:path" className="nav-link">
                    마이페이지 <i className="fa fa-cog fa-2x" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink2 style={this.navLink2Style} onClick={this.logoutClick} className="nav-link">
                    로그아웃
                  </NavLink2>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default CategoryNavbar;
