import React from 'react';
import { Button, Modal, Row, Col, Label, FormGroup, Input } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

import { requestPut } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';

// 지점 수정 모달
@inject('department', 'searchFilter', 'miniAlertModal', 'loader', 'miniAlertModal')
@observer
class ModifyDepartmentModal extends React.Component {
  superPassInput = null;

  componentDidMount() {
    const { department } = this.props;
    department.editDepartment.superPassYn === 'Y' ? this.superPassInput.checked = true : this.superPassInput.checked = false;
  }

  toggleModal = () => {
    const { department } = this.props;
    department.set('modifyModalIsOpen', !department.modifyModalIsOpen);
  };

  // 우편번호, 주소 찾기
  searchAddr = targetObjectName => {
    new window.daum.Postcode({
      oncomplete: data => {
        kakao.maps.load(() => {
          const geocoder = new window.daum.maps.services.Geocoder();
          geocoder.addressSearch(data.roadAddress, (result, status) => {
            if (status === window.daum.maps.services.Status.OK) {
              this.props.department.setDepartment(targetObjectName, null, null, {
                ...this.props.department[targetObjectName],
                zip: data.zonecode,
                address: data.jibunAddress,
                roadAddress: data.roadAddress,
                lat: result[0].y,
                lon: result[0].x
              });
            }
          });
        });
      }
    }).open();
  };

  // 지점 수정 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    const { department, miniAlertModal } = this.props;
    const editDepartment = toJS(department.editDepartment);
    if (
      editDepartment.name === '' ||
      editDepartment.wifi === '' ||
      editDepartment.lat === '' ||
      editDepartment.lon === '' ||
      editDepartment.roadAddress === '' ||
      editDepartment.roadAddressDetail === '' ||
      editDepartment.zip === ''
    ) {
      miniAlertModal.show('모든 값을 입력해주세요.');
      return false;
    }
    this.fetchModfiyDepartment();
  };

  // 지점 수정 API 호출
  fetchModfiyDepartment = async () => {
    const { miniAlertModal, loader, searchFilter, department, fetchRefresh } = this.props;
    loader.show(true);
    const url =
      process.env.REACT_APP_ENDPOINT +
      '/v1/admin/api/companies/' +
      toJS(department.editDepartment.companyId) +
      '/departments';
    const body = {
      department: toJS(department.editDepartment)
    };
    const responseData = await requestPut(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      department.setDepartment('editDepartment', null, null, {
        companyId: '',
        name: '',
        code: '',
        wifi: '',
        lat: '',
        lon: '',
        address: '',
        addressDetail: '',
        roadAddress: '',
        roadAddressDetail: '',
        zip: '',
        superPassYn: 'N'
      });

      await searchFilter.set('page', 1);
      fetchRefresh();
      miniAlertModal.show('수정되었습니다.');
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  render() {
    const { department, searchFilter, miniAlertModal } = this.props;
    return (
      <Modal className="modal-lg" backdrop={'static'} isOpen={department.modifyModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">지점 수정</h4>
        </div>
        <div className="modal-body __modalScrollbar">
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">회사명 *</Label>
                <FormGroup className="col-md-10">
                  <Input
                    readOnly
                    className="__textAlignCenter"
                    type="text"
                    name="companyName"
                    value={searchFilter.selectCompany.label}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="row">
                <Label className="col-md-4 col-form-label font-weight-bold text-center">지점명 *</Label>
                <FormGroup className="col-md-8">
                  <Input
                    className="__textAlignCenter"
                    type="text"
                    name="name"
                    onChange={e => {
                      department.setDepartment('editDepartment', 'name', e.target.value);
                    }}
                    value={department.editDepartment.name}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col md="6">
              <div className="row">
                <Label className="col-md-4 col-form-label font-weight-bold text-center">부점코드</Label>
                <FormGroup className="col-md-8">
                  <Input
                    className="__textAlignCenter"
                    type="text"
                    name="code"
                    onChange={e => {
                      department.setDepartment('editDepartment', 'code', e.target.value);
                    }}
                    value={department.editDepartment.code ? department.editDepartment.code : ''}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="row">
                <Label className="col-md-4 col-form-label font-weight-bold text-center">최소 AP 수 *</Label>
                <FormGroup className="col-md-8">
                  <Input
                    className="__textAlignCenter"
                    type="text"
                    name="wifi"
                    onChange={e => {
                      department.setDepartment('editDepartment', 'wifi', e.target.value.replace(/[^0-9]/g, ''));
                    }}
                    value={department.editDepartment.wifi}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col md="6" style={{display: 'flex'}}>
              <Label className="col-md-4 col-form-label font-weight-bold text-center">
                <p style={{color: '#ff2222', display: 'inline'}}>슈퍼패스</p> ⚠️
              </Label>
              <FormGroup check>
                <Label check>
                  <Input
                    innerRef={ref => (this.superPassInput = ref)}
                    type="checkbox"
                    onChange={e => {
                      if (e.target.checked) {
                        miniAlertModal.show('해당 지점을 항상\n지점 내로 판단합니다.');
                        department.setDepartment('editDepartment', 'superPassYn', 'Y');
                      } else {
                        department.setDepartment('editDepartment', 'superPassYn', 'N');
                      }
                    }}
                    value={department.editDepartment.superPassYn}
                  />
                  <span className="form-check-sign reverse" />
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">우편번호 *</Label>
                {process.env.REACT_APP_FILE_SYSTEM === 'S3' ? (
                  <>
                    <FormGroup className="col-md-7">
                      <Input className="__textAlignCenter" type="text" readOnly value={department.editDepartment.zip} />
                    </FormGroup>
                    <div className="col-md-3">
                      <Button
                        className="btn-round __marginTop"
                        onClick={() => {
                          this.searchAddr('editDepartment');
                        }}
                        color="primary"
                      >
                        우편번호 찾기
                      </Button>
                    </div>
                  </>
                ) : (
                  <FormGroup className="col-md-10">
                    <Input
                      className="__textAlignCenter"
                      type="text"
                      onChange={e => {
                        department.setDepartment('editDepartment', 'zip', e.target.value.replace(/[^0-9]/g, ''));
                      }}
                      value={department.editDepartment.zip}
                    />
                  </FormGroup>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">주소 *</Label>
                {process.env.REACT_APP_FILE_SYSTEM === 'S3' ? (
                  <FormGroup className="col-md-10">
                    <Input
                      className="__textAlignCenter"
                      type="text"
                      readOnly
                      value={department.editDepartment.roadAddress}
                    />
                  </FormGroup>
                ) : (
                  <FormGroup className="col-md-10">
                    <Input
                      className="__textAlignCenter"
                      type="text"
                      value={department.editDepartment.roadAddress}
                      onChange={e => {
                        department.setDepartment('editDepartment', 'roadAddress', e.target.value);
                      }}
                    />
                  </FormGroup>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">상세주소 *</Label>
                <FormGroup className="col-md-10">
                  <Input
                    className="__textAlignCenter"
                    type="text"
                    name="roadAddressDetail"
                    onChange={e => {
                      department.setDepartment('editDepartment', 'roadAddressDetail', e.target.value);
                    }}
                    value={department.editDepartment.roadAddressDetail}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}
            >
              수정
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
              닫기
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

ModifyDepartmentModal.defaultProps = {
  fetchRefresh: async () => {
    console.log('[ModifyDepartmentModal] - refresh function is undefined');
  }
};

export default ModifyDepartmentModal;
