import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class CustomPagination extends Component {
  static propTypes = {
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    pageSize: PropTypes.number,
    callApi: PropTypes.func,
    callbackFunction: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      pageLength: this.createArray()
    };
  }

  state = {
    ...this.props
  };

  async componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      await this.setState({
        ...nextProps
      });
    }
    this.pageCalc = parseInt((this.state.currentPage - 1) / 10) * 10;
    this.pageEnd = this.pageCalc + 10 < this.state.totalPages ? this.pageCalc + 10 : this.state.totalPages;
    await this.setState({
      pageLength: this.createArray()
    });
  }

  pageCalc = parseInt((this.state.currentPage - 1) / 10) * 10;
  pageEnd =
    this.pageCalc + this.state.pageSize < this.state.totalPages
      ? this.pageCalc + this.state.pageSize
      : this.state.totalPages;

  createArray = () => {
    const item = [];
    for (let i = this.pageCalc + 1; i <= this.pageEnd; i++) {
      item.push(i);
    }
    return item;
  };

  callback = data => {
    this.props.callback(data);
  };

  render() {
    return (
      <Pagination className="pagination pagination-primary __justify-content" listClassName="pagination-primary">
        {this.state.currentPage > 10 && (
          <PaginationItem key="prev">
            <PaginationLink
              previous
              onClick={e => {
                e.preventDefault();
                this.callback(this.pageCalc);
              }}
            />
          </PaginationItem>
        )}
        {this.state.pageLength.map(data => {
          return (
            <PaginationItem key={data} className={data === this.state.currentPage ? 'active' : ''}>
              <PaginationLink
                onClick={e => {
                  e.preventDefault();
                  this.callback(data);
                }}>
                {data}
              </PaginationLink>
            </PaginationItem>
          );
        })}
        {parseInt((this.state.currentPage - 1) / 10) !== parseInt((this.state.totalPages - 1) / 10) && (
          <PaginationItem key="next">
            <PaginationLink
              next
              onClick={e => {
                e.preventDefault();
                this.callback(this.pageEnd + 1);
              }}
            />
          </PaginationItem>
        )}
      </Pagination>
    );
  }
}

export default CustomPagination;
