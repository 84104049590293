import { observable, action, reaction } from 'mobx';

// 마이페이지
export class MobxStore {
  constructor() {
    this.buttonTitle = {
      checkPassword: '비밀번호 확인',
      changePassword: '비밀번호 변경',
      modifyUserInfo: '저장'
    };
    this.data = {
      companyName: '',
      id: '',
      password: '',
      confirmPassword: '',
      name: '',
      tel: '',
      email: ''
    };
    this.isReadOnly = {
      companyName: true,
      id: true,
      password: false,
      confirmPassworkd: true,
      name: true,
      tel: true,
      email: true
    };
    this.editStatus = 'checkPassword';
    this.expireType = '';
    this.resetOption = '';
    this.passwordHint = 'normal';
  }

  @observable buttonTitle; // 버튼 텍스트
  @observable data; // 마이페이지 데이터
  @observable isReadOnly; // Input ReadOnly 상태
  @observable editStatus; // 페이지 상태 - checkPassword: 비밀번호 확인 / changePassword: 초기 비밀먼호 변경 / modifyUserInfo: 유저 정보 수정
  // 비밀번호 변경 사유 - TEMP_PWD: 임시 비밀번호, PWD: 패스워드 만료, ACC: 계정 유효기간 만료
  @observable expireType;
  @observable resetOption;
  @observable passwordHint; // passwordHint 폰트 컬러 스타일

  @action set = (key, value) => {
    this[key] = value;
  };

  changedEditStatus = reaction(
    () => this.editStatus,
    editStatus => {
      switch (editStatus) {
      case 'modifyUserInfo': // 유저 정보 수정
        this.set('isReadOnly', {
          companyName: true,
          id: true,
          password: false,
          confirmPassworkd: false,
          name: false,
          tel: false,
          email: false
        });
        break;
      case 'checkPassword': // 비밀번호 확인
      case 'changePassword': // 초기 비밀번호 변경
      default:
        this.set('isReadOnly', {
          companyName: true,
          id: true,
          password: false,
          confirmPassworkd: false,
          name: true,
          tel: true,
          email: true
        });
        break;
      }
    }
  );
}
