import React, {useState} from 'react';
import Select from 'react-select';
import Loader from 'views/components/loader/Loader';
import buildUrl from 'build-url';
import Pagination from 'views/components/pagination/CustomPagination';
import { getUserType } from 'utils/tokenHelper';
import { Button, Card, CardBody, CardFooter, Label, FormGroup, Form, Input, Row, Col } from 'reactstrap';
import { requestGet, requestDelete } from 'utils/fetchHelper';
import { autoBssidFormatter } from 'utils/fomatter';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/scss/custom-style/customApWidth.scss';

import RegisterApModal from './Register';
import ModifyApModal from './Modify';

import ConfirmAlert from 'views/components/alert/confirmAlert/ConfirmAlert';
import MiniAlert from 'views/components/alert/miniAlert/MiniAlert';
import ToastAlert from 'views/components/alert/toastAlert/ToastAlert';

import {
  AP_ADMIN_TABLE_HEADER,
  AP_CUSTOMER_TABLE_HEADER,
  BLE_BATTERY_ADMIN_TABLE_HEADER
} from 'commons/conts/tableHeader';
import Table from 'views/components/table/checkbox/table';

import CompanyFilter from 'views/components/searchFilter/CompanyFilter';
import DepartmentFilter from 'views/components/searchFilter/DepartmentFilter';

import { isValidResponse } from 'utils/responseValidChecker';

import { toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import TotalCount from 'views/components/common/totalCount/TotalCount';
import ReactDatetime from 'react-datetime';
import moment from 'moment';

@inject('searchFilter', 'ap', 'confirmAlertModal', 'miniAlertModal', 'toastAlert', 'loader', 'table')
@observer
class Ap extends React.Component {
  radioWifi = '';

  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.radioWifi.checked = true;
    this.props.searchFilter.companyList.length === 0 && (await this.props.searchFilter.getCompanyList());
    await this.fetchRefresh();
  }

  componentWillUnmount() {
    const { searchFilter, table, ap } = this.props;
    searchFilter.clear();
    table.set('selectedData', []);
    ap.set('data', null);
  }

  // 위치인증 Ap 조회 API 호출
  fetchRefresh = async () => {
    const { searchFilter, miniAlertModal, loader, ap, table } = this.props;
    loader.show(true);
    let queryParams = {};
    queryParams.companyId = searchFilter.selectCompany.value;
    searchFilter.selectDepartment.value !== '' && (queryParams.departmentId = searchFilter.selectDepartment.value);
    queryParams.type = searchFilter.deviceType;
    searchFilter.ssid !== '' && (queryParams.ssid = searchFilter.ssid);
    searchFilter.bssid !== '' && (queryParams.bssid = searchFilter.bssid);
    searchFilter.unUsedTime !== '' && (queryParams.unUsedTime = searchFilter.unUsedTime);
    searchFilter.battery !== '' && (queryParams.battery = searchFilter.battery);
    queryParams.page = searchFilter.page !== '' ? searchFilter.page : 1;
    queryParams.size = searchFilter.pageSize.value !== '' ? searchFilter.pageSize.value : 10;

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/v1/admin/api/devices',
      queryParams: queryParams
    });

    const responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);
    const editedResponseData = await this.addCustomColumn(responseData); // 지점명 + 지점ID 처리
    // table.set('data', editedResponseData.data);
    ap.set('data', editedResponseData.data);
  };

  // 지점명(지점ID) 및 Ap 타입 칼럼 추가
  addCustomColumn = async response => {
    const editedContent = response.data.content.map(item => {
      return {
        ...item,
        customDepartmentInfo: item.departmentName + ' (' + item.departmentId + ')',
        customDeviceType: item.deviceType === 'BLE' ? 'BLE Beacon' : item.deviceType === 'WIFI' ? 'WIFI' : 'NFC'
      };
    });
    response.data.content = editedContent;
    return response;
  };

  // 데이터 테이블 클릭 - 위치 인증 Ap 수정 모달
  handleEditRowClick = async data => {
    if (getUserType().slice(2, 4) === 'RO') {
      return;
    }
    const { ap } = this.props;
    await ap.setEditAps('editAps', null, null, {
      deviceId: data.deviceId,
      type: data.deviceType,
      department: {
        label: data.departmentName,
        value: data.departmentId
      },
      ssid: data.ssid,
      bssid: data.bssid,
      rssiFilter: data.rssi !== null ? data.rssi : ''
    });
    ap.set('modifyModalIsOpen', !ap.modifyModalIsOpen);
  };

  // 삭제 버튼 클릭 처리
  handleDeleteClick = async () => {
    const { table, miniAlertModal, confirmAlertModal } = this.props;
    if (toJS(table.selectedData).length === 0) {
      miniAlertModal.show('삭제할 디바이스를 선택해주세요.');
    } else {
      confirmAlertModal.show(
          '선택한 데이터를 삭제합니다.\n',
          '삭제된 데이터는 복원할 수 없습니다.\n그래도 삭제 하시겠습니까?',
          '',
          this.fetchDeleteAps
      );
    }
  };

  // 위치인증 Ap 삭제 API 호출
  fetchDeleteAps = async () => {
    const { table, loader, miniAlertModal, searchFilter } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/devices';
    const body = {
      devices: table.selectedData.map(ap => {
        return {
          deviceId: ap.deviceId
        };
      })
    };
    const responseData = await requestDelete(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      miniAlertModal.show('삭제되었습니다.');
      await searchFilter.set('page', 1);
      this.fetchRefresh();
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  // 등록 버튼 클릭 이벤트 처리
  handleRegisterButtonClick = async () => {
    const { ap } = this.props;
    await ap.clearRegisterAps();
    ap.set('registerModalIsOpen', !ap.registerModalIsOpen);
  };

  // 페이징 컴포넌트 콜백 함수
  handlePaginationCallback = async page => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', page);
    await this.fetchRefresh();
  };

  // 검색 조건 엔터키 입력 이벤트 처리
  handleEnterKeyPress = async e => {
    const { searchFilter } = this.props;
    if (e.key === 'Enter') {
      await searchFilter.set('page', 1);
      await this.fetchRefresh();
    }
  };

  // 조회 버튼 클릭 이벤트 처리
  handleSearchClick = async e => {
    const { searchFilter } = this.props;
    searchFilter.set('page', 1);
    await this.fetchRefresh();
  };

  // 위치 인증 Ap 타입 라디오 버튼 변경 이벤트 처리
  handleRadioChange = async e => {
    const { searchFilter } = this.props;
    const deviceType = e.target.value;
    await searchFilter.set('page', 1);
    await this.props.searchFilter.set('deviceType', deviceType);
    await this.fetchRefresh();
  };

  // 페이지 당 검색 결과 수 변경 이벤트 함수
  handlePageSizeChanged = async e => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    await searchFilter.setPageSize(e);
    await this.fetchRefresh();
  };

  handleTableHeader = () => {
    if((getUserType().slice(0, 2) === 'SA' || getUserType() === 'CDSU') && this.props.searchFilter.deviceType === 'BLE'){
      return BLE_BATTERY_ADMIN_TABLE_HEADER;
    }else if((getUserType().slice(0, 2) === 'SA' || getUserType() === 'CDSU') && this.props.searchFilter.deviceType === 'WIFI'){
      return AP_ADMIN_TABLE_HEADER;
    }else{
      return AP_CUSTOMER_TABLE_HEADER;
    }
  }

  handleSelectedDate = (e) => {
    this.selectedDate = e;
  }

  render() {
    let { ap, confirmAlertModal, miniAlertModal, loader, searchFilter, toastAlert } = this.props;
    const userType = getUserType();
    return (
        <>
          {loader.showLoader && <Loader></Loader>}
          <div className="content">
            <h4>위치인증 AP 관리</h4>
            <h6>위치인증 AP의 정보를 확인하실 수 있습니다.</h6>

            <Card className="card-stats">
              <CardBody className="__padding">
                <Form className="form-horizontal">
                  <Row md="12">
                    <Col md={{ size: 10, offset: 1 }} className="text-center">
                      <Row md="12">
                        <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                          회사명
                        </Label>
                        <Col md="4" sm="8" xs="8">
                          <FormGroup>
                            <CompanyFilter refreshData={this.fetchRefresh} />
                          </FormGroup>
                        </Col>
                        <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                          지점명
                        </Label>
                        <Col md="4" sm="8" xs="8">
                          <FormGroup>
                            <DepartmentFilter refreshData={this.fetchRefresh} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row md="12">
                        <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                          SSID
                        </Label>
                        <Col md="4" sm="8" xs="8">
                          <FormGroup>
                            <Input
                                className="text-center"
                                type="text"
                                name="ssid"
                                value={this.props.searchFilter.ssid}
                                onChange={e => {
                                  this.props.searchFilter.set('ssid', e.target.value);
                                }}
                                onKeyPress={this.handleEnterKeyPress}
                                maxLength={32}
                            />
                          </FormGroup>
                        </Col>
                        <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                          BSSID
                        </Label>
                        <Col md="4" sm="8" xs="8">
                          <FormGroup>
                            <Input
                                className="text-center"
                                type="text"
                                name="bssid"
                                value={this.props.searchFilter.bssid}
                                onChange={e => {
                                  this.props.searchFilter.set(
                                      'bssid',
                                      autoBssidFormatter(e.target.value.replace(/[^a-f0-9]/gi, ''))
                                  );
                                }}
                                onKeyPress={this.handleEnterKeyPress}
                                maxLength={17}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {(searchFilter.deviceType === 'BLE') && (
                          // <Row md="12">
                          //   <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                          //     미사용 기간 (주 단위)
                          //   </Label>
                          //   <Col md="4" sm="8" xs="8">
                          //     <FormGroup>
                          //       <Input
                          //           className="text-center"
                          //           type="text"
                          //           name="unUsedTime"
                          //           value={this.props.searchFilter.unUsedTime}
                          //           onChange={e => {
                          //             this.props.searchFilter.set('unUsedTime', e.target.value);
                          //           }}
                          //           onKeyPress={this.handleEnterKeyPress}
                          //           maxLength={17}
                          //       />
                          //     </FormGroup>
                          //   </Col>
                          <Row md="12">
                            <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                              미사용 기준일
                            </Label>
                            <Col md="4" sm="8" xs="8">
                              <FormGroup>
                                <ReactDatetime
                                    inputProps={{
                                      className: 'form-control datetime-custom',
                                      placeholder: moment(this.props.searchFilter.unUsedTime, 'YYYY-MM-DD HH:mm:ss').format(
                                          'YYYY-MM-DD HH:mm'
                                      ),
                                      value: moment(this.props.searchFilter.unUsedTime, 'YYYY-MM-DD HH:mm:ss').format(
                                          'YYYY-MM-DD HH:mm'
                                      ),
                                      readOnly: true
                                    }}
                                    dateFormat='YYYYMMDD'
                                    timeFormat='HH:mm'
                                    onChange={e => {
                                      this.props.searchFilter.set('unUsedTime', e);
                                    }}
                                    locale='ko'
                                    // timeFormat={true}
                                    // input={true}
                                />
                              </FormGroup>
                            </Col>
                            <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                              배터리 잔량 (%)
                            </Label>
                            <Col md="4" sm="8" xs="8">
                              <FormGroup>
                                <div className="customApWidth">
                                <Input
                                    type="range"
                                    max={100}
                                    min={1}
                                    step={1}
                                    name="battery"
                                    value={this.props.searchFilter.battery}
                                    onChange={e => {
                                      this.props.searchFilter.set('battery', e.target.value);
                                    }}
                                    onKeyPress={this.handleEnterKeyPress}
                                />
                                </div>
                                {this.props.searchFilter.battery}
                              </FormGroup>
                            </Col>
                          </Row>
                      )}
                      <Row md="12">
                        <Col md="12">
                          <div className="form-check-radio form-check-inline">
                            <Label className="form-check-label">
                              <Input
                                  innerRef={ref => (this.radioWifi = ref)}
                                  type="radio"
                                  name="deviceType"
                                  onChange={this.handleRadioChange}
                                  value="WIFI"
                              />
                              WiFi
                              <span className="form-check-sign"></span>
                            </Label>
                          </div>
                          <div className="form-check-radio form-check-inline">
                            <Label className="form-check-label">
                              <Input type="radio" name="deviceType" onChange={this.handleRadioChange} value="BLE" />
                              BLE Beacon
                              <span className="form-check-sign"></span>
                            </Label>
                          </div>
                          {/* userType이 super admin일 경우 NFC 항목을 보여준다. */}
                          {(userType === 'SARW' || userType === 'SARO') && (
                              <div className="form-check-radio form-check-inline">
                                <Label className="form-check-label">
                                  <Input type="radio" name="deviceType" onChange={this.handleRadioChange} value="NFC" />
                                  NFC
                                  <span className="form-check-sign"></span>
                                </Label>
                              </div>
                          )}
                        </Col>
                      </Row>
                      <Row md="12">
                        <Col md="12">
                          <Button className="btn-round" color="primary" onClick={this.handleSearchClick}>
                            조회
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row className="form-horizontal __marginBottom5">
                  <Col md="12" className="text-right">
                    <div className="__inline-div">
                      <Select
                          className="react-select __pageSelect"
                          classNamePrefix="react-select"
                          name="pageSize"
                          placeholder=""
                          value={searchFilter.pageSize}
                          options={searchFilter.pageSizeList}
                          onChange={this.handlePageSizeChanged}
                      />
                      <Label className="label-page">페이지 사이즈</Label>
                      <TotalCount totalElements={ap.data && ap.data.totalElements} />
                    </div>
                  </Col>
                </Row>
                <Row className="form-horizontal">
                  <Col md="12">
                    <Table
                        header={
                          this.handleTableHeader()
                        }
                        data={ap.data}
                        handleRowClick={this.handleEditRowClick}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md={{ offset: 3, size: 6 }}>
                    {ap.data && (
                        <Pagination
                            currentPage={searchFilter.page}
                            totalPages={ap.data.totalPages}
                            pageSize={ap.data.pageable.pageSize}
                            callback={this.handlePaginationCallback}
                        />
                    )}
                  </Col>
                  {getUserType().slice(2, 4) !== 'RO' && (
                      <Col lg="12" xl="12" className="text-right">
                        <div className="__inline-div __button-margin">
                          <Button
                              className="btn-round __marginTop"
                              onClick={this.handleRegisterButtonClick}
                              color="primary"
                          >
                            등록
                          </Button>
                        </div>
                        <div className="__inline-div __button-margin">
                          <Button
                              className="btn-round __marginTop __button-margin"
                              onClick={this.handleDeleteClick}
                              color="primary"
                          >
                            삭제
                          </Button>
                        </div>
                      </Col>
                  )}
                </Row>
              </CardFooter>
            </Card>

            {/* 등록 모달 */}
            {ap.registerModalIsOpen && <RegisterApModal {...this.props} fetchRefresh={this.fetchRefresh} />}

            {/* 수정 모달 */}
            {ap.modifyModalIsOpen && <ModifyApModal {...this.props} fetchRefresh={this.fetchRefresh} />}

            {/* 알림 팝업 */}
            {miniAlertModal.isOpen && <MiniAlert />}

            {/* 사용자 확인 알림 모달 */}
            {confirmAlertModal.isOpen && <ConfirmAlert />}

            {/* 토스트 메시지 팝업 */}
            {toastAlert.isOpen && <ToastAlert />}
          </div>
        </>
    );
  }
}

export default Ap;
