import React from 'react';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import Logger from 'utils/logger';

@inject('searchFilter')
@observer
class CompanyFilter extends React.Component {
  handleSelectChange = async e => {
    const { searchFilter } = this.props;
    const company = e;
    searchFilter.set('selectCompany', {
      label: company.label,
      value: company.value
    });
    await searchFilter.getDepartmentList();
    await searchFilter.getUserList();
    await searchFilter.set('page', 1);
    await this.props.refreshData();
  };

  render() {
    const { searchFilter } = this.props;
    return (
      <FormGroup>
        <Select
          {...(searchFilter.companyList.length > 1 ? '' : { isDisabled: true })}
          className="react-select text-left"
          classNamePrefix="react-select"
          name="selectCompany"
          value={searchFilter.selectCompany}
          onChange={this.handleSelectChange}
          options={searchFilter.companyList}
          placeholder="회사를 선택하세요."
        />
      </FormGroup>
    );
  }
}

CompanyFilter.defaultProps = {
  refreshData: async () => {
    Logger.log('[CompanyFilter] - refresh function is undefined');
  }
};

export default CompanyFilter;
