import { observable, action } from 'mobx';

// 테이블 컴포넌트
export class MobxStore {
  constructor() {
    this.selectedData = [];
  }

  @observable selectedData;

  @action addSelectedData = data => {
    const isExisted = this.selectedData.find(item => item.rowNum === data.rowNum);
    if (!isExisted) {
      this.selectedData.push(data);
    }
  };

  @action deleteSelectedData = data => {
    this.selectedData = this.selectedData.filter(item => item.rowNum !== data.rowNum);
  };

  @action set = (key, value) => {
    this[key] = value;
  };
}
