export const handleCopyClick = (toastAlert, elementId, mousePosition) => {
  const element = document.getElementById(elementId);
  element.select();
  const result = document.execCommand('Copy');
  toastAlert.set('mouseX', mousePosition.mouseX - 200);
  toastAlert.set('mouseY', mousePosition.mouseY -100);
  if (element.value !== '') {
    toastAlert.show('복사되었습니다.', setTimeout(() => {
      toastAlert.close();
    }, 1500));
  } else {
    toastAlert.show('복사할 값이 존재하지 않습니다.', setTimeout(() => {
      toastAlert.close();
    }, 1500));
  }
};