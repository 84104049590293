import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';

import contact from 'assets/images/pages/contact/contact-info.png';

class Contact extends Component {
  render() {
    return (
      <div className="__homeBackground">
        <Container>
          <div className="content">
            <Row>
              <Col md="3" />
              <Col md="6">
                <h4>서비스 문의</h4>
                <p>서비스 이용도중 궁금한 사항은 아래 연락처를 이용해주세요.</p>
                <img src={contact} alt="Contact Us" />
                <p>평일 10:00 ~ 18:00(점심시간 12:30 ~ 13:30) / 주말 및 공휴일은 휴무</p>
              </Col>
              <Col md="3" />
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

export default Contact;
