import { observable, action } from 'mobx';

// 등록 기지국 관리
export class MobxStore {
  constructor() {
    this.data = null;
    this.registerCells = [
      {
        department: '',
        telecom: '',
        lac: '',
        cellId: ''
      }
    ];
    this.editCells = {
      id: '',
      department: '',
      telecom: '',
      lac: '',
      cellId: ''
    };
    this.registerModalIsOpen = false;
    this.modifyModalIsOpen = false;
    this.fileUploadModalIsOpen = false;
  }

  @observable data; // 등록 기지국   데이터
  @observable registerCells; // 등록 기지국
  @observable editCells; // 수정 기지국
  @observable registerModalIsOpen; // 등록 모달 토글
  @observable modifyModalIsOpen; // 수정 모달 토글
  @observable fileUploadModalIsOpen; // 파일 업로드 모달 토글

  // 등록 데이터 변경
  @action setRegisterCells = (index, key, value) => {
    this.registerCells[index][key] = value;
  };

  // 등록 데이터 추가
  @action addRegisterCells = () => {
    this.registerCells.push({
      id: '',
      telecom: '',
      lac: '',
      cellId: ''
    });
  };

  // 등록 데이터 삭제
  @action deleteRegisterCells = index => {
    this.registerCells.splice(index, 1);
  };

  // 등록 데이터셋 초기화
  @action clearRegisterCells = async () => {
    this.registerCells = [
      {
        department: '',
        telecom: '',
        lac: '',
        cellId: ''
      }
    ];
  };

  // 수정 데이터 변경
  @action setEditCells = (objName, key, value, newObj) => {
    if (newObj !== undefined && newObj !== null) {
      this[objName] = newObj;
    } else {
      this[objName] = {
        ...this[objName],
        [key]: value
      };
    }
  };

  @action set = (key, value) => {
    this[key] = value;
  };
}
