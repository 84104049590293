import React from 'react';
import Select from 'react-select';
import Loader from 'views/components/loader/Loader';
import buildUrl from 'build-url';
import Pagination from 'views/components/pagination/CustomPagination';
import { getUserType } from 'utils/tokenHelper';
import { Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { requestDelete, requestGet } from 'utils/fetchHelper';

import ConfirmAlert from 'views/components/alert/confirmAlert/ConfirmAlert';
import MiniAlert from 'views/components/alert/miniAlert/MiniAlert';
import ToastAlert from 'views/components/alert/toastAlert/ToastAlert';

import { USER_ADMIN_TABLE_HEADER, USER_CUSTOMER_TABLE_HEADER } from 'commons/conts/tableHeader';
import Table from 'views/components/table/checkbox/table';

import CompanyFilter from 'views/components/searchFilter/CompanyFilter';

import { isValidResponse } from 'utils/responseValidChecker';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import TotalCount from 'views/components/common/totalCount/TotalCount';
import { phoneFomatter } from 'utils/fomatter';
import RegisterUserModal from 'views/pages/user/Register';
import ModifyUserModal from 'views/pages/user/Modify';
import UserTypeFilter from 'views/components/searchFilter/UserTypeFilter';

@inject('searchFilter', 'user', 'confirmAlertModal', 'miniAlertModal', 'toastAlert', 'loader', 'table')
@observer
class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.props.searchFilter.companyList.length === 0 && (await this.props.searchFilter.getCompanyList());
    this.props.searchFilter.setUserTypeList('select');
    await this.fetchMyIp();
    await this.fetchRefresh();
  }

  componentWillUnmount() {
    const { searchFilter, table, user } = this.props;
    searchFilter.clear();
    table.set('selectedData', []);
    user.set('data', null);
    user.set('whatIsMyIp', '');
  }

  // 내 IP 조회 API 호출
  fetchMyIp = async () => {
    const { user } = this.props;

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/health/whatismyip'
    });

    const responseData = await requestGet(url);
    const splitString = responseData.data.split(',');
    const regex = /(\d{1,3}\.){3}\d{1,3}/;
    const publicIp = splitString[1].match(regex);
    user.set('whatIsMyIp', publicIp ? publicIp[0] : '');
  };

  // 관리자 조회 API 호출
  fetchRefresh = async () => {
    const { searchFilter, miniAlertModal, loader, user } = this.props;
    loader.show(true);

    let queryParams = {};
    queryParams.companyId = searchFilter.selectCompany.value;
    if (searchFilter.selectUserType.value !== '') {
      queryParams.userType = searchFilter.selectUserType.value;
    }
    queryParams.page = searchFilter.page !== '' ? searchFilter.page : 1;
    queryParams.size = searchFilter.pageSize.value !== '' ? searchFilter.pageSize.value : 10;

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/v1/admin/api/users',
      queryParams: queryParams
    });

    const responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    const editedResponseData = await this.addCustomColumn(responseData);
    user.set('data', editedResponseData.data);
  };

  addCustomColumn = async response => {
    response.data.content = response.data.content.map(item => {
      let userType;
      switch (item.userType) {
        case 'SARW':
          userType = '엘핀 관리자';
          break;
        case 'SARO':
          userType = '엘핀 운영자';
          break;
        case 'CDRW':
          userType = '운영자(수정 가능)';
          break;
        case 'CDRO':
          userType = '운영자(읽기 전용)';
          break;
        case 'CDSU':
          userType = '관리자';
          break;
      }
      return {
        ...item,
        phone: phoneFomatter(item.phone),
        userType: userType,
        ipAddress: item.ipAddress.join().replaceAll(',', ', ')
      };
    });
    return response;
  };

  // 등록 버튼 클릭 이벤트 처리
  handleRegisterButtonClick = async () => {
    const { user } = this.props;
    await user.clearRegisterUser();
    this.props.searchFilter.setUserTypeList();
    user.set('registerModalIsOpen', !user.registerModalIsOpen);
  };

  // 삭제 버튼 클릭 처리
  handleDeleteClick = async () => {
    const { table, miniAlertModal, confirmAlertModal } = this.props;
    if (toJS(table.selectedData).length === 0) {
      miniAlertModal.show('삭제할 디바이스를 선택해주세요.');
    } else {
      confirmAlertModal.show(
        '선택한 데이터를 삭제합니다.\n',
        '삭제된 데이터는 복원할 수 없습니다.\n그래도 삭제 하시겠습니까?',
        '',
        this.fetchDeleteUser
      );
    }
  };

  handleEditRowClick = async data => {
    const { user, searchFilter } = this.props;

    await user.setEditUser('editUser', null, null, {
      id: data.id,
      loginId: data.loginId,
      password: '',
      name: data.name,
      email: data.email,
      phone: data.phone,
      ipAddress: data.ipAddress,
      lastLoggedAt: data.lastLoggedAt
    });
    user.set('modifyModalIsOpen', !user.modifyModalIsOpen);
    searchFilter.setUserTypeList();
    searchFilter.set('selectUserType', searchFilter.userTypeList.filter(value => value.label === data.userType)[0]);
  };

  // 관리자 삭제 API 호출
  fetchDeleteUser = async () => {
    const { table, loader, miniAlertModal, searchFilter } = this.props;

    loader.show(true);

    table.selectedData.map(async data => {
      const url = process.env.REACT_APP_ENDPOINT + `/v1/admin/api/users/${data.id}`;
      const responseData = await requestDelete(url);
      await isValidResponse(responseData, miniAlertModal, this.props.history);
      if (responseData.code === '0000') {
        miniAlertModal.show('삭제되었습니다.');
        await searchFilter.set('page', 1);
      } else {
        miniAlertModal.show(responseData.message);
      }
      this.fetchRefresh();
    });
    loader.show(false);
  };

  // 페이징 컴포넌트 콜백 함수
  handlePaginationCallback = async page => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', page);
    await this.fetchRefresh();
  };

  // 검색 조건 엔터키 입력 이벤트 처리
  handleEnterKeyPress = async e => {
    const { searchFilter } = this.props;
    if (e.key === 'Enter') {
      await searchFilter.set('page', 1);
      await this.fetchRefresh();
    }
  };

  // 조회 버튼 클릭 이벤트 처리
  handleSearchClick = async e => {
    const { searchFilter } = this.props;
    searchFilter.set('page', 1);
    await this.fetchRefresh();
  };

  // 페이지 당 검색 결과 수 변경 이벤트 함수
  handlePageSizeChanged = async e => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    await searchFilter.setPageSize(e);
    await this.fetchRefresh();
  };

  render() {
    let { user, confirmAlertModal, miniAlertModal, loader, searchFilter, toastAlert } = this.props;
    const userType = getUserType();
    return (
      <>
        {loader.showLoader && <Loader></Loader>}
        <div className="content">
          <h4>유저 관리</h4>
          <h6>유저의 정보 및 등록, 수정, 삭제하실 수 있습니다.</h6>

          <Card className="card-stats">
            <CardBody className="__padding">
              <Form className="form-horizontal">
                <Row md="12">
                  <Col md={{ size: 10, offset: 1 }} className="text-center">
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        회사명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <CompanyFilter refreshData={this.fetchRefresh} />
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        권한
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <UserTypeFilter refreshData={this.fetchRefresh} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="12">
                        <Button className="btn-round" color="primary" onClick={this.handleSearchClick}>
                          조회
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="form-horizontal __marginBottom5">
                <Col md="12" className="text-right">
                  <div className="__inline-div">
                    <Select
                      className="react-select __pageSelect"
                      classNamePrefix="react-select"
                      name="pageSize"
                      placeholder=""
                      value={searchFilter.pageSize}
                      options={searchFilter.pageSizeList}
                      onChange={this.handlePageSizeChanged}
                    />
                    <Label className="label-page">페이지 사이즈</Label>
                    <TotalCount totalElements={user.data && user.data.totalElements} />
                  </div>
                </Col>
              </Row>
              <Row className="form-horizontal">
                <Col md="12">
                  <Table
                    header={getUserType().slice(0, 2) === 'SA' ? USER_ADMIN_TABLE_HEADER : USER_CUSTOMER_TABLE_HEADER}
                    data={user.data}
                    handleRowClick={this.handleEditRowClick}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={{ offset: 3, size: 6 }}>
                  {user.data && (
                    <Pagination
                      currentPage={searchFilter.page}
                      totalPages={user.data.totalPages}
                      pageSize={user.data.pageable.pageSize}
                      callback={this.handlePaginationCallback}
                    />
                  )}
                </Col>
              </Row>
              <Col lg="12" xl="12" className="text-right">
                <div className="__inline-div __button-margin">
                  <Button className="btn-round __marginTop" onClick={this.handleRegisterButtonClick} color="primary">
                    등록
                  </Button>
                </div>
                <div className="__inline-div __button-margin">
                  <Button
                    className="btn-round __marginTop __button-margin"
                    onClick={this.handleDeleteClick}
                    color="primary"
                  >
                    삭제
                  </Button>
                </div>
              </Col>
              <Row>
                <Col lg="6" xl="6" className="text-left" style={{ fontSize: '13px', marginTop: '5px' }}>
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {`- IP 주소는 CIDR 형태로 보여집니다.
                      - IP 주소를 등록하지 않으면 어느 곳에서도 관리자 사용이 가능합니다.
                      - 등록 또는 수정시 여러개 IP를 허용하려면 ',' 구분지어 등록되게 됩니다.
                      - 대역대로 허용을 하시려면 CIDR로 입력하시면 됩니다.
                    `}
                  </span>
                </Col>
                <Col lg="6" xl="6" className="text-left" style={{ fontSize: '13px', marginTop: '5px' }}>
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {`[IP 주소 입력 예시]
                      1) 10.120.121.110 IP 하나를 허용하려면 아래 중 하나로 입력하시면 됩니다.
                      * 10.120.121.110
                      * 10.120.121.110/32
                      2) 10.120.121.121과 10.120.120.160, 127.0.0.1 IP 세개를 허용하려면 아래 중 하나로 입력하시면 됩니다.
                      * 10.120.121.110, 10.120.120.160, 127.0.0.1
                      * 10.120.121.110/32, 10.120.120.160/32, 127.0.0.1/32
                      3) 10.120.120.0 대역대와 10.120.121.0 대역대를 허용하려면 아래와 같이 CIDR로 입력하시면 됩니다.
                      * 10.120.120.0/23
                    `}
                  </span>
                </Col>
              </Row>
            </CardFooter>
          </Card>

          {/* 등록 모달 */}
          {user.registerModalIsOpen && <RegisterUserModal {...this.props} fetchRefresh={this.fetchRefresh} />}

          {/* 수정 모달 */}
          {user.modifyModalIsOpen && <ModifyUserModal {...this.props} fetchRefresh={this.fetchRefresh} />}

          {/* 알림 팝업 */}
          {miniAlertModal.isOpen && <MiniAlert />}

          {/* 사용자 확인 알림 모달 */}
          {confirmAlertModal.isOpen && <ConfirmAlert />}

          {/* 토스트 메시지 팝업 */}
          {toastAlert.isOpen && <ToastAlert />}
        </div>
      </>
    );
  }
}

export default User;
