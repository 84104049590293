import React, { Fragment } from 'react';
import Select from 'react-select';
import { Button, Modal, Row, Col, Table, FormGroup, Input } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

import { requestPost } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';

import { autoPhoneFormatter } from 'utils/fomatter';

// 등록 디바이스 등록 모달
@inject('auth', 'searchFilter', 'miniAlertModal', 'loader')
@observer
class RegisterAuthDevice extends React.Component {
  toggleModal = () => {
    const { auth } = this.props;
    auth.set('registerModalIsOpen', !auth.registerModalIsOpen);
  };

  // 등록 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    const { auth, miniAlertModal } = this.props;
    // 등록할 디바이스 개수 체크
    if(auth.registerDevices.length < 1) {
      miniAlertModal.show('등록할 디바이스가 없습니다.');
      return false;
    }
    // 빈 데이터가 있는지 체크
    const result = toJS(auth.registerDevices).map((data) => {
      if (data.department === '' || data.telecom === '' || data.phone === '') {
        miniAlertModal.show('모든 값을 입력해주세요.');
        return false;
      }
      if (data.phone.length <= 10) {
        miniAlertModal.show('전화번호를 확인해주세요.');
        return false;
      }
      return true;
    });

    if (!result.includes(false)) {
      this.fetchRegisterAuthDevices();
    }
  };

  // 등록 API 호출
  fetchRegisterAuthDevices = async () => {
    const { miniAlertModal, loader, searchFilter, auth, fetchRefresh } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/devices';
    const body = {
      devices: toJS(auth.registerDevices).map(data => {
        return {
          companyId: searchFilter.selectCompany.value,
          departmentId: data.department.value,
          type: 'TABLET',
          telecom: data.telecom.value,
          phone: data.phone,
          useYn: 'Y',
          adid: data.adid
        };
      })
    };
    const responseData = await requestPost(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      auth.clearRegisterDevices();

      await searchFilter.set('page', 1);
      fetchRefresh();
      miniAlertModal.show('등록되었습니다.');
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  renderTableRow = () => {
    const { auth, searchFilter } = this.props;
    const devices = toJS(auth.registerDevices).map((row, key) => {
      return (
        <Fragment key={key}>
          <tr>
            <td>
              <FormGroup>
                <Select
                  className="react-select __tableInputSelect __tableInputSelectColor"
                  classNamePrefix="react-select"
                  name="registerDepartment"
                  value={row.department}
                  onChange={e => {
                    auth.setRegisterDevices(key, 'department', e);
                  }}
                  placeholder="지점을 선택하세요."
                  options={searchFilter.departmentList.length > 1 ? searchFilter.departmentList.slice(1) : []}
                />
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <Select
                  className="react-select __tableInputSelect __tableInputSelectColor"
                  classNamePrefix="react-select"
                  name="registerTelecom"
                  value={row.telecom}
                  onChange={e => {
                    auth.setRegisterDevices(key, 'telecom', e);
                  }}
                  placeholder="통신사"
                  options={[
                    { label: 'SKT', value: 'SKT' },
                    { label: 'KT', value: 'KT' },
                    { label: 'LGT', value: 'LGT' }
                  ]}
                />
              </FormGroup>
            </td>
            <td className="text-center">
              <div className="__inline-div __tableInputSelect">
                <FormGroup>
                  <Input
                    className="__textAlignCenter __inline-input"
                    type="text"
                    name="registerPhone"
                    value={autoPhoneFormatter(row.phone)}
                    onChange={e => {
                      auth.setRegisterDevices(key, 'phone', e.target.value.replace(/[^0-9]/g, ''));
                    }}
                    maxLength={13}
                  />
                </FormGroup>
              </div>
            </td>
            <td className="text-center">
              <Button
                className="btn-round btn-icon"
                color="google"
                onClick={() => {
                  auth.deleteRegisterDevices(key);
                }}>
                <i className="nc-icon nc-simple-delete" />
              </Button>
            </td>
          </tr>
        </Fragment>
      );
    });
    return devices;
  };

  render() {
    const { auth, miniAlertModal } = this.props;
    return (
      <Modal className="modal-lg" backdrop={'static'} isOpen={auth.registerModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove"/>
          </button>
          <h4 className="title title-up">디바이스 등록</h4>
        </div>
        <div className="modal-body __paddingModalBody">
          <Row>
            <Col md="12">
              <Table bordered>
                <thead>
                  <tr className="text-center">
                    <th width="25%">지점 *</th>
                    <th width="15%">통신사 *</th>
                    <th width="30%">전화번호 *</th>
                    <th width="10%"/>
                  </tr>
                </thead>
                <tbody>{this.renderTableRow()}</tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="text-center">
              <Button
                className="btn-round btn-icon"
                color="success"
                onClick={async () => {
                  if (toJS(auth.registerDevices).length < 10) {
                    auth.addRegisterDevices();
                  } else {
                    miniAlertModal.show('더 이상 추가할 수 없습니다.');
                  }
                }}>
                <i className="nc-icon nc-simple-add" />
              </Button>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}>
                저장
            </Button>
          </div>
          <div className="divider"/>
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
                닫기
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

RegisterAuthDevice.defaultProps = {
  fetchRefresh: async () => {
    console.log('[RegisterAuthDevice] - refresh function is undefined');
  }
};

export default RegisterAuthDevice;
