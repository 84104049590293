import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { observer, inject } from 'mobx-react';

// 단순 알림 모달 컴포넌트
@inject('toastAlert')
@observer

class ToastAlert extends React.Component {
  toastAlert = null;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { toastAlert } = this.props;
    return (
      <Alert
        className="__toastAlert"
        style={{top: toastAlert.mouseY, left: toastAlert.mouseX}}
        color="dark"
      >
        <div
          className="justify-content-center __textAlignCenter"
        >
          <Row>
            <Col md="12">
              {toastAlert.alertMessage.split('\n').map((line, index) => {
                return (
                  <div key={index}>
                    {line}
                    <br />
                  </div>
                );
              })}
            </Col>
          </Row>
        </div>
      </Alert>
    );
  }
}

export default ToastAlert;
