import { observable, action } from 'mobx';

// 인증집계 현황
export class MobxStore {
  constructor() {
    this.data = null;
    this.departmentName = null;
    this.detailAuthSummary = {
      date: '',
      authTotCount: 0,
      authFailCount: 0,
      serverErrorCount: 0,
      failRate: 0.00
    };
    this.detailModalIsOpen = false;
  }

  @observable data; // 인증현황 데이터
  @observable departmentName; // 지점명
  @observable detailAuthSummary; // 인증 상세 정보
  @observable detailModalIsOpen; // 상세 현황 모달 토글

  // 인증 상세 정보 수정
  @action setDetailAuthSummary= (objName, key, value, newObj) => {
    if (newObj !== undefined && newObj !== null) {
      this[objName] = newObj;
    } else {
      this[objName] = {
        ...this[objName],
        [key]: value
      };
    }
  };

  // 인증 상세 정보 초기화
  @action clearDetailAuthSummary = () => {
    this.detailAuthSummary = {
      date: '',
      authTotCount: 0,
      authFailCount: 0,
      serverErrorCount: 0,
      failRate: 0.00
    };
  };

  @action set = (key, value) => {
    this[key] = value;
  };
}
