import React from 'react';
import { Button, Modal, Row, Col, Label, FormGroup, Input } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

import { requestPost } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';

// 지점 등록 모달
@inject('department', 'searchFilter', 'miniAlertModal', 'loader')
@observer
class RegisterDepartmentModal extends React.Component {
  toggleModal = () => {
    const { department } = this.props;
    department.set('registerModalIsOpen', !department.registerModalIsOpen);
  };

  // 우편번호, 주소 찾기
  searchAddr = targetObjectName => {
    new window.daum.Postcode({
      oncomplete: data => {
        kakao.maps.load(() => {
          const geocoder = new window.daum.maps.services.Geocoder();
          geocoder.addressSearch(data.roadAddress, (result, status) => {
            if (status === window.daum.maps.services.Status.OK) {
              this.props.department.setDepartment(targetObjectName, null, null, {
                ...this.props.department[targetObjectName],
                zip: data.zonecode,
                address: data.jibunAddress,
                roadAddress: data.roadAddress,
                lat: result[0].y,
                lon: result[0].x
              });
            }
          });
        });
      }
    }).open();
  };

  // 지점 등록 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    const { department, miniAlertModal } = this.props;
    const registerDepartment = toJS(department.registerDepartment);
    console.log(registerDepartment.name);
    console.log(registerDepartment.wifi);
    console.log(registerDepartment.lat);
    console.log(registerDepartment.lon);
    console.log(registerDepartment.roadAddress);
    console.log(registerDepartment.roadAddressDetail);
    console.log(registerDepartment.zip);
    if (
      registerDepartment.name === '' ||
      registerDepartment.wifi === '' ||
      registerDepartment.lat === '' ||
      registerDepartment.lon === '' ||
      registerDepartment.roadAddress === '' ||
      registerDepartment.roadAddressDetail === '' ||
      registerDepartment.zip === ''
    ) {
      miniAlertModal.show('모든 값을 입력해주세요.');
      return false;
    }
    this.fetchRegisterDepartment();
  };

  // 지점 등록 API 호출
  fetchRegisterDepartment = async () => {
    const { miniAlertModal, loader, searchFilter, department, fetchRefresh } = this.props;
    loader.show(true);

    const url =
      process.env.REACT_APP_ENDPOINT +
      '/v1/admin/api/companies/' +
      this.props.department.registerDepartment.value +
      '/departments';
    const body = {
      department: toJS(this.props.department.registerDepartment)
    };
    const responseData = await requestPost(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      department.setDepartment('registerDepartment', null, null, {
        companyId: '',
        name: '',
        code: '',
        wifi: '',
        lat: 0,
        lon: 0,
        address: '',
        addressDetail: '',
        roadAddress: '',
        roadAddressDetail: '',
        zip: '',
        superPassYn: 'N',
      });

      await searchFilter.set('page', 1);
      fetchRefresh();
      miniAlertModal.show('등록되었습니다.');
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  render() {
    const { department, searchFilter, miniAlertModal } = this.props;
    return (
      <Modal className="modal-lg" backdrop={'static'} isOpen={department.registerModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">지점 등록</h4>
        </div>
        <div className="modal-body __modalScrollbar">
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">회사명 *</Label>
                <FormGroup className="col-md-10">
                  <Input
                    readOnly
                    className="__textAlignCenter"
                    type="text"
                    name="companyName"
                    value={searchFilter.selectCompany.label}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="row">
                <Label className="col-md-4 col-form-label font-weight-bold text-center">지점명 *</Label>
                <FormGroup className="col-md-8">
                  <Input
                    className="__textAlignCenter"
                    type="text"
                    name="name"
                    onChange={e => {
                      department.setDepartment('registerDepartment', 'name', e.target.value);
                    }}
                    value={department.registerDepartment.name}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col md="6">
              <div className="row">
                <Label className="col-md-4 col-form-label font-weight-bold text-center">부점코드</Label>
                <FormGroup className="col-md-8">
                  <Input
                    className="__textAlignCenter"
                    type="text"
                    name="name"
                    onChange={e => {
                      department.setDepartment('registerDepartment', 'code', e.target.value);
                    }}
                    value={department.registerDepartment.code}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="row">
                <Label className="col-md-4 col-form-label font-weight-bold text-center">최소 AP 수 *</Label>
                <FormGroup className="col-md-8">
                  <Input
                    className="__textAlignCenter"
                    type="text"
                    name="wifi"
                    onChange={e => {
                      department.setDepartment('registerDepartment', 'wifi', e.target.value.replace(/[^0-9]/g, ''));
                    }}
                    value={department.registerDepartment.wifi}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col md="6" style={{display: 'flex'}}>
              <Label className="col-md-4 col-form-label font-weight-bold text-center">
                <p style={{color: '#ff2222', display: 'inline'}}>슈퍼패스</p> ⚠️
              </Label>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={e => {
                      if (e.target.checked) {
                        miniAlertModal.show('해당 지점을 항상\n지점 내로 판단합니다.');
                        department.setDepartment('registerDepartment', 'superPassYn', 'Y');
                      } else {
                        department.setDepartment('registerDepartment', 'superPassYn', 'N');
                      }
                      this.superPassInput = !this.superPassInput;
                    }}
                    value={department.registerDepartment.superPassYn}
                  />
                  <span className="form-check-sign reverse" />
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">우편번호 *</Label>
                {process.env.REACT_APP_FILE_SYSTEM === 'S3' ? (
                  <>
                    <FormGroup className="col-md-7">
                      <Input
                        className="__textAlignCenter"
                        type="text"
                        readOnly
                        value={department.registerDepartment.zip}
                      />
                    </FormGroup>
                    <div className="col-md-3">
                      <Button
                        className="btn-round __marginTop"
                        onClick={() => {
                          this.searchAddr('registerDepartment');
                        }}
                        color="primary"
                      >
                        우편번호 찾기
                      </Button>
                    </div>
                  </>
                ) : (
                  <FormGroup className="col-md-10">
                    <Input
                      className="__textAlignCenter"
                      type="text"
                      onChange={e => {
                        department.setDepartment('registerDepartment', 'zip', e.target.value.replace(/[^0-9]/g, ''));
                      }}
                      value={department.registerDepartment.zip}
                    />
                  </FormGroup>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">주소 *</Label>
                {process.env.REACT_APP_FILE_SYSTEM === 'S3' ? (
                  <FormGroup className="col-md-10">
                    <Input
                      className="__textAlignCenter"
                      type="text"
                      readOnly
                      value={department.registerDepartment.roadAddress}
                    />
                  </FormGroup>
                ) : (
                  <FormGroup className="col-md-10">
                    <Input
                      className="__textAlignCenter"
                      type="text"
                      value={department.registerDepartment.roadAddress}
                      onChange={e => {
                        department.setDepartment('registerDepartment', 'roadAddress', e.target.value);
                      }}
                    />
                  </FormGroup>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">상세주소 *</Label>
                <FormGroup className="col-md-10">
                  <Input
                    className="__textAlignCenter"
                    type="text"
                    name="roadAddressDetail"
                    onChange={e => {
                      department.setDepartment('registerDepartment', 'roadAddressDetail', e.target.value);
                    }}
                    value={department.registerDepartment.roadAddressDetail}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}
            >
              저장
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
              닫기
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

RegisterDepartmentModal.defaultProps = {
  fetchRefresh: async () => {
    console.log('[RegisterDepartmentModal] - refresh function is undefined');
  }
};

export default RegisterDepartmentModal;
