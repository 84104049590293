import KJUR from 'jsrsasign';
import { executeFetch, requestGet } from 'utils/fetchHelper';
import Logger from 'utils/logger';
import { getLocalIp } from 'utils/ipHelper';

// jwt payload의 특정 필드 값을 반환
export function getJwtBodyValue(token, field) {
  try {
    const jwtData = KJUR.jws.JWS.parse(token, '', {
      alg: ['HS512']
    });
    return jwtData.payloadObj[field];
  } catch (e) {
    Logger.log('[tokenHelper - getJwtBodyValue] Error - invalid token');
    return null;
  }
}

// local Storage에서 토큰을 얻어 유저 타입을 반환
export function getUserType() {
  try {
    const token = localStorage.getItem('token');
    const jwtData = KJUR.jws.JWS.parse(token, '', {
      alg: ['HS512']
    });
    return jwtData.payloadObj.auth;
  } catch (e) {
    Logger.log('[tokenHelper - getUserType] Error - invalid token');
    return null;
  }
}

// 기업 리스트 반환
export async function getCompanyList() {
  try {
    let companyList = [];
    const token = localStorage.getItem('token');
    const jwtData = KJUR.jws.JWS.parse(token, '', {
      alg: ['HS512']
    });
    if (jwtData.payloadObj['auth'].slice(0, 2) === 'SA') {
      let url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/companies';
      let responseData = await requestGet(url);
      if (responseData.code === '0000') {
        for (const company of responseData.data) {
          companyList.push({
            label: company.name,
            value: company.id
          });
        }
      }
    } else {
      companyList.push({
        label: jwtData.payloadObj['companyName'],
        value: jwtData.payloadObj['companyId']
      });
    }
    return companyList;
  } catch (e) {
    Logger.log('[tokenHelper - getCompanyList] Error - invalid token');
    return [];
  }
}

// 지점 리스트 반환
export async function getDepartmentList(endPoint, companyId) {
  try {
    let departmentList = [];
    let url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/companies/' + companyId + '/departments';
    let responseData = await requestGet(url);
    if (responseData.code === '0000') {
      for (const department of responseData.data) {
        departmentList.push({
          label: getUserType().slice(0, 2) === 'SA' ? department.name + ' (' + department.id + ')' : department.name,
          value: department.id
        });
      }
    }
    return departmentList;
  } catch (e) {
    Logger.log('[tokenHelper - getDepartmentList] Error - invalid token');
    return [];
  }
}

// result code 리스트 반환
export async function getResultCodeList() {
  try {
    let resultCodeList = [];
    let url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/statistics/auth/result_code';
    let responseData = await requestGet(url);
    if (responseData.code === '0000') {
      for (const resultCode of responseData.data) {
        resultCodeList.push({
          label: `${resultCode.resultCode} - ${resultCode.resultMessage}`,
          value: resultCode.resultCode
        });
      }
    }
    return resultCodeList;
  } catch (e) {
    Logger.log('[tokenHelper - getResultCodeList] Error - invalid token');
    return [];
  }
}

// 토큰 새로고침
export async function refreshToken() {
  const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/users/jwt/refresh';
  const option = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Auth-Local-Address': getLocalIp()
    }
  };
  return executeFetch(url, option)
    .then(res => {
      localStorage.setItem('token', res.data.token);
      return res;
    })
    .catch(() => {});
}

// 코드 api - jobName 리스트 및 jobStatus 반환
export async function getJobNameList(groupId) {
  try {
    let jobNameList = [];
    let url = process.env.REACT_APP_ENDPOINT + `/v1/admin/api/codes?groupId=${groupId}`;
    let responseData = await requestGet(url);
    if (responseData.code === '0000') {
      for (const resultCode of responseData.data) {
        jobNameList.push({
          label: resultCode.codeName,
          value: resultCode.codeId
        });
      }
    }
    return jobNameList;
  } catch (e) {
    Logger.log('[tokenHelper - getResultCodeList] Error - invalid token');
    return [];
  }
}

// 유저 리스트(조건: 회사명, 총 유저 수) 리스트 반환
export async function getUserList(endPoint, companyId) {
  try {
    let userList = [];
    let url = endPoint + '/v1/admin/api/users?companyId=' + companyId + '&page=1&size=50';
    let responseData = await requestGet(url);
    if (responseData.code === '0000') {
      for (const resultCode of responseData.data.content) {
        userList.push({
          label: `${resultCode.loginId}(${resultCode.name})`,
          value: resultCode.loginId
        });
      }
    }
    return userList;
  } catch (e) {
    Logger.log('[tokenHelper - getResultCodeList] Error - invalid token');
    return [];
  }
}

// HostName 리스트 반환
export async function getHostNameList(endPoint, companyId) {
  try {
    let hostNameList = [];
    let url = endPoint + '/v1/admin/api/auth/hosts?companyId=' + companyId;
    let responseData = await  requestGet(url);
    let responseWithoutNullData = responseData.data.filter((v) => v.hostName !== null);
    if (responseData.code === '0000') {
      for (const resultCode of responseWithoutNullData) {
        hostNameList.push({
          label: resultCode.hostName,
          value: resultCode.hostName
        });
      }
    }
    return hostNameList;
  } catch (e) {
    Logger.log('[tokenHelper - getResultCodeList] Error - invalid token');
    return [];
  }
}