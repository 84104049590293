import React from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, Row, Table } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import Logger from 'utils/logger';
import { requestPost } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';
import CompanyFilter from 'views/components/searchFilter/CompanyFilter';
import UserTypeFilter from 'views/components/searchFilter/UserTypeFilter';
import { handleCopyClick } from 'views/components/alert/toastAlert/copy';


// 관리자 등록 모달
@inject('user', 'searchFilter', 'miniAlertModal', 'loader', 'toastAlert')
@observer
class RegisterUserModal extends React.Component {
  toggleModal = () => {
    const { user, searchFilter } = this.props;
    user.set('registerModalIsOpen', !user.registerModalIsOpen);
    searchFilter.setUserTypeList('select');
  };

  // 등록 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    const { user, miniAlertModal } = this.props;
    // 빈 항목이 있는지 체크
    if (
      user.registerUser.loginId === '' ||
      user.registerUser.password === '' ||
      user.registerUser.name === ''
    ) {
      miniAlertModal.show('빈 항목이 있습니다.');
      return false;
    }
    this.fetchRegisterUser();
  };

  // 관리자 등록 API 처리
  fetchRegisterUser = async () => {
    const { user, miniAlertModal, loader, searchFilter, fetchRefresh } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/users';

    const body = {
      ...user.registerUser,
      companyId: searchFilter.selectCompany.value,
      userType: searchFilter.selectUserType.value,
      phone: user.registerUser.phone.replaceAll('-', ''),
      ipAddress: user.registerUser.ipAddress.length > 0 ? user.registerUser.ipAddress.split(',') : []
    };

    const responseData = await requestPost(url, body);
    loader.show(false);
    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      user.clearRegisterUser();
      await searchFilter.set('page', 1);
      searchFilter.setUserTypeList('select');
      fetchRefresh();
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  render() {
    const { user, fetchRefresh, toastAlert } = this.props;
    return (
      <Modal className="modal-lg" backdrop={'static'} isOpen={user.registerModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">유저 등록</h4>
        </div>
        <div className="modal-body __paddingModalBody __modalScrollbar">
          <Row>
            <Col md="12" className="text-right">
              <div className="__inline-div">
                <Label
                  className="label-page"
                  style={{ color: 'black', cursor: 'pointer' }}
                  onClick={(e) => {
                    let mousePosition = { mouseX: e.clientX, mouseY: e.clientY };
                    handleCopyClick(toastAlert, `${user.whatIsMyIp}`, mousePosition);
                  }}
                >
                  내 IP: {user.whatIsMyIp}
                </Label>
                <input
                  id={`${user.whatIsMyIp}`}
                  value={user.whatIsMyIp}
                  readOnly
                  style={{position: 'absolute', top: -999, left: -999}}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Table bordered>
                <thead>
                  <tr className="text-center">
                    <th width="25%">회사명 *</th>
                    <th width="25%">아이디 *</th>
                    <th width="25%">임시 비밀번호 *</th>
                    <th width="25%">이름 *</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <CompanyFilter refreshData={fetchRefresh} />
                    </td>
                    <td>
                      <FormGroup>
                        <Input
                          className="__textAlignCenter __inline-input"
                          type="text"
                          name="loginId"
                          value={user.loginId}
                          onChange={e => {
                            user.setRegisterUser('loginId', e.target.value);
                          }}
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Input
                          className="__textAlignCenter __inline-input"
                          type="password"
                          name="password"
                          value={user.password}
                          onChange={e => {
                            user.setRegisterUser('password', e.target.value);
                          }}
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Input
                          className="__textAlignCenter __inline-input"
                          type="text"
                          name="name"
                          value={user.name}
                          onChange={e => {
                            user.setRegisterUser('name', e.target.value);
                          }}
                        />
                      </FormGroup>
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr className="text-center">
                    <th width="25%">이메일</th>
                    <th width="25%">전화번호</th>
                    <th colSpan={2} width="50%">
                      권한 *
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <FormGroup>
                        <Input
                          className="__textAlignCenter __inline-input"
                          type="text"
                          name="email"
                          value={user.email}
                          onChange={e => {
                            user.setRegisterUser('email', e.target.value);
                          }}
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Input
                          className="__textAlignCenter __inline-input"
                          type="text"
                          name="phone"
                          value={user.phone}
                          onChange={e => {
                            user.setRegisterUser('phone', e.target.value);
                          }}
                        />
                      </FormGroup>
                    </td>
                    <td colSpan={2}>
                      <UserTypeFilter />
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr className="text-center">
                    <th colSpan={4} width="50%">
                      IP 주소
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={4}>
                      <FormGroup>
                        <Input
                          className="__textAlignCenter __inline-input"
                          type="text"
                          name="ipAddress"
                          value={user.ipAddress}
                          onChange={e => {
                            user.setRegisterUser('ipAddress', e.target.value);
                          }}
                        />
                      </FormGroup>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}
            >
              저장
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
              닫기
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

RegisterUserModal.defaultProps = {
  fetchRefresh: async () => {
    Logger.log('[RegisterApModal] - refresh function is undefined');
  }
};

export default RegisterUserModal;
