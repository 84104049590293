import Status from 'views/pages/status/Status.jsx';
import AuthSummary from 'views/pages/authSummary/authSummary.jsx';
import deviceAuthSummary from 'views/pages/deviceAuthSummary/deviceAuthSummary.jsx';
import AuthDevice from 'views/pages/authDevice/AuthDevice.jsx';
import UnAuth from 'views/pages/unAuthDevice/UnAuth.jsx';
import CellTower from 'views/pages/cellTower/CellTower.jsx';
import Department from 'views/pages/department/Department.jsx';
import Ap from 'views/pages/ap/Ap.jsx';
import User from 'views/pages/user/User.jsx';
import JobStatus from 'views/pages/jobStatus/JobStatus';

import departmentIcon from 'assets/images/commons/sidebar/department.png';
import statusIcon from 'assets/images/commons/sidebar/status.png';
import authDeviceIcon from 'assets/images/commons/sidebar/auth-device.png';
import unAuthDeviceIcon from 'assets/images/commons/sidebar/unauth-device.png';
import cellTowerIcon from 'assets/images/commons/sidebar/cell-tower.png';
import apIcon from 'assets/images/commons/sidebar/ap.png';
import permissionIcon from 'assets/images/commons/sidebar/permission.png';
import AccessLog from 'views/pages/accessLog/AccessLog';
import userIcon from 'assets/images/commons/sidebar/user-management.png';
import jobStatusIcon from 'assets/images/commons/sidebar/job-status.png';
import accessLogIcon from 'assets/images/commons/sidebar/access-log.png';

import { getUserType } from 'utils/tokenHelper';


export default function getRoutes() {
  let routes = [];
  const userType = getUserType();
  if (userType === 'SARW' || userType === 'SARO' || userType === 'CDSU') {
    routes = [
      {
        path: '/department',
        name: '지점 관리',
        icon: departmentIcon,
        component: Department,
        layout: '/devices'
      },
      {
        path: '/status/:path',
        name: '인증 현황',
        icon: statusIcon,
        component: Status,
        layout: '/devices'
      },
      {
        path: '/auth',
        name: '등록 디바이스 관리',
        icon: authDeviceIcon,
        component: AuthDevice,
        layout: '/devices'
      },
      {
        path: '/unauth',
        name: '미등록 디바이스 관리',
        icon: unAuthDeviceIcon,
        component: UnAuth,
        layout: '/devices'
      },
      {
        path: '/cell',
        name: '기지국 관리',
        icon: cellTowerIcon,
        component: CellTower,
        layout: '/devices'
      },
      {
        path: '/ap',
        name: '위치인증 Ap 관리',
        icon: apIcon,
        component: Ap,
        layout: '/devices'
      },
      {
        path: '/summary',
        name: '지점별 인증현황 집계',
        icon: statusIcon,
        component: AuthSummary,
        layout: '/devices'
      },
      {
        path: '/deviceSummary',
        name: '지점별 단말인증현황 집계',
        icon: statusIcon,
        component: deviceAuthSummary,
        layout: '/devices'
      },
      {
        path: '/user',
        name: '유저 관리',
        icon: userIcon,
        component: User,
        layout: '/management'
      },
      {
        path: '/jobStatus',
        name: 'Job 현황',
        icon: jobStatusIcon,
        component: JobStatus,
        layout: '/management'
      },
      {
        path: '/accessLog',
        name: '접속 로그',
        icon: accessLogIcon,
        component: AccessLog,
        layout: '/management'
      }
    ];
  } else if (userType === 'CDRW' || userType === 'CDRO') {
    routes = [
      {
        path: '/department',
        name: '지점 관리',
        icon: departmentIcon,
        component: Department,
        layout: '/devices'
      },
      {
        path: '/status/:path',
        name: '인증 현황',
        icon: statusIcon,
        component: Status,
        layout: '/devices'
      },
      {
        path: '/auth',
        name: '등록 디바이스 관리',
        icon: authDeviceIcon,
        component: AuthDevice,
        layout: '/devices'
      },
      {
        path: '/unauth',
        name: '미등록 디바이스 관리',
        icon: unAuthDeviceIcon,
        component: UnAuth,
        layout: '/devices'
      },
      {
        path: '/cell',
        name: '기지국 관리',
        icon: cellTowerIcon,
        component: CellTower,
        layout: '/devices'
      },
      {
        path: '/ap',
        name: '위치인증 Ap 관리',
        icon: apIcon,
        component: Ap,
        layout: '/devices'
      },
      {
        path: '/battery',
        name: '배터리 현황',
        icon: apIcon,
        component: Ap,
        layout: '/devices'
      },
      {
        path: '/authSummary',
        name: '지점별 인증현황 집계',
        icon: statusIcon,
        component: AuthSummary,
        layout: '/devices'
      },
      {
        path: '/deviceSummary',
        name: '지점별 단말인증현황 집계',
        icon: statusIcon,
        component: deviceAuthSummary,
        layout: '/devices'
      }
    ];
  }
  return routes;
}
