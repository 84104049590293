import { observable, action } from 'mobx';

// 알림 모달
export class MobxStore {
  constructor() {
    this.alertMessage = '';
    this.isOpen = false;
    this.callback = null;
    this.mouseX = null;
    this.mouseY = null;
  }

  @observable alertMessage; // 안내 메시지
  @observable isOpen; // 모달 출력 여부
  @observable callback; // 콜백 함수
  @observable mouseX; // 마우스 x 좌표
  @observable mouseY; // 마우스 y 좌표

  // 알림 모달 활성화
  @action show = (alertMessage, callback) => {
    this.alertMessage = alertMessage;
    if (callback !== null && callback !== undefined) {
      this.callback = callback;
    }
    this.isOpen = true;
  };

  @action close = () => {
    this.isOpen = false;
  };

  @action set = (key, value) => {
    this[key] = value;
  };
}
