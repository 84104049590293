import React from 'react';
import Select from 'react-select';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import Loader from 'views/components/loader/Loader';
import buildUrl from 'build-url';
import Pagination from 'views/components/pagination/CustomPagination';

import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { requestGet } from 'utils/fetchHelper';

import MiniAlert from 'views/components/alert/miniAlert/MiniAlert';

import CompanyFilter from 'views/components/searchFilter/CompanyFilter';
import JobNameFilter from 'views/components/searchFilter/JobNameFilter';
import JobStatusFilter from 'views/components/searchFilter/JobStatusFilter';

import { JOB_STATUS_TABLE_HEADER } from 'commons/conts/tableHeader';
import Table from 'views/components/table/normal/table';

import { isValidResponse } from 'utils/responseValidChecker';

import { inject, observer } from 'mobx-react';
import { saveAs } from 'file-saver';
import TotalCount from 'views/components/common/totalCount/TotalCount';

@inject('searchFilter', 'jobStatus', 'miniAlertModal', 'loader', 'table')
@observer
class JobStatus extends React.Component {
  constructor(props) {
    super(props);
    moment.updateLocale(moment.locale(), { invalidDate: '' });
  }

  async componentDidMount() {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    searchFilter.companyList.length === 0 && (await searchFilter.getCompanyList());
    await searchFilter.getJobNameList('select');
    await searchFilter.set('startDate', moment().format('YYYY-MM-DD 00:00:00'));
    await searchFilter.set('endDate', moment().format('YYYY-MM-DD 23:59:59'));
    searchFilter.setJobStatusList('select');

    this.fetchRefresh();
  }

  componentWillUnmount() {
    const { searchFilter, jobStatus } = this.props;
    searchFilter.clear();
    jobStatus.set('data', null);
  }

  // 인증 현황 조회 API 호출
  fetchRefresh = async () => {
    const { searchFilter, miniAlertModal, loader, jobStatus } = this.props;
    loader.show(true);

    let queryParams = {};
    queryParams.companyId = searchFilter.selectCompany.value;
    searchFilter.selectJobCode.value !== '' && (queryParams.jobCode = searchFilter.selectJobCode.value);
    searchFilter.selectJobStatus.value !== '' && (queryParams.status = searchFilter.selectJobStatus.value);
    searchFilter.startDate !== '' &&
    (queryParams.startDate = moment(searchFilter.startDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
    searchFilter.endDate !== '' &&
    (queryParams.endDate = moment(searchFilter.endDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
    queryParams.page = searchFilter.page !== '' ? searchFilter.page : 1;
    queryParams.size = searchFilter.pageSize.value !== '' ? searchFilter.pageSize.value : 10;

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/v1/admin/api/jobs',
      queryParams: queryParams
    });

    let responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    const editedResponseData = await this.addCustomColumn(responseData);

    jobStatus.set('data', editedResponseData.data);
  };

  // 지점명(지점ID) 및 Ap 타입 칼럼 추가
  addCustomColumn = async response => {
    response.data.content = response.data.content.map(item => {
      return {
        ...item,
        customDepartmentInfo: item.departmentName !== null ? item.departmentName + ' (' + item.departmentId + ')' : ''
      };
    });
    return response;
  };

  // 구간 버튼 클릭 이벤트 처리
  handleDateButtonClick = async (e, range) => {
    const { searchFilter } = this.props;
    let from = moment();
    let to = moment();
    switch (range) {
      case '3days':
        from = moment()
          .subtract(3, 'days')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case '7days':
        from = moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case '1months':
        from = moment()
          .subtract(1, 'months')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case '3months':
        from = moment()
          .subtract(3, 'months')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
      default:
        from = moment()
          .subtract(3, 'days')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
    }

    await searchFilter.set('startDate', from);
    await searchFilter.set('endDate', to);
    await searchFilter.set('page', 1);

    this.fetchRefresh();
  };

  // 검색 조건 엔터키 입력 이벤트 처리
  handleEnterKeyPress = async e => {
    const { searchFilter } = this.props;
    if (e.key === 'Enter') {
      await searchFilter.set('page', 1);
      this.fetchRefresh();
    }
  };

  // 페이징 컴포넌트 콜백 함수
  handlePaginationCallback = async page => {
    const { searchFilter } = this.props;
    searchFilter.set('page', page);
    await this.fetchRefresh();
  };

  // 검색 버튼 클릭 이벤트
  handleSearchClick = async () => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    this.fetchRefresh();
  };

  // 페이지 당 검색 결과 수 변경 이벤트 함수
  handlePageSizeChanged = async e => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    await searchFilter.setPageSize(e);
    await this.fetchRefresh();
  };

  render() {
    let { miniAlertModal, loader, jobStatus, searchFilter } = this.props;
    return (
      <>
        {loader.showLoader && <Loader></Loader>}
        <div className="content">
          <h4 className="__marginTop">Job 현황</h4>
          <h6>날짜 별로 Job 현황을 확인할 수 있습니다.</h6>
          <Card className="card-stats">
            <CardBody className="__padding">
              <Form className="form-horizontal">
                <Row md="12">
                  <Col md={{ size: 10, offset: 1 }} className="text-center">
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        회사명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <CompanyFilter refreshData={this.fetchRefresh}></CompanyFilter>
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        Job 이름
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <JobNameFilter refreshData={this.fetchRefresh}></JobNameFilter>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        조회 시작일
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <ReactDatetime
                            inputProps={{
                              className: 'form-control datetime-custom',
                              placeholder: moment(this.props.searchFilter.startDate, 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              value: moment(this.props.searchFilter.startDate, 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              readOnly: true
                            }}
                            dateFormat="YYYYMMDD"
                            timeFormat="HH:mm"
                            onChange={e => {
                              this.props.searchFilter.set('startDate', moment(e).format('YYYY-MM-DD HH:mm:ss'));
                            }}
                            locale="ko"
                            // timeFormat={true}
                            // input={true}
                          />
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        조회 종료일
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <ReactDatetime
                            inputProps={{
                              className: 'form-control datetime-custom',
                              placeholder: moment(this.props.searchFilter.endDate, 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              value: moment(this.props.searchFilter.endDate, 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              readOnly: true
                            }}
                            dateFormat="YYYYMMDD"
                            timeFormat="HH:mm"
                            onChange={e => {
                              this.props.searchFilter.set('endDate', moment(e).format('YYYY-MM-DD HH:mm:ss'));
                            }}
                            locale="ko"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        상태
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <JobStatusFilter refreshData={this.fetchRefresh} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="12">
                        <ButtonGroup data-toggle="buttons">
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '3days');
                            }}
                          >
                            3 일
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '7days');
                            }}
                          >
                            7 일
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '1months');
                            }}
                          >
                            1개월
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '3months');
                            }}
                          >
                            3개월
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="12">
                        <Button className="btn-round __marginTop" color="primary" onClick={this.handleSearchClick}>
                          조회
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="form-horizontal __marginBottom5">
                <Col md="12" className="text-right">
                  <div className="__inline-div">
                    <Select
                      className="react-select __pageSelect"
                      classNamePrefix="react-select"
                      name="pageSize"
                      placeholder=""
                      value={searchFilter.pageSize}
                      options={searchFilter.pageSizeList}
                      onChange={this.handlePageSizeChanged}
                    />
                    <Label className="label-page">페이지 사이즈</Label>
                    <TotalCount totalElements={jobStatus.data && jobStatus.data.totalElements} />
                  </div>
                </Col>
              </Row>
              <Row className="form-horizontal">
                <Col md="12">
                  <Table
                    header={JOB_STATUS_TABLE_HEADER}
                    data={jobStatus.data}
                    handleRowClick={this.handleEditRowClick}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={{ offset: 3, size: 6 }}>
                  {jobStatus.data && (
                    <Pagination
                      currentPage={searchFilter.page}
                      totalPages={jobStatus.data.totalPages}
                      pageSize={jobStatus.data.pageable.pageSize}
                      callback={this.handlePaginationCallback}
                    />
                  )}
                </Col>
              </Row>
            </CardFooter>
          </Card>

          {/* 알림 팝업 */}
          {miniAlertModal.isOpen && <MiniAlert />}
        </div>
      </>
    );
  }
}

export default JobStatus;