import React from 'react';
import { Button, Modal, Row, Col } from 'reactstrap';
import { observer, inject } from 'mobx-react';

// 단순 알림 모달 컴포넌트
@inject('miniAlertModal')
@observer
class MiniAlert extends React.Component {
  miniAlertModal = null;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { miniAlertModal } = this.props;
    return (
      <Modal className="modal-sm" backdrop={'static'} isOpen={miniAlertModal.isOpen}>
        <div className="modal-body justify-content-center __textAlignCenter">
          <Row>
            <Col md="12">
              {miniAlertModal.alertMessage.split('\n').map((line, index) => {
                return (
                  <div key={index}>
                    {line}
                    <br />
                  </div>
                );
              })}
            </Col>
          </Row>
        </div>
        <div className="modal-footer justify-content-center">
          <Button
            className="btn-round"
            data-dismiss="modal"
            color="default"
            outline
            type="button"
            onClick={miniAlertModal.close}>
            확인
          </Button>
        </div>
      </Modal>
    );
  }
}

export default MiniAlert;
