import React, { Fragment } from 'react';
import Select from 'react-select';
import { Button, Modal, Row, Col, FormGroup, Input, Table } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

import { requestPut } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';

import { autoPhoneFormatter } from 'utils/fomatter';

// 등록 디바이스 수정 모달
@inject('auth', 'searchFilter', 'miniAlertModal', 'loader')
@observer
class ModifyAuthDeviceModal extends React.Component {
  toggleModal = () => {
    const { auth } = this.props;
    auth.set('modifyModalIsOpen', !auth.modifyModalIsOpen);
  };

  // 수정 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    const { auth, miniAlertModal } = this.props;
    if (auth.editDevices.department === '' || auth.editDevices.telecom === '' || auth.editDevices.phone === '') {
      miniAlertModal.show('모든 값을 입력해주세요.');
      return false;
    }
    if (auth.editDevices.phone.length <= 10) {
      miniAlertModal.show('전화번호를 확인해주세요.');
      return false;
    }
    this.fetchModifyAuthDevices();
  };

  // 등록 디바이스 수정 API 호출
  fetchModifyAuthDevices = async () => {
    const { miniAlertModal, loader, auth, searchFilter, fetchRefresh } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/devices';
    const body = {
      devices: [
        {
          companyId: searchFilter.selectCompany.value,
          departmentId: auth.editDevices.department.value,
          deviceId: auth.editDevices.deviceId,
          type: 'TABLET',
          telecom: auth.editDevices.telecom.value,
          phone: auth.editDevices.phone,
          useYn: 'Y',
          deletedYn: 'N',
          adid: auth.editDevices.adid
        }
      ]
    };
    const responseData = await requestPut(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      auth.set('editDevices', {
        deviceId: '',
        department: '',
        telecom: '',
        phone: '',
        adid: ''
      });

      await searchFilter.set('page', 1);
      fetchRefresh();
      miniAlertModal.show('수정되었습니다.');
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  renderTableRow = () => {
    let { auth, searchFilter } = this.props;
    return (
      <Fragment>
        <tr>
          <td>
            <FormGroup>
              <Select
                className="react-select __tableInputSelect __tableInputSelectColor"
                classNamePrefix="react-select"
                name="editDepartment"
                value={auth.editDevices.department}
                onChange={data => {
                  auth.setEditDevices('editDevices', 'department', data, null);
                }}
                placeholder="지점을 선택하세요."
                options={searchFilter.departmentList.length > 1 ? searchFilter.departmentList.slice(1) : []}
              />
            </FormGroup>
          </td>
          <td>
            <FormGroup>
              <Select
                className="react-select __tableInputSelect __tableInputSelectColor"
                classNamePrefix="react-select"
                name="editTelecom"
                value={auth.editDevices.telecom}
                onChange={data => {
                  auth.setEditDevices('editDevices', 'telecom', data, null);
                }}
                placeholder="통신사"
                options={[
                  { label: 'SKT', value: 'SKT' },
                  { label: 'KT', value: 'KT' },
                  { label: 'LGT', value: 'LGT' }
                ]}
              />
            </FormGroup>
          </td>
          <td className="text-center">
            <div className="__inline-div __tableInputSelect">
              <FormGroup>
                <Input
                  className="__textAlignCenter __inline-input"
                  type="text"
                  name="editPhone"
                  value={autoPhoneFormatter(auth.editDevices.phone)}
                  onChange={e => {
                    auth.setEditDevices('editDevices', 'phone', e.target.value.replace(/[^0-9]/g, ''), null);
                  }}
                  maxLength={13}
                />
              </FormGroup>
            </div>
          </td>
        </tr>
        <tr>
          <th className="text-center">기기식별자</th>
          <td colSpan={2}>
            <div className="__tableInputSelect">
              <FormGroup>
                <Input
                  className="__textAlignCenter"
                  type="text"
                  name="editAdid"
                  value={auth.editDevices.adid}
                  onChange={e => {
                    auth.setEditDevices('editDevices', 'adid', e.target.value);
                  }}
                  maxLength={50}
                />
              </FormGroup>
            </div>
          </td>
        </tr>
      </Fragment>
    );
  };

  render() {
    const { auth } = this.props;
    return (
      <Modal className="modal-lg" backdrop={'static'} isOpen={auth.modifyModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">디바이스수정</h4>
        </div>
        <div className="modal-body __paddingModalBody">
          <Row>
            <Col md="12">
              <Table bordered>
                <thead>
                  <tr className="text-center">
                    <th width="25%">지점 *</th>
                    <th width="15%">통신사 *</th>
                    <th width="30%">전화번호 *</th>
                  </tr>
                </thead>
                <tbody>{this.renderTableRow()}</tbody>
              </Table>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}>
              수정
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
              닫기
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

ModifyAuthDeviceModal.defaultProps = {
  fetchRefresh: async () => {
    console.log('[ModifyAuthDeviceModal] - refresh function is undefined');
  }
};

export default ModifyAuthDeviceModal;
