import React from 'react';
import { Table as RSTable } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { handleCopyClick } from 'views/components/alert/toastAlert/copy';


// 체크박스가 없는 테이블
@inject('table', 'searchFilter', 'toastAlert')
@observer
class Table extends React.Component {
  // 테이블 헤더 영역
  renderTableHeader = () => {
    const { header } = this.props;
    let tableHeader = [];
    tableHeader = tableHeader.concat(
      header.map((data, key) => {
        return (
          <th key={key + 1} width={data.width}>
            {data.label}
          </th>
        );
      })
    );
    return tableHeader;
  };

  // 테이블 데이터 영역
  // 테이블 셀 클릭 시 복사를 위해 전화번호, 메모, bssid는 따로 처리..
  renderData = (data, rowNum) => {
    const { header, toastAlert } = this.props;
    const headerData = header.map((row, key) => {
      if (row.key === 'rowNum' && data[row.key] === undefined) {
        return <td key={key}>{rowNum + 1}</td>;
      } else {
        if (row.key === 'phone' || row.key === 'referral' || row.key === 'bssid') {
          return (
            <td key={key}>
              <p
                style={{marginBottom: 0, display: 'inline'}}
                onClick={(e) => {
                  e.stopPropagation();
                  let mousePosition = { mouseX: e.clientX, mouseY: e.clientY };
                  handleCopyClick(toastAlert, `copy${rowNum}of${row.key}`, mousePosition);
              }}>
                {data[row.key]}
              </p>
              <input
                id={`copy${rowNum}of${row.key}`}
                value={data[row.key]}
                readOnly
                style={{position: 'absolute', top: -999, left: -999}}
              />
            </td>
          );
        } else if (row.key === 'status') {
          return <td key={key}>{
            (data[row.key] === 'ST' ? (
              '실행 중'
            ): (data[row.key] === 'FS') ? (
              '종료'
            ): '오류')
          }
          </td>;
        } else if (row.key === 'resultMessage') {
          return <td key={key}>{
            data[row.key].length > 9 ? data[row.key].substring(0, 9) + '...' : data[row.key]
          }</td>;
        } else if (row.key === 'resultDesc') {
          return <td key={key}>{
            data[row.key].length > 16 ? data[row.key].substring(0, 16) + '...' : data[row.key]
          }</td>;
        } else {
          return <td key={key}>{data[row.key]}</td>;
        }
      }
    });
    return headerData;
  };

  // 테이블 영역
  renderTableBody = () => {
    const { data, handleRowClick } = this.props;
    let bodyData;

    if (data.content) {
      bodyData = toJS(data.content);
    } else {
      bodyData = toJS(data);
    }

    bodyData = bodyData.map((row, key) => {
      return (
        <tr
          className={handleRowClick ? 'clickable-tr' : 'non-clickable-tr'}
          key={key}
          onClick={() => {
            handleRowClick && handleRowClick(row);
          }}>
          {this.renderData(row, key)}
        </tr>
      );
    });
    return bodyData;
  };

  render() {
    const { header, data } = this.props;
    return (
      <>
        <RSTable bordered striped className="__tableFixed">
          <thead>
            <tr className="text-center">{header && this.renderTableHeader()}</tr>
          </thead>
          <tbody>{data && this.renderTableBody()}</tbody>
        </RSTable>
      </>
    );
  }
}

Table.defaultProps = {
  header: null,
  data: null,
  handleRowClick: null
};

export default Table;
