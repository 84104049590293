import { observable, action } from 'mobx';

// 인증 현황
export class MobxStore {
  constructor() {
    this.data = null;
    this.detailStatus = {
      id: '',
      departmentId: '',
      departmentName: '',
      date: '',
      phone: '',
      resultCode: '',
      resultMessage: '',
      referral: '',
      txid: ''
    };
    this.detailModalIsOpen = false;
    this.detailModalRegisterCell = false;
  }

  @observable data; // 인증현황 데이터
  @observable detailStatus; // 인증 상세 정보
  @observable detailModalIsOpen; // 상세 현황 모달 토글
  @observable detailModalRegisterCell; // 상세 현황 모달 토글에서 기지국 버튼 클릭 시 모달 토글

  // 인증 상세 정보 수정
  @action setDetailStatus = (objName, key, value, newObj) => {
    if (newObj !== undefined && newObj !== null) {
      this[objName] = newObj;
    } else {
      this[objName] = {
        ...this[objName],
        [key]: value
      };
    }
  };

  // 인증 상세 정보 초기화
  @action clearDetailStatus = () => {
    this.detailStatus = {
      id: '',
      departmentId: '',
      departmentName: '',
      date: '',
      phone: '',
      resultCode: '',
      resultMessage: '',
      referral: '',
      txid: ''
    };
  };

  @action set = (key, value) => {
    this[key] = value;
  };
}
