import 'react-app-polyfill/ie9'; // For IE 9-11 support
import React from 'react';
import ReactDOM from 'react-dom';
// import { createBrowserHistory } from "history";
import { Route, Switch, Redirect, HashRouter } from 'react-router-dom';

import LoginLayout from 'src/views/layouts/login/LoginLayout';
import CategoryLayout from 'src/views/layouts/category/CategoryLayout';
import IndexLayout from 'src/views/layouts/index/IndexLayout';
import MainLayout from 'src/views/layouts/main/MainLayout';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss';

import { Provider } from 'mobx-react';

import { MobxStore as ApStore } from 'views/pages/ap/store';
import { MobxStore as AuthStore } from 'views/pages/authDevice/store';
import { MobxStore as DashboardStore } from 'views/pages/dashboard/store';
import { MobxStore as DepartmentStore } from 'views/pages/department/store';
import { MobxStore as StatusStore } from 'views/pages/status/store';
import { MobxStore as AuthSummaryStore } from 'views/pages/authSummary/store';
import { MobxStore as DeviceAuthSummaryStore } from 'views/pages/deviceAuthSummary/store';
import { MobxStore as UnAuthStore } from 'views/pages/unAuthDevice/store';
import { MobxStore as UserInfoStore } from 'views/pages/userInfo/store';
import { MobxStore as UserStore } from 'views/pages/user/store';
import { MobxStore as CellStore } from 'views/pages/cellTower/store';
import { MobxStore as JobStatusStore } from 'views/pages/jobStatus/store';
import { MobxStore as AccessLogStore } from 'views/pages/accessLog/store';

import { MobxStore as LoaderStore } from 'views/components/loader/store';
import { MobxStore as TableStore } from 'views/components/table/store';
import { MobxStore as SearchFilterStore } from 'views/components/searchFilter/store';
import { MobxStore as ConfirmAlertModalStore } from 'views/components/alert/confirmAlert/store';
import { MobxStore as MiniAlertModalStore } from 'views/components/alert/miniAlert/store';
import { MobxStore as ToastAlertStore } from 'views/components/alert/toastAlert/store';

import { getLocalIp } from 'utils/ipHelper';

// const hist = createBrowserHistory();

const mainRoutes = [
  {
    path: '/dashboard'
  },
  {
    path: '/contact'
  },
  {
    path: '/userinfo'
  },
  {
    path: '/newuserinfo'
  }
];

const searchFilter = new SearchFilterStore();
const department = new DepartmentStore();
const confirmAlertModal = new ConfirmAlertModalStore();
const miniAlertModal = new MiniAlertModalStore();
const toastAlert  = new ToastAlertStore();
const loader = new LoaderStore();
const auth = new AuthStore();
const unAuth = new UnAuthStore();
const ap = new ApStore();
const status = new StatusStore();
const authSummary = new AuthSummaryStore();
const deviceAuthSummary = new DeviceAuthSummaryStore();
const table = new TableStore();
const dashboard = new DashboardStore();
const userInfo = new UserInfoStore();
const user = new UserStore();
const cell = new CellStore();
const jobStatus = new JobStatusStore();
const accessLog = new AccessLogStore();

const ip = getLocalIp();

ReactDOM.render(
  <Provider
    searchFilter={searchFilter}
    department={department}
    auth={auth}
    unAuth={unAuth}
    ap={ap}
    status={status}
    authSummary={authSummary}
    deviceAuthSummary={deviceAuthSummary}
    confirmAlertModal={confirmAlertModal}
    miniAlertModal={miniAlertModal}
    toastAlert={toastAlert}
    table={table}
    loader={loader}
    dashboard={dashboard}
    userInfo={userInfo}
    user={user}
    cell={cell}
    jobStatus={jobStatus}
    accessLog={accessLog}
  >
    <HashRouter>
        <Switch>
          <Route path="/index" render={props => <IndexLayout endPoint={process.env.REACT_APP_ENDPOINT} {...props} />} />
          <Route path="/login" render={props => <LoginLayout endPoint={process.env.REACT_APP_ENDPOINT} {...props} />} />
          <Route
            path="/devices"
            render={props => <CategoryLayout endPoint={process.env.REACT_APP_ENDPOINT} {...props} path="/devices"/>}
          />
          {mainRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              render={props => <MainLayout endPoint={process.env.REACT_APP_ENDPOINT} {...props} />}
            />
          ))}
          <Route
            path="/management"
            render={props => <CategoryLayout endPoint={process.env.REACT_APP_ENDPOINT} {...props} path="/management"/>}
          />
          {mainRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              render={props => <MainLayout endPoint={process.env.REACT_APP_ENDPOINT} {...props} />}
            />
          ))}
          <Redirect to="/index" />
        </Switch>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);
