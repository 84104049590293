import React, { Component } from 'react';
import Loader from 'views/components/loader/Loader';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardTitle, Row, Col, Container } from 'reactstrap';
import nav1 from 'assets/images/pages/dashboard/managed-device.png';
import nav2 from 'assets/images/pages/dashboard/total-auth.png';
import nav3 from 'assets/images/pages/dashboard/succeed-auth.png';
import nav4 from 'assets/images/pages/dashboard/failed-auth.png';
import nav5 from 'assets/images/pages/dashboard/unauth-device.png';
import { requestGet } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';

import CompanyFilter from 'views/components/searchFilter/CompanyFilter';

import { toJS } from 'mobx';
import { observer, inject } from 'mobx-react';

@inject('searchFilter', 'dashboard', 'loader')
@observer
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadToggle: false,
      deviceTotalCount: '-',
      deviceUnregisterCount: '-',
      authTotalCount: '-',
      authSuccessCount: '-',
      authFailCount: '-',
      checkRequiredBeaconCount: '-'
    };
  }

  async componentDidMount() {
    const { searchFilter } = this.props;
    searchFilter.companyList.length === 0 && (await searchFilter.getCompanyList());
    await this.fetchRefresh();
  }

  fetchRefresh = async () => {
    const { searchFilter, loader, dashboard, miniAlertModal } = this.props;
    loader.show(true);

    let params = '?battery=20&unUsedTime=1';
    let url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/dashboards/companies/' + searchFilter.selectCompany.value + params;

    let responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    dashboard.set('data', {
      authFailCount: responseData.data.authFailCount,
      authSuccessCount: responseData.data.authSuccessCount,
      deviceTotalCount: responseData.data.deviceTotalCount,
      deviceUnregisterCount: responseData.data.deviceUnregisterCount,
      authTotalCount: responseData.data.authTotalCount,
      checkRequiredBeaconCount: responseData.data.checkRequiredBeaconCount
    });
  };

  render() {
    const { dashboard, loader } = this.props;
    const data = toJS(dashboard.data);
    return (
      <div className="__homeBackground">
        {loader.showLoader && <Loader></Loader>}
        <Container>
          <div className="content">
            <Row>
              <Col md={{ size: '6', offset: '3' }} style={{ marginBottom: 15 }}>
                <CompanyFilter refreshData={this.fetchRefresh}></CompanyFilter>
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <img src={nav1} alt="nav1" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">관리 디바이스</p>
                          <CardTitle tag="p">{data.deviceTotalCount.toLocaleString()}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats text-center">
                      <NavLink to="/devices/auth" className="nav-link">
                        자세히 보기
                      </NavLink>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <img src={nav2} alt="nav2" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">총 인증</p>
                          <CardTitle tag="p">{data.authTotalCount.toLocaleString()}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats text-center">
                      <NavLink to="/devices/status/:path" className="nav-link">
                        자세히 보기
                      </NavLink>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <img src={nav3} alt="nav3" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">인증 성공</p>
                          <CardTitle tag="p">{data.authSuccessCount.toLocaleString()}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats text-center">
                      <NavLink to="/devices/status/0000" className="nav-link">
                        자세히 보기
                      </NavLink>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <img src={nav4} alt="nav4" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">인증 실패</p>
                          <CardTitle tag="p">{data.authFailCount.toLocaleString()}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats text-center">
                      <NavLink to="/devices/status/0001" className="nav-link">
                        자세히 보기
                      </NavLink>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <img src={nav5} alt="nav5" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">미등록 디바이스</p>
                          <CardTitle tag="p">{data.deviceUnregisterCount.toLocaleString()}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats text-center">
                      <NavLink to="/devices/unauth" className="nav-link">
                        자세히 보기
                      </NavLink>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <img src={nav1} alt="nav1" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">비콘 체크필요</p>
                          <CardTitle tag="p">{data.checkRequiredBeaconCount}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats text-center">
                      <NavLink to="/devices/ap" className="nav-link">
                        자세히 보기
                      </NavLink>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

export default Dashboard;
