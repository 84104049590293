import { observable, action } from 'mobx';

// 사용자 확인 모달
export class MobxStore {
  constructor() {
    this.alertMessage = '';
    this.highlightMessage = '';
    this.userCheckMessage = '';
    this.isOpen = false;
    this.confirmAction = null;
    this.confirmInput = '';
    this.isValidConfirmInput = false;
    this.isShowInputHelpMessage = false;
  }

  @observable alertMessage; // 기본 메시지
  @observable highlightMessage; // 하이라이트 메시지(bold + red color)
  @observable userCheckMessag; // 사용자 동의 메시지
  @observable isOpen; // 모달 출력 여부
  @observable confirmAction; // 실행 메소드

  @observable confirmInput; // 사용자 동의 입력 메시지
  @observable isValidConfirmInput; // 사용자 동의 입력 메시지 값 일치 여부
  @observable isShowInputHelpMessage; // 사용자 입력 안내 텍스트 출력 여부

  // 사용자 확인 모달 활성화
  @action show = (alertMessage, highlightMessage, userCheckMessage, confirmAction) => {
    this.alertMessage = alertMessage;
    this.highlightMessage = highlightMessage;
    this.userCheckMessage = userCheckMessage;
    this.confirmAction = confirmAction;
    this.isOpen = true;
  };
}
