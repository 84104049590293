export const DEPARTMENT_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '5%'
  },
  {
    label: '지점명 (지점ID)',
    key: 'customDepartmentInfo',
    width: '20%'
  },
  {
    label: '부점코드',
    key: 'code',
    width: '10%'
  },
  {
    label: '최소 AP 수',
    key: 'wifi',
    width: '5%'
  },
  {
    label: '우편번호',
    key: 'zip',
    width: '10%'
  },
  {
    label: '주소',
    key: 'roadAddress',
    width: '25%'
  },
  {
    label: '상세주소',
    key: 'roadAddressDetail',
    width: '20%'
  },
  {
    label: '슈퍼 패스',
    key: 'superPassYn',
    width: '5%'
  },
];

export const STATUS_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '4%'
  },
  {
    label: 'Hostname',
    key: 'hostName',
    width: '10%',
  },
  {
    label: 'IP 주소',
    key: 'ipAddress',
    width: '10%'
  },
  {
    label: '지점명 (지점ID)',
    key: 'customDepartmentInfo',
    width: '13%'
  },
  {
    label: '시간',
    key: 'date',
    width: '9%'
  },
  {
    label: '전화번호',
    key: 'phone',
    width: '10%'
  },
  {
    label: '오류 코드',
    key: 'resultCode',
    width: '5%'
  },
  {
    label: '메세지',
    key: 'resultMessage',
    width: '11%'
  },
  {
    label: '추가 메시지',
    key: 'resultDesc',
    width: '13%'
  },
  {
    label: '메모 (단말상태 L:위치, LA:위치항상허용, W:WiFi, B:BLE)',
    key: 'referral',
    width: '15%'
  }
];

export const UNAUTH_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '5%'
  },
  {
    label: '시간',
    key: 'date',
    width: '25%'
  },
  {
    label: '통신사',
    key: 'telecom',
    width: '15%'
  },
  {
    label: '전화번호',
    key: 'phone',
    width: '30%'
  }
];

export const AP_ADMIN_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '5%'
  },
  {
    label: '지점명 (지점ID)',
    key: 'customDepartmentInfo',
    width: '25%'
  },
  {
    label: 'Ap 타입',
    key: 'customDeviceType',
    width: '10%'
  },
  {
    label: 'SSID',
    key: 'ssid',
    width: '20%'
  },
  {
    label: 'BSSID',
    key: 'bssid',
    width: '20%'
  },
  {
    label: 'RSSI',
    key: 'rssi',
    width: '5%'
  },
  {
    label: '인증 사용일시',
    key: 'lastTriedAt',
    width: '20%'
  }
];

export const AP_CUSTOMER_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '5%'
  },
  {
    label: '지점명 (지점ID)',
    key: 'customDepartmentInfo',
    width: '20%'
  },
  {
    label: 'Ap 타입',
    key: 'customDeviceType',
    width: '10%'
  },
  {
    label: 'SSID',
    key: 'ssid',
    width: '30%'
  },
  {
    label: 'BSSID',
    key: 'bssid',
    width: '30%'
  }
];

export const AUTH_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '5%'
  },
  {
    label: '지점명 (지점ID)',
    key: 'customDepartmentInfo',
    width: '25%'
  },
  {
    label: '통신사',
    key: 'telecom',
    width: '10%'
  },
  {
    label: '전화번호',
    key: 'phone',
    width: '10%'
  },
  {
    label: '기기식별자',
    key: 'adid',
    width: '20%'
  },
  {
    label: '시도일시',
    key: 'lastTriedAt',
    width: '10%'
  },
  {
    label: '성공일시',
    key: 'lastSucceededAt',
    width: '10%'
  }
];

export const CELL_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '5%'
  },
  {
    label: '지점명 (지점ID)',
    key: 'customDepartmentInfo',
    width: '25%'
  },
  {
    label: '통신사',
    key: 'telecom',
    width: '10%'
  },
  {
    label: 'LAC',
    key: 'lac',
    width: '10%'
  },
  {
    label: 'CELL ID',
    key: 'cellId',
    width: '15%'
  },
  {
    label: '인증 사용일시',
    key: 'lastTriedAt',
    width: '20%'
  },
  {
    label: '등록 유형',
    key: 'inertType',
    width: '10%'
  }
];

export const AUTH_SUMMARY_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '5%'
  },
  {
    label: '지점명',
    key: 'departmentName',
    width: '35%'
  },
  {
    label: '총인증시도건수',
    key: 'authTotCount',
    width: '15%'
  },
  {
    label: '인증실패건수',
    key: 'authFailCount',
    width: '15%'
  },
  {
    label: '서버에러건수',
    key: 'serverErrorCount',
    width: '15%'
  },
  {
    label: '인증실패율',
    key: 'failRate',
    width: '15%'
  }
];

export const AUTH_SUMMARY_DETAIL_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '5%'
  },
  {
    label: '날짜',
    key: 'date',
    width: '35%'
  },
  {
    label: '총인증시도건수',
    key: 'authTotCount',
    width: '15%'
  },
  {
    label: '인증실패건수',
    key: 'authFailCount',
    width: '15%'
  },
  {
    label: '서버에러건수',
    key: 'serverErrorCount',
    width: '15%'
  },
  {
    label: '인증실패율',
    key: 'failRate',
    width: '15%'
  }
];

export const USER_ADMIN_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '4%'
  },
  {
    label: '회사명',
    key: 'companyName',
    width: '15%'
  },
  {
    label: '아이디',
    key: 'loginId',
    width: '12%'
  },
  {
    label: '이름',
    key: 'name',
    width: '8%'
  },
  {
    label: '이메일',
    key: 'email',
    width: '15%'
  },
  {
    label: '전화번호',
    key: 'phone',
    width: '12%'
  },
  {
    label: '권한',
    key: 'userType',
    width: '12%'
  },
  {
    label: 'IP 주소',
    key: 'ipAddress',
    width: '12%'
  },
  {
    label: '마지막 로그인 일시',
    key: 'lastLoggedAt',
    width: '10%'
  }
];

export const USER_CUSTOMER_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '5%'
  },
  {
    label: '아이디',
    key: 'loginId',
    width: '13%'
  },
  {
    label: '이름',
    key: 'name',
    width: '10%'
  },
  {
    label: '이메일',
    key: 'email',
    width: '18%'
  },
  {
    label: '전화번호',
    key: 'phone',
    width: '12%'
  },
  {
    label: '권한',
    key: 'userType',
    width: '12%'
  },
  {
    label: 'IP 주소',
    key: 'ipAddress',
    width: '15%'
  },
  {
    label: '마지막 로그인 일시',
    key: 'lastLoggedAt',
    width: '15%'
  }
];

export const JOB_STATUS_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '5%'
  },
  {
    label: '회사명',
    key: 'companyName',
    width: '15%'
  },
  {
    label: '시간',
    key: 'date',
    width: '15%'
  },
  {
    label: 'Job 이름',
    key: 'jobName',
    width: '15%'
  },
  {
    label: 'Hostname',
    key: 'hostName',
    width: '20%'
  },
  {
    label: '상태',
    key: 'status',
    width: '10%'
  },
  {
    label: '처리건수',
    key: 'processedCount',
    width: '10%'
  },
  {
    label: '처리시간(ms)',
    key: 'processTimeMs',
    width: '10%'
  }
];

export const ACCESS_LOG_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '5%'
  },
  {
    label: '회사명',
    key: 'companyName',
    width: '15%'
  },
  {
    label: '시간',
    key: 'date',
    width: '10%'
  },
  {
    label: 'ID',
    key: 'loginId',
    width: '12%'
  },
  {
    label: 'IP 주소',
    key: 'ipAddress',
    width: '10%'
  },
  {
    label: '메뉴',
    key: 'menu',
    width: '13%'
  },
  {
    label: '작업 내용',
    key: 'action',
    width: '10%'
  },
  {
    label: '작업 내용 상세',
    key: 'contents',
    width: '25%'
  }
];

export const BLE_BATTERY_ADMIN_TABLE_HEADER = [
  {
    label: '순번',
    key: 'rowNum',
    width: '5%'
  },
  {
    label: '지점명 (지점ID)',
    key: 'customDepartmentInfo',
    width: '20%'
  },
  {
    label: 'Ap 타입',
    key: 'customDeviceType',
    width: '10%'
  },
  {
    label: 'SSID',
    key: 'ssid',
    width: '20%'
  },
  {
    label: 'BSSID',
    key: 'bssid',
    width: '20%'
  },
  {
    label: 'BATTERY(%)',
    key: 'battery',
    width: '10%'
  },
  {
    label: 'RSSI',
    key: 'rssi',
    width: '5%'
  },
  {
    label: '인증 사용일시',
    key: 'lastTriedAt',
    width: '15%'
  }
];
