import React from 'react';
import { Button, Modal, Label } from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { requestGet } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';
import Logger from 'utils/logger';
import { getLocalIp } from 'utils/ipHelper';
import { saveAs } from 'file-saver';

// 등록 디바이스 파일 업로드 모달
@inject('auth', 'searchFilter', 'miniAlertModal', 'loader')
@observer
class FileUploadAuthDeviceModal extends React.Component {
  file = null;
  input = null;
  inputs = [];

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileName: ''
    };
  }

  toggleModal = () => {
    const { auth } = this.props;
    auth.set('fileUploadModalIsOpen', !auth.fileUploadModalIsOpen);
  };

  // 파일 변경 이벤트 처리
  handleUploadFileChange = e => {
    const file = e.target.files;
    this.setState({
      ...this.state,
      file: file,
      fileName: file[0].name
    });
  };

  // 파일 선택 버튼 클릭 이벤트 처리
  handleSelectFileButtonClick = () => {
    this.file.click();
  };

  // 등록 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    const { miniAlertModal } = this.props;
    const fileName = this.state.fileName;
    if (fileName === '') {
      miniAlertModal.show('업로드할 파일을 선택해주세요.');
    } else {
      this.fetchRegisterDeviceFileUpload();
    }
  };

  // 디바이스 파일 업로드 API 처리
  fetchRegisterDeviceFileUpload = async () => {
    const { searchFilter, miniAlertModal, loader, fetchRefresh } = this.props;
    loader.show(true);

    const obj = this.file;
    if (obj.value !== '') {
      const url =
        process.env.REACT_APP_ENDPOINT +
        '/v1/admin/api/companies/' +
        searchFilter.selectCompany.value +
        '/devices/excel';
      const formData = new FormData();
      formData.append('excelFile', this.state.file[0]);
      const option = {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'X-Auth-Local-Address': getLocalIp()
        }
      };
      const responseData = await fetch(url, option)
        .then(res => res.json())
        .then(json => {
          if (json.code === '0000') {
            Logger.log(json);
            return json;
          } else {
            Logger.log(json.message);
            return json;
          }
        })
        .catch(err => {
          Logger.log(err);
          return 'failedToFetch';
        });
      loader.show(false);

      await isValidResponse(responseData, miniAlertModal, this.props.history);

      if (responseData.code === '0000') {
        if (process.env.REACT_APP_FILE_SYSTEM === 'FILE') {
          const byteCharacters = atob(responseData.data.fileData);

          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray]);

          saveAs(blob, responseData.data.fileName);

          await searchFilter.set('page', 1);
          miniAlertModal.show('업로드 되었습니다.');
        } else {
          responseData.data.map(data => {
            saveAs(
              data.excelUrl,
              data.excelUrl.substring(data.excelUrl.lastIndexOf('/') + 1, data.excelUrl.lastIndexOf('_'))
            );
            return null;
          });

          this.setState({
            ...this.state,
            file: null,
            fileName: ''
          });
        }
      } else {
        miniAlertModal.show(responseData.message);
      }

      await fetchRefresh();
      this.toggleModal();
    } else {
      this.handleSelectFileButtonClick();
    }
  };

  // 양식 다운로드 버튼 클릭 이벤트 처리
  handleFormDownloadButtonClick = async () => {
    const { loader, miniAlertModal } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/devices/excel/sample';
    const responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);
    if (process.env.REACT_APP_FILE_SYSTEM === 'FILE') {
      const byteCharacters = atob(responseData.data.fileData);

      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);

      saveAs(blob, responseData.data.fileName);
    } else {
      if (responseData.code === '0000') {
        responseData.data.map(data => {
          saveAs(
            data.excelUrl,
            data.excelUrl.substring(data.excelUrl.lastIndexOf('/') + 1, data.excelUrl.lastIndexOf('_'))
          );
          return null;
        });
      }
    }

    if (responseData.code !== '0000') {
      miniAlertModal.show(responseData.message);
    }
  };

  render() {
    const { auth } = this.props;
    return (
      <Modal className="modal-lg" backdrop={'static'} isOpen={auth.fileUploadModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">파일 업로드</h4>
        </div>
        <div className="modal-body __textAlignCenter">
          <div className="blockquote">
            <span>
              <Label>
                1.전화번호를 등록할 수 있도록 <b>양식 다운로드</b> 버튼을 클릭하여 해당 양식을 내려받으세요.
              </Label>
            </span>
            <br />
            <span>
              <Label>2. 사원의 ① 통신사 ② 전화번호 다운로드 받은 파일에 작성해주세요.</Label>
            </span>
            <br />
            <span>
              <Label>
                3.작성한 파일을 <b>파일선택</b> 버튼을 클릭하여 작성하신 엑셀 파일을 업로드 하세요.
              </Label>
            </span>
          </div>
          <div className="row">
            <div className="col-md-8">
              <input
                ref={ref => (this.file = ref)}
                onChange={this.handleUploadFileChange}
                type="file"
                name="file"
                hidden={true}
              />
              <p
                style={{
                  marginTop: '1rem'
                }}
              >
                {this.state.fileName === '' ? '선택된 파일 없음' : this.state.fileName}
              </p>
            </div>
            <div className="col-md-4 text-right">
              <Button
                className="btn-round"
                onClick={e => {
                  e.preventDefault();
                  this.handleSelectFileButtonClick();
                }}
                color="primary"
              >
                파일 선택
              </Button>
              <Button className="btn-round" onClick={this.handleFormDownloadButtonClick} color="primary">
                양식 다운로드
              </Button>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}
            >
              업로드
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
              닫기
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

FileUploadAuthDeviceModal.defaultProps = {
  fetchRefresh: async () => {
    Logger.log('[FileUploadAuthDeviceModal] - refresh function is undefined');
  }
};

export default FileUploadAuthDeviceModal;
