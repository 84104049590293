import { observable, action } from 'mobx';

// 단말 인증집계 현황
export class MobxStore {
  constructor() {
    this.data = null;
  }
  @observable data; // 인증현황 데이터

  @action set = (key, value) => {
    this[key] = value;
  };
}
