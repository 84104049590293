import React from 'react';
import Select from 'react-select';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import Loader from 'views/components/loader/Loader';
import buildUrl from 'build-url';
import Pagination from 'views/components/pagination/CustomPagination';

import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { requestGet } from 'utils/fetchHelper';
import { autoPhoneFormatter } from 'utils/fomatter';

import ConfirmAlert from 'views/components/alert/confirmAlert/ConfirmAlert';
import MiniAlert from 'views/components/alert/miniAlert/MiniAlert';
import ToastAlert from 'views/components/alert/toastAlert/ToastAlert';

import CompanyFilter from 'views/components/searchFilter/CompanyFilter';
import DepartmentFilter from 'views/components/searchFilter/DepartmentFilter';
import HostNameFilter from 'views/components/searchFilter/HostNameFilter';

import DetailStatusModal from './Detail';

import { STATUS_TABLE_HEADER } from 'commons/conts/tableHeader';
import Table from 'views/components/table/normal/table';

import { isValidResponse } from 'utils/responseValidChecker';

import { inject, observer } from 'mobx-react';
import ResultCodeFilter from 'views/components/searchFilter/ResultCodeFilter';
import { saveAs } from 'file-saver';
import TotalCount from 'views/components/common/totalCount/TotalCount';

@inject('searchFilter', 'status', 'confirmAlertModal', 'miniAlertModal', 'toastAlert', 'loader', 'table')
@observer
class Status extends React.Component {
  radioAll = '';

  constructor(props) {
    super(props);
    moment.updateLocale(moment.locale(), { invalidDate: '' });
  }

  async componentDidMount() {
    this.radioAll.checked = true;
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    searchFilter.companyList.length === 0 && (await searchFilter.getCompanyList());
    await searchFilter.getResultCodeList();
    await searchFilter.set('startDate', moment().format('YYYY-MM-DD 00:00:00'));
    await searchFilter.set('endDate', moment().format('YYYY-MM-DD 23:59:59'));
    searchFilter.hostNameList.length === 0 && (await searchFilter.getHostNameList());

    if (this.props.match.params.path === '0000') {
      // 인증 성공 조회
      searchFilter.selectResultCode = {
        label: '0000 - 인증 성공',
        value: '0000'
      };
    } else if (this.props.match.params.path === '0001') {
      // 인증 실패 조회
      searchFilter.selectResultCode = {
        label: 'ERROR - 인증 실패',
        value: 'ERROR'
      };
    } else {
      // 전체 조회
      searchFilter.selectResultCode = {
        label: 'ALL - 전체',
        value: 'ALL'
      };
    }

    this.fetchRefresh();
  }

  componentWillUnmount() {
    const { searchFilter, status } = this.props;
    searchFilter.clear();
    status.set('data', null);
  }

  // 인증 현황 조회 API 호출
  fetchRefresh = async () => {
    const { searchFilter, miniAlertModal, loader, status } = this.props;
    loader.show(true);

    let queryParams = {};
    queryParams.companyId = searchFilter.selectCompany.value;
    searchFilter.selectDepartment.value !== '' && (queryParams.departmentId = searchFilter.selectDepartment.value);
    searchFilter.startDate !== '' &&
      (queryParams.startDate = moment(searchFilter.startDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
    searchFilter.endDate !== '' &&
      (queryParams.endDate = moment(searchFilter.endDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
    searchFilter.selectResultCode !== '' && (queryParams.status = searchFilter.selectResultCode.value);
    searchFilter.phone !== '' && (queryParams.phone = searchFilter.phone);
    searchFilter.txid !== '' && (queryParams.txid = searchFilter.txid);
    searchFilter.ipAddress !== '' && (queryParams.ipAddress = searchFilter.ipAddress);
    searchFilter.nearStatus !== '' && (queryParams.nearYn = searchFilter.nearStatus);
    searchFilter.selectHostName.value !== '' && (queryParams.hostName = searchFilter.selectHostName.value);
    queryParams.page = searchFilter.page !== '' ? searchFilter.page : 1;
    queryParams.size = searchFilter.pageSize.value !== '' ? searchFilter.pageSize.value : 10;

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/v1/admin/api/statistics/auth',
      queryParams: queryParams
    });

    let responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    const editedResponseData = await this.addCustomColumn(responseData);

    status.set('data', editedResponseData.data);
  };

  // 지점명(지점ID) 및 Ap 타입 칼럼 추가
  addCustomColumn = async response => {
    response.data.content = response.data.content.map(item => {
      return {
        ...item,
        customDepartmentInfo: item.departmentName !== null ? item.departmentName + ' (' + item.departmentId + ')' : ''
      };
    });
    return response;
  };

  // 구간 버튼 클릭 이벤트 처리
  handleDateButtonClick = async (e, range) => {
    const { searchFilter } = this.props;
    let from = moment();
    let to = moment();
    switch (range) {
      case '3days':
        from = moment()
          .subtract(3, 'days')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case '7days':
        from = moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case '1months':
        from = moment()
          .subtract(1, 'months')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case '3months':
        from = moment()
          .subtract(3, 'months')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
      default:
        from = moment()
          .subtract(3, 'days')
          .format('YYYY-MM-DD 00:00:00');
        to = moment().format('YYYY-MM-DD 23:59:59');
        break;
    }

    await searchFilter.set('startDate', from);
    await searchFilter.set('endDate', to);
    await searchFilter.set('page', 1);

    this.fetchRefresh();
  };

  // 다운로드 버튼 클릭 이벤트 처리
  handleDownloadButtonClick = async () => {
    const { loader, miniAlertModal, searchFilter } = this.props;
    loader.show(true);

    let queryParams = {};
    queryParams.companyId = searchFilter.selectCompany.value;
    searchFilter.selectDepartment.value !== '' && (queryParams.departmentId = searchFilter.selectDepartment.value);
    searchFilter.startDate !== '' &&
      (queryParams.startDate = moment(searchFilter.startDate, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDD-HH:mm:ss'));
    searchFilter.endDate !== '' &&
      (queryParams.endDate = moment(searchFilter.endDate, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDD-HH:mm:ss'));
    searchFilter.selectResultCode !== '' && (queryParams.status = searchFilter.selectResultCode.value);
    searchFilter.phone !== '' && (queryParams.phone = searchFilter.phone);
    searchFilter.txid !== '' && (queryParams.txid = searchFilter.txid);
    searchFilter.ipAddress !== '' && (queryParams.ipAddress = searchFilter.ipAddress);
    searchFilter.nearStatus !== '' && (queryParams.nearYn = searchFilter.nearStatus);
    searchFilter.selectHostName.value !== '' && (queryParams.hostName = searchFilter.selectHostName.value);

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/v1/admin/api/statistics/excel',
      queryParams: queryParams
    });

    const responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (process.env.REACT_APP_FILE_SYSTEM === 'FILE') {
      const byteCharacters = atob(responseData.data.fileData);

      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);

      saveAs(blob, responseData.data.fileName);
    } else {
      if (responseData.code === '0000') {
        responseData.data.map(data => {
          saveAs(
            data.excelUrl,
            data.excelUrl.substring(data.excelUrl.lastIndexOf('/') + 1, data.excelUrl.lastIndexOf('_'))
          );
          return data;
        });
      }
    }
    if (responseData.code !== '0000') {
      miniAlertModal.show(responseData.message);
    }
  };

  // 데이터 테이블 클릭 - 인증 현황 상세 모달
  handleEditRowClick = async data => {
    const { status } = this.props;
    const editData = {
      id: data.id,
      departmentName: data.departmentName !== null ? data.departmentName : '',
      departmentId: data.departmentId !== null ? data.departmentId : '',
      date: data.date,
      phone: data.phone,
      hostName: data.hostName,
      ipAddress: data.ipAddress,
      resultCode: data.resultCode,
      userAgent: data.userAgent,
      resultMessage: data.resultMessage,
      resultDesc: data.resultDesc,
      referral: data.referral !== null ? data.referral : '',
      txid: data.txId !== null ? data.txId : '',
      telecom: data.telecom !== null ? data.telecom : '',
      lac: data.lac !== null ? data.lac : '',
      cellId: data.cellId !== null ? data.cellId : '',
      wifis: data.wifis !== null ? data.wifis : '',
      bles: data.bles !== null ? data.bles : '',
      nfcs: data.nfcs !== null ? data.nfcs : ''
    };
    status.setDetailStatus('detailStatus', null, null, editData);
    status.set('detailModalIsOpen', !status.detailModalIsOpen);
  };

  // 검색 조건 엔터키 입력 이벤트 처리
  handleEnterKeyPress = async e => {
    const { searchFilter } = this.props;
    if (e.key === 'Enter') {
      await searchFilter.set('page', 1);
      this.fetchRefresh();
    }
  };

  // 인증 근처 근처/외부 필터 라디오 버튼 클릭 이벤트 처리
  handleRadioChange = async e => {
    const { searchFilter } = this.props;
    const nearStatus = e.target.value;
    await searchFilter.set('nearStatus', nearStatus);
    await searchFilter.set('page', 1);
    this.fetchRefresh();
  };

  // 페이징 컴포넌트 콜백 함수
  handlePaginationCallback = async page => {
    const { searchFilter } = this.props;
    searchFilter.set('page', page);
    await this.fetchRefresh();
  };

  // 검색 버튼 클릭 이벤트
  handleSearchClick = async () => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    this.fetchRefresh();
  };

  // 페이지 당 검색 결과 수 변경 이벤트 함수
  handlePageSizeChanged = async e => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    await searchFilter.setPageSize(e);
    await this.fetchRefresh();
  };

  render() {
    let { confirmAlertModal, miniAlertModal, loader, status, searchFilter, toastAlert } = this.props;
    return (
      <>
        {loader.showLoader && <Loader></Loader>}
        <div className="content">
          <h4 className="__marginTop">인증 현황</h4>
          <h6>날짜 별로 인증 현황을 확인할 수 있습니다.</h6>
          <Card className="card-stats">
            <CardBody className="__padding">
              <Form className="form-horizontal">
                <Row md="12">
                  <Col md={{ size: 10, offset: 1 }} className="text-center">
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        회사명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <CompanyFilter refreshData={this.fetchRefresh}></CompanyFilter>
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        지점명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <DepartmentFilter refreshData={this.fetchRefresh}></DepartmentFilter>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        전화번호
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <Input
                            className="text-center"
                            type="text"
                            value={autoPhoneFormatter(this.props.searchFilter.phone)}
                            onChange={e => {
                              this.props.searchFilter.set('phone', e.target.value.replace(/[^0-9]/g, ''));
                            }}
                            onKeyPress={this.handleEnterKeyPress}
                            maxLength={13}
                          />
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        txid
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <Input
                            className="text-center"
                            type="text"
                            value={this.props.searchFilter.txid}
                            onChange={e => {
                              this.props.searchFilter.set('txid', e.target.value);
                            }}
                            onKeyPress={this.handleEnterKeyPress}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        조회 시작일
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <ReactDatetime
                            inputProps={{
                              className: 'form-control datetime-custom',
                              placeholder: moment(this.props.searchFilter.startDate, 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              value: moment(this.props.searchFilter.startDate, 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              readOnly: true
                            }}
                            dateFormat="YYYYMMDD"
                            timeFormat="HH:mm"
                            onChange={e => {
                              this.props.searchFilter.set('startDate', moment(e).format('YYYY-MM-DD HH:mm:ss'));
                            }}
                            locale="ko"
                            // timeFormat={true}
                            // input={true}
                          />
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        조회 종료일
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <ReactDatetime
                            inputProps={{
                              className: 'form-control datetime-custom',
                              placeholder: moment(this.props.searchFilter.endDate, 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              value: moment(this.props.searchFilter.endDate, 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              readOnly: true
                            }}
                            dateFormat="YYYYMMDD"
                            timeFormat="HH:mm"
                            onChange={e => {
                              this.props.searchFilter.set('endDate', moment(e).format('YYYY-MM-DD HH:mm:ss'));
                            }}
                            locale="ko"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        오류코드
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <ResultCodeFilter refreshData={this.fetchRefresh} />
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        IP 주소
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <Input
                            className="text-center"
                            type="text"
                            value={this.props.searchFilter.ipAddress}
                            onChange={e => {
                              this.props.searchFilter.set('ipAddress', e.target.value);
                            }}
                            onKeyPress={this.handleEnterKeyPress}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        Hostname
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <HostNameFilter refreshData={this.fetchRefresh}></HostNameFilter>
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        근처
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <div className="form-check-radio form-check-inline">
                          <Label className="form-check-label">
                            <Input
                              innerRef={ref => (this.radioAll = ref)}
                              type="radio"
                              name="near"
                              onChange={this.handleRadioChange}
                              value=""
                            />
                            전체
                            <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div className="form-check-radio form-check-inline">
                          <Label className="form-check-label">
                            <Input type="radio" name="near" onChange={this.handleRadioChange} value="Y" />
                            근처
                            <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div className="form-check-radio form-check-inline">
                          <Label className="form-check-label">
                            <Input type="radio" name="near" onChange={this.handleRadioChange} value="N" />
                            외부
                            <span className="form-check-sign" />
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="12">
                        <ButtonGroup data-toggle="buttons">
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '3days');
                            }}
                          >
                            3 일
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '7days');
                            }}
                          >
                            7 일
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '1months');
                            }}
                          >
                            1개월
                          </Button>
                          <Button
                            className="btn-round btn-sm"
                            color="info"
                            outline
                            type="button"
                            onClick={e => {
                              this.handleDateButtonClick(e, '3months');
                            }}
                          >
                            3개월
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="12">
                        <Button className="btn-round __marginTop" color="primary" onClick={this.handleSearchClick}>
                          조회
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="form-horizontal __marginBottom5">
                <Col md="12" className="text-right">
                  <div className="__inline-div">
                    <Select
                      className="react-select __pageSelect"
                      classNamePrefix="react-select"
                      name="pageSize"
                      placeholder=""
                      value={searchFilter.pageSize}
                      options={searchFilter.pageSizeList}
                      onChange={this.handlePageSizeChanged}
                    />
                    <Label className="label-page">페이지 사이즈</Label>
                    <TotalCount totalElements={status.data && status.data.totalElements} />
                  </div>
                </Col>
              </Row>
              <Row className="form-horizontal">
                <Col md="12">
                  <Table header={STATUS_TABLE_HEADER} data={status.data} handleRowClick={this.handleEditRowClick} />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={{ offset: 3, size: 6 }}>
                  {status.data && (
                    <Pagination
                      currentPage={searchFilter.page}
                      totalPages={status.data.totalPages}
                      pageSize={status.data.pageable.pageSize}
                      callback={this.handlePaginationCallback}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg="12" xl="12" className="text-right">
                  <Button className="btn-round __marginTop" color="primary" onClick={this.handleDownloadButtonClick}>
                    다운로드
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>

          {/* 상세 현황 모달 */}
          {status.detailModalIsOpen && <DetailStatusModal {...this.props} fetchRefresh={this.fetchRefresh} />}

          {/* 알림 팝업 */}
          {miniAlertModal.isOpen && <MiniAlert />}

          {/* 사용자 확인 알림 모달 */}
          {confirmAlertModal.isOpen && <ConfirmAlert />}

          {/* 토스트 메시지 팝업 */}
          {toastAlert.isOpen && <ToastAlert />}
        </div>
      </>
    );
  };
}

export default Status;
