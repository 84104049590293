import { observable, action } from 'mobx';

// Job Status 관리
export class MobxStore {
  constructor() {
    this.data = null;
  }

  @observable data; // job status 데이터

  @action set = (key, value) => {
    this[key] = value;
  };
}