import { observable, action } from 'mobx';

// 위치인증 Ap 관리
export class MobxStore {
  constructor() {
    this.data = null;
    this.registerAps = [
      {
        department: '',
        type: '',
        ssid: '',
        bssid: '',
        rssiFilter: ''
      }
    ];
    this.editAps = {
      deviceId: '',
      type: '',
      department: '',
      ssid: '',
      bssid: '',
      rssiFilter: ''
    };
    this.egisterModalIsOpen = false;
    this.modifyModalIsOpen = false;
  }

  @observable data; // 위치인증 Ap 데이터
  @observable registerAps; // Ap 등록
  @observable editAps; // Ap 수정
  @observable registerModalIsOpen; // 등록 모달 토글
  @observable modifyModalIsOpen; // 수정 모달 토글

  // 등록 데이터 변경
  @action setRegisterAps = (index, key, value) => {
    this.registerAps[index][key] = value;
  };

  // 등록 데이터 추가
  @action addRegisterAps = () => {
    this.registerAps.push({ department: '', type: '', ssid: '', bssid: '', rssiFilter: '' });
  };

  // 등록 데이터 제거
  @action deleteRegisterAps = index => {
    this.registerAps.splice(index, 1);
  };

  // 등록 데이터셋 초기화
  @action clearRegisterAps = () => {
    this.registerAps = [
      {
        department: '',
        type: '',
        ssid: '',
        bssid: '',
        rssiFilter: ''
      }
    ];
  };

  // 수정 데이터셋 초기화
  @action clearModifyAps = () => {
    this.editAps = {
      deviceId: '',
      type: '',
      department: '',
      ssid: '',
      bssid: '',
      rssiFilter: ''
    };
  };

  // 수정 데이터 변경
  @action setEditAps = (objName, key, value, newObj) => {
    if (newObj !== undefined && newObj !== null) {
      this[objName] = newObj;
    } else {
      this[objName] = {
        ...this[objName],
        [key]: value
      };
    }
  };

  @action set = (key, value) => {
    this[key] = value;
  };
}
