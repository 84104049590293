import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import Logger from 'utils/logger';
import DepartmentFilter from 'views/components/searchFilter/DepartmentFilter';
import { inject, observer } from 'mobx-react';

@inject('searchFilter')
@observer
class ResultCodeFilter extends Component {
  handleSelectChange = async e => {
    const { searchFilter } = this.props;
    const resultCode = e;
    searchFilter.set('selectResultCode', {
      label: resultCode.label,
      value: resultCode.value
    });
    await searchFilter.set('page', 1);
    await this.props.refreshData();
  };

  render() {
    const { searchFilter } = this.props;
    return (
      <FormGroup>
        <Select
          className="react-select text-left"
          classNamePrefix="react-select"
          name="selectDepartment"
          value={searchFilter.selectResultCode}
          onChange={this.handleSelectChange}
          options={searchFilter.resultCodeList}
          placeholder="오류코드를 선택하세요."
        />
      </FormGroup>
    );
  }
}

DepartmentFilter.defaultProps = {
  refreshData: () => {
    Logger.log('[DepartmentFilter] - refresh function is undefined');
  }
};

export default ResultCodeFilter;
