import React, { Component } from 'react';
import { Button, Container } from 'reactstrap';

import backgroundImg from 'assets/images/pages/index/background.png';
import { inject, observer } from 'mobx-react';

@inject('userInfo')
@observer
class Index extends Component {
  componentDidMount() {
    localStorage.removeItem('token');
    localStorage.removeItem('companyId');
    localStorage.removeItem('companyName');
    localStorage.removeItem('ip');
    this.props.userInfo.set('resetOption', '');
  }

  render() {
    return (
      <div className="__indexText">
        <Container>
          <h5>위치인증 솔루션 NO.1 엘핀</h5>
          <h1>위치기반 접속권한 관리</h1>
          <div className="__innerText">
            <h5>
              사용자가 소유한 모바일기기와 근무지점 건물의 위치를
              <br />
              비교하여 시스템 접근 승인 또는 거부
            </h5>
          </div>
          <Button
            color="primary"
            size="lg"
            onClick={() => {
              this.props.history.push('/login/in');
            }}>
            Login
          </Button>
          <img className="__backgroundImage" src={backgroundImg} alt="backgroundImg" />
        </Container>
      </div>
    );
  }
}

export default Index;
