import React from 'react';
import { Button, Modal, Row, Col, Label, FormGroup, Input } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

import { autoPhoneFormatter } from 'utils/fomatter';
import Logger from 'utils/logger';
import { requestPut } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';
import { getUserType } from 'utils/tokenHelper';
import { handleCopyClick } from 'views/components/alert/toastAlert/copy';

import DetailModalRegisterCellTower from './RegisterCell';
import ToastAlert from 'views/components/alert/toastAlert/ToastAlert';

// 인증 현황 상세 모달
@inject('status', 'searchFilter', 'miniAlertModal', 'toastAlert', 'loader')
@observer
class DetailStatusModal extends React.Component {
  toggleModal = () => {
    const { status } = this.props;
    status.clearDetailStatus();
    status.set('detailModalIsOpen', !status.detailModalIsOpen);
  };

  // 수정 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    this.fetchModfiyStatus();
  };

  // 기지국 등록 버튼 클릭 이벤트 처리
  handleRegisterButtonClick = () => {
    const { status } = this.props;
    status.set('detailModalRegisterCell', !status.detailModalRegisterCell);
  }

  // 인증현황 수정 API 호출
  fetchModfiyStatus = async () => {
    const { miniAlertModal, loader, searchFilter, status, fetchRefresh } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/statistics/auth';
    const body = {
      historyAuth: {
        id: status.detailStatus.id,
        referral: status.detailStatus.referral
      }
    };
    const responseData = await requestPut(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      status.clearDetailStatus();

      await searchFilter.set('page', 1);
      fetchRefresh();
      miniAlertModal.show('수정되었습니다.');
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  // 리셋 버튼 클릭 이벤트 처리
  handleResetButtonClick = async () => {
    const { confirmAlertModal } = this.props;
    confirmAlertModal.show(
      '기기식별자를 리셋합니다.\n',
      '리셋된 기기식별자는 복원할 수 없습니다.\n그래도 리셋 하시겠습니까?',
      '',
      this.fetchResetDevice
    );
  };

  // 기기식별자 리셋 API 호출
  fetchResetDevice = async () => {
    const { miniAlertModal, loader, searchFilter, status, fetchRefresh } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/devices/resetbyphone';
    const devices = [{
      companyId:localStorage.getItem('companyId'),
      phone: status.detailStatus.phone
    }];
    const body = {
      devices: devices
    };
    const responseData = await requestPut(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      status.clearDetailStatus();

      await searchFilter.set('page', 1);
      fetchRefresh();
      miniAlertModal.show('기기식별자가 리셋되었습니다.');
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  render() {
    const { status, toastAlert } = this.props;
    return (
      <>
      <Modal className="modal-lg" backdrop={'static'} isOpen={status.detailModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">현황 상세 보기</h4>
        </div>
        <div className="modal-body  __modalScrollbar">
          <Row>
            <Col md="6">
              <div className="row">
                <Label className="col-md-3 col-form-label font-weight-bold text-center">지점명</Label>
                <FormGroup className="col-md-9">
                  <Input
                    readOnly
                    className="__textAlignCenter"
                    type="text"
                    name="departmentName"
                    value={status.detailStatus.departmentName}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col md="6">
              <div className="row">
                <Label className="col-md-3 col-form-label font-weight-bold text-center">시간</Label>
                <FormGroup className="col-md-9">
                  <Input
                    readOnly
                    className="__textAlignCenter"
                    type="text"
                    name="date"
                    value={status.detailStatus.date}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="row">
                <Label className="col-md-3 col-form-label font-weight-bold text-center">전화번호</Label>
                <FormGroup className="col-md-9">
                  <Input
                    readOnly
                    className="__textAlignCenter"
                    type="text"
                    value={autoPhoneFormatter(status.detailStatus.phone)}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col md="6">
              <div className="row">
                <Label className="col-md-3 col-form-label font-weight-bold text-center">오류코드</Label>
                <FormGroup className="col-md-9">
                  <Input
                    readOnly
                    className="__textAlignCenter"
                    type="text"
                    name="resultCode"
                    value={status.detailStatus.resultCode}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="row">
                <Label className="col-md-3 col-form-label font-weight-bold text-center">Hostname</Label>
                <FormGroup className="col-md-9">
                  <Input
                    readOnly
                    className="__textAlignCenter"
                    type="text"
                    value={status.detailStatus.hostName}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col md="6">
              <div className="row">
                <Label className="col-md-3 col-form-label font-weight-bold text-center">IP 주소</Label>
                <FormGroup className="col-md-9">
                  <Input
                    readOnly
                    className="__textAlignCenter"
                    type="text"
                    name="resultCode"
                    value={status.detailStatus.ipAddress}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">User Agent</Label>
                <FormGroup className="col-md-10">
                  <Input
                    readOnly
                    md="8"
                    className="__textAlignCenter"
                    type="text"
                    name="resultMessage"
                    value={status.detailStatus.userAgent}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">메세지</Label>
                <FormGroup className={status.detailStatus.resultCode === '1121' ? 'col-md-7' : 'col-md-10'}>
                  <Input
                    readOnly
                    md="8"
                    className="__textAlignCenter"
                    type="text"
                    name="resultMessage"
                    value={status.detailStatus.resultMessage}
                  />
                </FormGroup>
                {status.detailStatus.resultCode === '1121' && (
                  <div className="col-md-3">
                    <Button
                      className="btn-round __marginTop"
                      color="primary"
                      onClick={this.handleResetButtonClick}
                    >
                      기기식별자 리셋
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">추가 메세지</Label>
                <FormGroup className="col-md-10">
                  <Input
                    readOnly
                    md="8"
                    className="__textAlignCenter"
                    type="text"
                    name="resultMessage"
                    value={status.detailStatus.resultDesc}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">메모</Label>
                <FormGroup className="col-md-10">
                  <Input
                    md="8"
                    className="__textAlignCenter"
                    type="text"
                    onChange={e => {
                      status.setDetailStatus('detailStatus', 'referral', e.target.value, null);
                    }}
                    name="referral"
                    value={status.detailStatus.referral}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="row">
                <Label className="col-md-2 col-form-label font-weight-bold text-center">txid</Label>
                <FormGroup className="col-md-8">
                  <Input
                    readOnly
                    className="__textAlignCenter"
                    type="text"
                    id="detailTxid"
                    name="txid"
                    value={status.detailStatus.txid}
                  />
                </FormGroup>
                <div className="col-md-2">
                  <Button
                    className="btn-round __marginTop"
                    color="primary"
                    onClick={(e) => {
                      let mousePosition = { mouseX: e.clientX, mouseY: e.clientY };
                      handleCopyClick(toastAlert, 'detailTxid', mousePosition);
                    }}
                  >
                    복사
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          {getUserType().slice(0, 2) === 'SA' || getUserType() === 'CDSU' ? (
            <>
              <Row>
                <Col md="6">
                  <div className="row">
                    <Label className="col-md-4 col-form-label font-weight-bold text-center">통신사</Label>
                    <FormGroup className="col-md-8">
                      <Input
                        readOnly
                        className="__textAlignCenter"
                        type="text"
                        name="telecom"
                        value={status.detailStatus.telecom}
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col md="6">
                  <div className="row">
                    <Label className="col-md-4 col-form-label font-weight-bold text-center">LAC</Label>
                    <FormGroup className="col-md-8">
                      <Input
                        readOnly
                        className="__textAlignCenter"
                        type="text"
                        name="lac"
                        value={status.detailStatus.lac}
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col md="6">
                  <div className="row">
                    <Label className="col-md-4 col-form-label font-weight-bold text-center">CELL ID</Label>
                    <FormGroup className="col-md-8">
                      <Input
                        readOnly
                        className="__textAlignCenter"
                        type="text"
                        name="cellId"
                        value={status.detailStatus.cellId}
                      />
                    </FormGroup>
                  </div>
                </Col>
                {getUserType().slice(2, 4) !== 'RO' && (
                  <Col md="6">
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <Button
                        className="btn-round __marginTop"
                        color="primary"
                        onClick={this.handleRegisterButtonClick}
                      >
                        기지국등록
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col md="12">
                  <div className="row">
                    <Label
                      className="col-md-2 col-form-label font-weight-bold text-center"
                      style={{ alignSelf: 'center' }}
                    >
                      WIFIS
                    </Label>
                    <FormGroup className="col-md-8">
                      <Input readOnly type="textarea" id="detailWifis" name="wifis" value={status.detailStatus.wifis} />
                    </FormGroup>
                    <div className="col-md-2" style={{ alignSelf: 'center' }}>
                      <Button
                        className="btn-round __marginTop"
                        color="primary"
                        onClick={(e) => {
                          let mousePosition = { mouseX: e.clientX, mouseY: e.clientY };
                          handleCopyClick(toastAlert, 'detailWifis', mousePosition);
                        }}
                      >
                        복사
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="row">
                    <Label
                      className="col-md-2 col-form-label font-weight-bold text-center"
                      style={{ alignSelf: 'center' }}
                    >
                      BLES
                    </Label>
                    <FormGroup className="col-md-8">
                      <Input readOnly type="textarea" id="detailBles" name="bles" value={status.detailStatus.bles} />
                    </FormGroup>
                    <div className="col-md-2" style={{ alignSelf: 'center' }}>
                      <Button
                        style={{ alignSelf: 'center' }}
                        className="btn-round __marginTop"
                        color="primary"
                        onClick={(e) => {
                          let mousePosition = { mouseX: e.clientX, mouseY: e.clientY };
                          handleCopyClick(toastAlert, 'detailBles', mousePosition);
                        }}
                      >
                        복사
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="row">
                    <Label
                      className="col-md-2 col-form-label font-weight-bold text-center"
                      style={{ alignSelf: 'center' }}
                    >
                      NFCS
                    </Label>
                    <FormGroup className="col-md-8">
                      <Input readOnly type="textarea" id="detailNfcs" name="nfcs" value={status.detailStatus.nfcs} />
                    </FormGroup>
                    <div className="col-md-2" style={{ alignSelf: 'center' }}>
                      <Button
                        style={{ alignSelf: 'center' }}
                        className="btn-round __marginTop"
                        color="primary"
                        onClick={(e) => {
                          let mousePosition = { mouseX: e.clientX, mouseY: e.clientY };
                          handleCopyClick(toastAlert, 'detailNfcs', mousePosition);
                        }}
                      >
                        복사
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : null}
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}
            >
              수정
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
              닫기
            </Button>
          </div>
        </div>
      </Modal>

      {/* 등록 모달 */}
      {status.detailModalRegisterCell && <DetailModalRegisterCellTower {...this.props} />}

        {/* 토스트 메시지 팝업 */}
        {toastAlert.isOpen && <ToastAlert />}
      </>
    );
  }
}

DetailStatusModal.defaultProps = {
  fetchRefresh: async () => {
    Logger.log('[DetailStatusModal] - refresh function is undefined');
  }
};

export default DetailStatusModal;
