import { observable, action } from 'mobx';

// 대시보드
export class MobxStore {
  constructor() {
    this.data = {
      authFailCount: '',
      authSuccessCount: '',
      deviceTotalCount: '',
      deviceUnregisterCount: '',
      authTotalCount: ''
    };
  }

  @observable data; // 대시보드 데이터

  @action set = (key, value) => {
    this[key] = value;
  };
}
