import { observable, action } from 'mobx';

// 위치인증 Ap 관리
export class MobxStore {
  constructor() {
    this.data = null;
    this.registerUser = {
      loginId: '',
      password: '',
      name: '',
      email: '',
      phone: '',
      ipAddress: ''
    };
    this.editUser = {
      id: '',
      loginId: '',
      password: '',
      name: '',
      email: '',
      phone: '',
      ipAddress: ''
    };
    this.registerModalIsOpen = false;
    this.modifyModalIsOpen = false;
    this.whatIsMyIp = '';
  }

  @observable data; // 관리자 데이터
  @observable registerUser; // 관리자 등록
  @observable editUser; // 관리자 수정
  @observable registerModalIsOpen; // 등록 모달 토글
  @observable modifyModalIsOpen; // 수정 모달 토글
  @observable whatIsMyIp; // 현재 내 IP 주소

  // 등록 데이터 변경
  @action setRegisterUser = (key, value) => {
    this.registerUser[key] = value;
  };

  // 등록 데이터 제거
  @action deleteRegisterUser = index => {
    this.registerUser.splice(index, 1);
  };

  // 등록 데이터셋 초기화
  @action clearRegisterUser = () => {
    this.registerUser = {
      loginId: '',
      name: '',
      email: '',
      phone: '',
      ipAddress: ''
    };
  };

  // 수정 데이터셋 초기화
  @action clearModifyUser = () => {
    this.editUser = {
      id: '',
      loginId: '',
      name: '',
      email: '',
      phone: '',
      ipAddress: ''
    };
  };

  // 수정 데이터 변경
  @action setEditUser = (objName, key, value, newObj) => {
    if (newObj !== undefined && newObj !== null) {
      this[objName] = newObj;
    } else {
      this[objName] = {
        ...this[objName],
        [key]: value
      };
    }
  };

  @action set = (key, value) => {
    this[key] = value;
  };
}
