/*eslint-disable*/
import React from 'react';
import { Container, Row } from 'reactstrap';

class MainFooter extends React.Component {
  render() {
    return (
      <>
        <div
          style={{
            backgroundColor: 'rgba(244,248,249,1)',
            height: '63px'
          }}/>
        <footer className={'__footerBackground  footer' + (this.props.default ? ' footer-default ' : '')}>
          <Container fluid={this.props.fluid ? true : false}>
            <Row className="text-light">
              <div>Seoul Fintech Lab, 21, Baekbeom-ro 31-gil, Mapo-gu, Seoul, Republic of Korea</div>
              <div className="credits ml-auto">
                <span className="copyright">COPYRIGHT © 2019 BY L Fin, LTD. ALL RIGHTS RESERVED.</span>
              </div>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default MainFooter;
