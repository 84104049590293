import React, { Fragment } from 'react';
import Select from 'react-select';
import { Button, Modal, Row, Col, Table, FormGroup, Input } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

import { requestPost } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';


// 등록 디바이스 등록 모달
@inject('cell', 'searchFilter', 'miniAlertModal', 'loader')
@observer
class RegisterCellTower extends React.Component {
  toggleModal = () => {
    const { cell } = this.props;
    cell.set('registerModalIsOpen', !cell.registerModalIsOpen);
  };

  // 등록 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    const { cell, miniAlertModal } = this.props;
    // 등록할 디바이스 개수 체크
    if(cell.registerCells.length < 1) {
      miniAlertModal.show('등록할 기지국이 없습니다.');
      return false;
    }
    // 빈 데이터가 있는지 체크
    const result = toJS(cell.registerCells).map((data) => {
      if (data.department === '' || data.telecom === '' || data.lac === '' || data.cellId === '') {
        miniAlertModal.show('모든 값을 입력해주세요.');
        return false;
      }
      return true;
    });

    if (!result.includes(false)) {
      this.fetchRegisterCellTowers();
    }
  };

  // 등록 API 호출
  fetchRegisterCellTowers = async () => {
    const { miniAlertModal, loader, searchFilter, cell, fetchRefresh } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/cells';
    const body = {
      cells: toJS(cell.registerCells).map(data => {
        return {
          companyId: searchFilter.selectCompany.value,
          departmentId: data.department.value,
          telecom: data.telecom.value,
          lac: data.lac,
          cellId: data.cellId
        };
      })
    };
    const responseData = await requestPost(url, body);
    const {registCellCount, dupCellCount, skipCellCount} = responseData.data;
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      cell.clearRegisterCells();

      await searchFilter.set('page', 1);
      fetchRefresh();
      miniAlertModal.show(`처리되었습니다. 
      
      성공: ${registCellCount}건, 중복: ${dupCellCount}건`);
    } else {
      miniAlertModal.show(responseData.message);
    }

    // 지점 없거나 해당 지점 권한 없음에 대한 message
    if(responseData.code === '1001') {
      miniAlertModal.show(responseData.message);
    }
  };

  renderTableRow = () => {
    const { cell, searchFilter } = this.props;
    const cells = toJS(cell.registerCells).map((row, key) => {
      return (
        <Fragment key={key}>
          <tr>
            <td>
              <FormGroup>
                <Select
                  className="react-select __tableInputSelect __tableInputSelectColor"
                  classNamePrefix="react-select"
                  name="registerDepartment"
                  value={row.department}
                  onChange={e => {
                     cell.setRegisterCells(key, 'department', e);
                  }}
                  placeholder="지점을 선택하세요."
                  options={searchFilter.departmentList.length > 1 ? searchFilter.departmentList.slice(1) : []}
                />
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <Select
                  className="react-select __tableInputSelect __tableInputSelectColor"
                  classNamePrefix="react-select"
                  name="registerTelecom"
                  value={row.telecom}
                  onChange={e => {
                    cell.setRegisterCells(key, 'telecom', e);
                  }}
                  placeholder="통신사"
                  options={[
                    { label: 'SKT', value: 'SKT' },
                    { label: 'KT', value: 'KT' },
                    { label: 'LGT', value: 'LGT' }
                  ]}
                />
              </FormGroup>
            </td>
            <td className="text-center">
              <div className="__inline-div __tableInputSelect">
                <FormGroup>
                  <Input
                    className="__textAlignCenter __inline-input"
                    type="text"
                    name="registerLAC"
                    value={row.lac}
                    onChange={e => {
                      cell.setRegisterCells(key, 'lac', e.target.value);
                    }}
                  />
                </FormGroup>
              </div>
            </td>
            <td>
              <div className="__tableInputSelect">
                <FormGroup>
                  <Input
                    className="__textAlignCenter"
                    type="text"
                    name="registerCellId"
                    value={row.cellId}
                    maxLength={50}
                    onChange={e => {
                      cell.setRegisterCells(key, 'cellId', e.target.value);
                    }}
                  />
                </FormGroup>
              </div>
            </td>
            <td className="text-center">
              <Button
                className="btn-round btn-icon"
                color="google"
                onClick={() => {
                  cell.deleteRegisterCells(key);
                }}>
                <i className="nc-icon nc-simple-delete" />
              </Button>
            </td>
          </tr>
        </Fragment>
      );
    });
    return cells;
  };

  render() {
    const { cell, miniAlertModal } = this.props;
    return (
      <Modal className="modal-lg" backdrop={'static'} isOpen={cell.registerModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove"/>
          </button>
          <h4 className="title title-up">기지국 등록</h4>
        </div>
        <div className="modal-body __paddingModalBody">
          <Row>
            <Col md="12">
              <Table bordered>
                <thead>
                <tr className="text-center">
                  <th width="25%">지점 *</th>
                  <th width="20%">통신사 *</th>
                  <th width="20%">LAC *</th>
                  <th width="25%">CELLID *</th>
                  <th width="10%"/>
                </tr>
                </thead>
                <tbody>{this.renderTableRow()}</tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="text-center">
              <Button
                className="btn-round btn-icon"
                color="success"
                onClick={async () => {
                  if (toJS(cell.registerCells).length < 10) {
                    cell.addRegisterCells();
                  } else {
                    miniAlertModal.show('더 이상 추가할 수 없습니다.');
                  }
                }}>
                <i className="nc-icon nc-simple-add" />
              </Button>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}>
              저장
            </Button>
          </div>
          <div className="divider"/>
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
              닫기
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

RegisterCellTower.defaultProps = {
  fetchRefresh: async () => {
    console.log('[RegisterCellTower] - refresh function is undefined');
  }
};

export default RegisterCellTower;
