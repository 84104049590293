import React from 'react';
import Select from 'react-select';
import Loader from 'views/components/loader/Loader';
import buildUrl from 'build-url';
import Pagination from 'views/components/pagination/CustomPagination';
import { Button, Card, CardBody, CardFooter, Label, FormGroup, Form, Input, Row, Col } from 'reactstrap';
import { requestGet, requestDelete, requestPut } from 'utils/fetchHelper';
import { autoPhoneFormatter } from 'utils/fomatter';

import { AUTH_TABLE_HEADER } from 'commons/conts/tableHeader';
import Table from 'views/components/table/checkbox/table';

import RegisterAuthDeviceModal from './Register';
import ModifyAuthDeviceModal from './Modify';
import FileUploadAuthDeviceModal from './FileUpload';

import ConfirmAlert from 'views/components/alert/confirmAlert/ConfirmAlert';
import MiniAlert from 'views/components/alert/miniAlert/MiniAlert';
import ToastAlert from 'views/components/alert/toastAlert/ToastAlert';

import CompanyFilter from 'views/components/searchFilter/CompanyFilter';
import DepartmentFilter from 'views/components/searchFilter/DepartmentFilter';

import { isValidResponse } from 'utils/responseValidChecker';

import { toJS } from 'mobx';
import { observer, inject } from 'mobx-react';

import { saveAs } from 'file-saver';
import TotalCount from 'views/components/common/totalCount/TotalCount';
import { getUserType } from 'utils/tokenHelper';
import moment from 'moment';
import ReactDatetime from 'react-datetime';

@inject('searchFilter', 'auth', 'confirmAlertModal', 'miniAlertModal', 'toastAlert', 'loader', 'table')
@observer
class AuthDevice extends React.Component {
  radioAll = '';

  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.radioAll.checked = true;
    this.props.searchFilter.companyList.length === 0 && (await this.props.searchFilter.getCompanyList());
    await this.fetchRefresh();
  }

  componentWillUnmount() {
    const { searchFilter, auth, table } = this.props;
    table.set('selectedData', []);
    auth.set('data', null);
    searchFilter.clear();
  }

  // 등록 디바이스 조회 API 호출
  fetchRefresh = async () => {
    const { searchFilter, miniAlertModal, loader, auth, table } = this.props;
    loader.show(true);

    let queryParams = {};
    queryParams.companyId = searchFilter.selectCompany.value;
    searchFilter.selectDepartment.value !== '' && (queryParams.departmentId = searchFilter.selectDepartment.value);
    searchFilter.phone !== '' && (queryParams.phone = searchFilter.phone);
    searchFilter.useYn = 'Y';
    searchFilter.adidStatus !== '' && (queryParams.adid = searchFilter.adidStatus);
    searchFilter.unUsedTime !== '' && (queryParams.unUsedTime = searchFilter.unUsedTime);
    queryParams.page = searchFilter.page !== '' ? searchFilter.page : 1;
    queryParams.size = searchFilter.pageSize.value !== '' ? searchFilter.pageSize.value : 10;

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/v1/admin/api/devices',
      queryParams: queryParams
    });

    const responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    const editedResponseData = await this.addCustomColumn(responseData); // 지점명 + 지점ID 처리
    // 중복 숫자 1 이하일 경우 표시하지 않음
    editedResponseData.data.content.forEach((v) => {
      v.duplicatedCount = `+${v.duplicatedCount}`;
      if (v.duplicatedCount <= 1) {
        v.duplicatedCount = '';
      }
    });

    auth.set('data', editedResponseData.data);
  };

  // 지점명(지점 ID) 컬럼 추가
  addCustomColumn = async response => {
    const editedContent = response.data.content.map(item => {
      return {
        ...item,
        customDepartmentInfo: item.departmentName + ' (' + item.departmentId + ')'
      };
    });
    response.data.content = editedContent;
    return response;
  };

  // 데이터 테이블 클릭 - 등록 디바이스 수정 모달
  handleEditRowClick = async data => {
    if (getUserType().slice(2, 4) === 'RO') {
      return;
    }
    const { auth } = this.props;
    auth.setEditDevices('editDevices', null, null, {
      deviceId: data.deviceId,
      department: { value: data.departmentId, label: data.departmentName },
      telecom: { value: data.telecom, label: data.telecom },
      phone: data.phone,
      adid: data.adid !== null ? data.adid : ''
    });

    auth.set('modifyModalIsOpen', !auth.modifyModalIsOpen);
  };

  // 페이징 컴포넌트 콜백 함수
  handlePaginationCallback = async page => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', page);
    await this.fetchRefresh();
  };

  // 등록 버튼 클릭 이벤트 처리
  handleRegisterButtonClick = async () => {
    const { auth } = this.props;
    await auth.clearRegisterDevices();
    auth.set('registerModalIsOpen', !auth.registerModalIsOpen);
  };

  // 리셋 버튼 클릭 이벤트 처리
  handleResetButtonClick = async () => {
    const { miniAlertModal, confirmAlertModal, table } = this.props;
    if (toJS(table.selectedData.length) === 0) {
      miniAlertModal.show('리셋할 디바이스를 선택해주세요.');
    } else {
      confirmAlertModal.show(
        '기기식별자를 리셋합니다.\n',
        '리셋된 기기식별자는 복원할 수 없습니다.\n그래도 리셋 하시겠습니까?',
        '',
        this.fetchResetDevice
      );
    }
  };

  // 기기식별자 리셋 API 호출
  fetchResetDevice = async () => {
    const { miniAlertModal, loader, table, searchFilter } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/devices/reset';
    const deviceIds = toJS(table.selectedData).map(device => {
      return device.deviceId;
    });
    const body = {
      deviceIds: deviceIds
    };
    const responseData = await requestPut(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      miniAlertModal.show('기기식별자가 리셋되었습니다.');
      await searchFilter.set('page', 1);
      this.fetchRefresh();
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  // 삭제 버튼 클릭 이벤트 처리
  handleDeleteButtonClick = async () => {
    const { miniAlertModal, confirmAlertModal, table } = this.props;
    if (toJS(table.selectedData).length === 0) {
      miniAlertModal.show('삭제할 디바이스를 선택해주세요.');
    } else {
      confirmAlertModal.show(
        '선택한 데이터를 삭제합니다.\n',
        '삭제된 데이터는 복원할 수 없습니다.\n그래도 삭제 하시겠습니까?',
        '',
        this.fetchDeleteDevice
      );
    }
  };

  // 등록 디바이스 삭제 API 호출
  fetchDeleteDevice = async () => {
    const { miniAlertModal, loader, table, searchFilter } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/devices';
    const body = {
      devices: table.selectedData.map(device => {
        return {
          deviceId: device.deviceId
        };
      })
    };
    let responseData = await requestDelete(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      miniAlertModal.show('삭제되었습니다.');
      await searchFilter.set('page', 1);
      this.fetchRefresh();
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  // 다운로드 버튼 클릭 이벤트 처리
  handleDownloadButtonClick = () => {
    this.fetchRegisterDevicesDownload();
  };

  // 등록 디바이스 다운로드 API 처리
  fetchRegisterDevicesDownload = async () => {
    const { loader, searchFilter, miniAlertModal } = this.props;
    loader.show(true);

    let queryParams = {};
    queryParams.companyId = searchFilter.selectCompany.value;
    searchFilter.selectDepartment.value !== '' && (queryParams.departmentId = searchFilter.selectDepartment.value);
    searchFilter.phone !== '' && (queryParams.phone = searchFilter.phone);
    queryParams.useYn = 'Y';
    queryParams.page = searchFilter.page !== '' ? searchFilter.page : 1;
    queryParams.size = searchFilter.pageSize.value !== '' ? searchFilter.pageSize.value : 10;

    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/v1/admin/api/devices/excel',
      queryParams: queryParams
    });

    const responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);
    if (process.env.REACT_APP_FILE_SYSTEM === 'FILE') {
      const byteCharacters = atob(responseData.data.fileData);

      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);

      saveAs(blob, responseData.data.fileName);
    } else {
      if (responseData.code === '0000') {
        responseData.data.map(data => {
          saveAs(
            data.excelUrl,
            data.excelUrl.substring(data.excelUrl.lastIndexOf('/') + 1, data.excelUrl.lastIndexOf('_'))
          );
          return null;
        });
      }
    }

    if (responseData.code !== '0000') {
      miniAlertModal.show(responseData.message);
    }
  };

  // 검색 조건 엔터키 입력 이벤트 처리
  handleEnterKeyPress = async e => {
    const { searchFilter } = this.props;
    if (e.key === 'Enter') {
      await searchFilter.set('page', 1);
      this.fetchRefresh();
    }
  };

  // 식별자 유무 라디오 버튼 클릭 이벤트 처리
  handleRadioChange = async e => {
    const { searchFilter } = this.props;
    const adidStatus = e.target.value;
    await searchFilter.set('page', 1);
    await searchFilter.set('adidStatus', adidStatus);
    await this.fetchRefresh();
  };

  // 검색 버튼 클릭 이벤트 처리
  handleSearchClick = async () => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    this.fetchRefresh();
  };

  // 피일 업로드 버튼 클릭 이벤트 처리
  handleFileUploadButtonClick = () => {
    const { auth } = this.props;
    auth.set('fileUploadModalIsOpen', !auth.fileUploadModalIsOpen);
  };

  // 페이지 당 검색 결과 수 변경 이벤트 함수
  handlePageSizeChanged = async e => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    await searchFilter.setPageSize(e);
    await this.fetchRefresh();
  };

  render() {
    let { confirmAlertModal, miniAlertModal, loader, auth, searchFilter, toastAlert } = this.props;
    return (
      <>
        {loader.showLoader && <Loader></Loader>}
        <div className="content">
          <h4>등록 디바이스 관리</h4>
          <h6>등록된 디바이스의 정보를 확인하실 수 있습니다.</h6>
          <Card className="card-stats">
            <CardBody className="__padding">
              <Form className="form-horizontal">
                <Row md="12">
                  <Col md={{ size: 10, offset: 1 }} className="text-center">
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        회사명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <CompanyFilter refreshData={this.fetchRefresh}></CompanyFilter>
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        지점명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <DepartmentFilter refreshData={this.fetchRefresh}></DepartmentFilter>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        미사용 기준일
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <ReactDatetime
                              inputProps={{
                                className: 'form-control datetime-custom',
                                placeholder: moment(this.props.searchFilter.unUsedTime, 'YYYY-MM-DD HH:mm:ss').format(
                                    'YYYY-MM-DD HH:mm'
                                ),
                                value: moment(this.props.searchFilter.unUsedTime, 'YYYY-MM-DD HH:mm:ss').format(
                                    'YYYY-MM-DD HH:mm'
                                ),
                                readOnly: true
                              }}
                              dateFormat='YYYYMMDD'
                              timeFormat='HH:mm'
                              onChange={e => {
                                this.props.searchFilter.set('unUsedTime', e);
                              }}
                              locale='ko'
                              // timeFormat={true}
                              // input={true}
                          />
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        전화번호
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <Input
                            className="__textAlignCenter"
                            type="text"
                            value={autoPhoneFormatter(this.props.searchFilter.phone)}
                            onChange={e => {
                              this.props.searchFilter.set('phone', e.target.value.replace(/[^0-9]/g, ''));
                            }}
                            onKeyPress={this.handleEnterKeyPress}
                            maxLength={13}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="12">
                        <div className="form-check-radio form-check-inline">
                          <Label className="form-check-label">
                            <Input
                              innerRef={ref => (this.radioAll = ref)}
                              type="radio"
                              name="adid"
                              onChange={this.handleRadioChange}
                              value=""
                            />
                            전체 조회
                            <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div className="form-check-radio form-check-inline">
                          <Label className="form-check-label">
                            <Input type="radio" name="adid" onChange={this.handleRadioChange} value="exist" />
                            기기식별자 있음
                            <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div className="form-check-radio form-check-inline">
                          <Label className="form-check-label">
                            <Input type="radio" name="adid" onChange={this.handleRadioChange} value="empty" />
                            기기식별자 없음
                            <span className="form-check-sign" />
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="12">
                        <Button className="btn-round" color="primary" onClick={this.handleSearchClick}>
                          조회
                        </Button>
                        {getUserType().slice(2, 4) !== 'RO' && (
                          <Button className="btn-round" onClick={this.handleFileUploadButtonClick} color="primary">
                            파일 업로드
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="form-horizontal __marginBottom5">
                <Col md="12" className="text-right">
                  <div className="__inline-div">
                    <Select
                      className="react-select __pageSelect"
                      classNamePrefix="react-select"
                      name="pageSize"
                      placeholder=""
                      value={searchFilter.pageSize}
                      options={searchFilter.pageSizeList}
                      onChange={this.handlePageSizeChanged}
                    />
                    <Label className="label-page">페이지 사이즈</Label>
                    <TotalCount totalElements={auth.data && auth.data.totalElements} />
                  </div>
                </Col>
              </Row>
              <Row className="form-horizontal">
                <Col md="12">
                  <Table header={AUTH_TABLE_HEADER} data={auth.data} handleRowClick={this.handleEditRowClick} />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={{ offset: 3, size: 6 }}>
                  {auth.data && (
                    <Pagination
                      currentPage={searchFilter.page}
                      totalPages={auth.data.totalPages}
                      pageSize={auth.data.pageable.pageSize}
                      callback={this.handlePaginationCallback}
                    />
                  )}
                </Col>
              </Row>
              {getUserType().slice(2, 4) !== 'RO' ? (
                <Row>
                  <Col lg="12" xl="12" className="text-right">
                    <div className="__inline-div __button-margin">
                      <Button
                        className="btn-round __marginTop"
                        onClick={this.handleRegisterButtonClick}
                        color="primary"
                      >
                        등록
                      </Button>
                    </div>
                    <div className="__inline-div __button-margin">
                      <Button
                        className="btn-round __marginTop __button-margin"
                        onClick={this.handleDeleteButtonClick}
                        color="primary"
                      >
                        삭제
                      </Button>
                    </div>
                    <div className="__inline-div __button-margin">
                      <Button className="btn-round __marginTop" onClick={this.handleResetButtonClick} color="primary">
                        리셋
                      </Button>
                    </div>
                    <div className="__inline-div __button-margin">
                      <Button
                        className="btn-round __marginTop"
                        onClick={this.handleDownloadButtonClick}
                        color="primary"
                      >
                        다운로드
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg="12" xl="12" className="text-right">
                    <div className="__inline-div __button-margin">
                      <Button
                        className="btn-round __marginTop"
                        onClick={this.handleDownloadButtonClick}
                        color="primary"
                      >
                        다운로드
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </CardFooter>
          </Card>

          {/* 등록 모달 */}
          {auth.registerModalIsOpen && <RegisterAuthDeviceModal {...this.props} fetchRefresh={this.fetchRefresh} />}

          {/* 수정 모달 */}
          {auth.modifyModalIsOpen && <ModifyAuthDeviceModal {...this.props} fetchRefresh={this.fetchRefresh} />}

          {/* 파일 업로드 모달 */}
          {auth.fileUploadModalIsOpen && <FileUploadAuthDeviceModal {...this.props} fetchRefresh={this.fetchRefresh} />}

          {/* 알림 팝업 */}
          {miniAlertModal.isOpen && <MiniAlert />}

          {/* 사용자 확인 알림 모달 */}
          {confirmAlertModal.isOpen && <ConfirmAlert />}

          {/* 토스트 메시지 팝업 */}
          {toastAlert.isOpen && <ToastAlert />}
        </div>
      </>
    );
  }
}

export default AuthDevice;
