import React, { Fragment } from 'react';
import Select from 'react-select';
import { Button, Modal, Row, Col, FormGroup, Input, Table } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { requestPut } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';

// 등록 기지국 수정 모달
@inject('cell', 'searchFilter', 'miniAlertModal', 'loader')
@observer
class ModifyCellTowerModal extends React.Component {
  toggleModal = () => {
    const { cell } = this.props;
    cell.set('modifyModalIsOpen', !cell.modifyModalIsOpen);
  };

  // 수정 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    const { cell, miniAlertModal } = this.props;
    if (cell.editCells.department === '' ||
        cell.editCells.telecom === '' ||
        cell.editCells.lac === '' ||
        cell.editCells.cellId === ''
    ) {
      miniAlertModal.show('모든 값을 입력해주세요.');
      return false;
    }
    this.fetchModifyAuthCells();
  };

  // 등록 기지국 수정 API 호출
  fetchModifyAuthCells = async () => {
    const { miniAlertModal, loader, cell, searchFilter, fetchRefresh } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/cells';
    const body = {
      cells: [
        {
          companyId: searchFilter.selectCompany.value,
          departmentId: cell.editCells.department.value,
          id: cell.editCells.id,
          telecom: cell.editCells.telecom.value,
          lac: cell.editCells.lac,
          cellId: cell.editCells.cellId,
          useYn: 'Y'
        }
      ]
    };
    const responseData = await requestPut(url, body);
    const {registCellCount, dupCellCount, skipCellCount} = responseData.data;
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      cell.set('editCells', {
        id: '',
        department: '',
        telecom: '',
        lac: '',
        cellId: ''
      });

      await searchFilter.set('page', 1);
      fetchRefresh();
      miniAlertModal.show(`처리되었습니다. 
      
      성공: ${registCellCount}건, 중복: ${dupCellCount}건, SKIP: ${skipCellCount}건`);
    } else {
      miniAlertModal.show(responseData.message);
    }

    // 데이터가 존재하지 않는다는 message
    if (responseData.code === '0002') {
      miniAlertModal.show(responseData.message);
    }
  };

  renderTableRow = () => {
    let { cell, searchFilter } = this.props;
    return (
      <Fragment>
        <tr>
          <td>
            <FormGroup>
              <Select
                className="react-select __tableInputSelect __tableInputSelectColor"
                classNamePrefix="react-select"
                name="editDepartment"
                value={cell.editCells.department}
                onChange={data => {
                  cell.setEditCells('editCells', 'department', data, null);
                }}
                placeholder="지점을 선택하세요."
                options={searchFilter.departmentList.length > 1 ? searchFilter.departmentList.slice(1) : []}
              />
            </FormGroup>
          </td>
          <td>
            <FormGroup>
              <Select
                className="react-select __tableInputSelect __tableInputSelectColor"
                classNamePrefix="react-select"
                name="editTelecom"
                value={cell.editCells.telecom}
                onChange={data => {
                  cell.setEditCells('editCells', 'telecom', data, null);
                }}
                placeholder="통신사"
                options={[
                  { label: 'SKT', value: 'SKT' },
                  { label: 'KT', value: 'KT' },
                  { label: 'LGT', value: 'LGT' }
                ]}
              />
            </FormGroup>
          </td>
          <td className="text-center">
            <div className="__inline-div __tableInputSelect">
              <FormGroup>
                <Input
                  className="__textAlignCenter __inline-input"
                  type="text"
                  name="editlac"
                  value={cell.editCells.lac}
                  onChange={e => {
                    cell.setEditCells('editCells', 'lac', e.target.value, null);
                  }}
                />
              </FormGroup>
            </div>
          </td>
          <td>
            <div className="__tableInputSelect">
              <FormGroup>
                <Input
                  className="__textAlignCenter"
                  type="text"
                  name="editCellId"
                  value={cell.editCells.cellId}
                  onChange={e => {
                    cell.setEditCells('editCells', 'cellId', e.target.value);
                  }}
                />
              </FormGroup>
            </div>
          </td>
        </tr>
      </Fragment>
    );
  };

  render() {
    const { cell } = this.props;
    return (
      <Modal className="modal-lg" backdrop={'static'} isOpen={cell.modifyModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">기지국 수정</h4>
        </div>
        <div className="modal-body __paddingModalBody">
          <Row>
            <Col md="12">
              <Table bordered>
                <thead>
                <tr className="text-center">
                  <th width="25%">지점 *</th>
                  <th width="20%">통신사 *</th>
                  <th width="20%">LAC *</th>
                  <th width="25%">CELLID *</th>
                </tr>
                </thead>
                <tbody>{this.renderTableRow()}</tbody>
              </Table>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}>
              수정
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
              닫기
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

ModifyCellTowerModal.defaultProps = {
  fetchRefresh: async () => {
    console.log('[ModifyCellTowerModal] - refresh function is undefined');
  }
};

export default ModifyCellTowerModal;
