import React from 'react';
import { Button, Modal, Row, Col, CardBody, Card, Table as RSTable, CardFooter } from 'reactstrap';
import { observer } from 'mobx-react';
import Logger from 'utils/logger';
import moment from 'moment';

// 인증 현황 상세 모달
@observer
class DetailAuthSummaryModal extends React.Component {
  toggleModal = () => {
    const { authSummary } = this.props;
    authSummary.clearDetailAuthSummary();
    authSummary.set('detailModalIsOpen', !authSummary.detailModalIsOpen);
  };

  render() {
    const { authSummary, searchFilter } = this.props;
    return (
      <Modal className="modal-lg" backdrop={'static'} isOpen={authSummary.detailModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h5 className="title title-up">
            <span style={{ color: '#35a6ff' }}>{authSummary.departmentName}</span> 지점 날짜별 인증현황 상세 보기
          </h5>
          <h6>기간: {moment(searchFilter.startDate).format('YYYY/MM/DD')} - {moment(searchFilter.endDate).format('YYYY/MM/DD')}</h6>
        </div>
        <div className="modal-body  __modalScrollbar">
          <Card>
            <CardBody>
              <Row className="form-horizontal __marginBottom5">
                <Col md="12" className="text-right"></Col>
              </Row>
              <Row className="form-horizontal">
                <Col md="12">

                  <RSTable bordered striped>
                    <thead>
                      <tr className="table-header text-center underline-dategroup-tr">
                        <th width="8%">
                          <span style={{ whiteSpace: 'pre-line' }} >순번</span>
                        </th>
                        <th width="20%">
                          <span style={{ whiteSpace: 'pre-line' }}>날짜</span>
                        </th>
                        <th width="18%">
                          <span style={{ whiteSpace: 'pre-line' }}>인증실패율</span>
                        </th>
                        <th width="18%">
                          <span style={{ whiteSpace: 'pre-line' }}>인증시도건수</span>
                        </th>
                        <th width="18%">
                          <span style={{ whiteSpace: 'pre-line' }}>인증실패건수</span>
                        </th>
                        <th width="18%">
                          <span style={{ whiteSpace: 'pre-line' }}>서버에러건수</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tbody-fontsize-sm">
                      {authSummary.detailAuthSummary !== null ? this.renderTable(authSummary.detailAuthSummary) : null}
                    </tbody>
                  </RSTable>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col lg="12" xl="12" className="text-left" style={{ fontSize: '14px', marginTop: '5px' }}>
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {`
                      - 날짜순으로 조회됩니다. 단, 인증이력이 한 건도 발생하지 않은 날짜는 검색에서 제외됩니다.
                      - 실패율(%) = 실패단말수 / 시도단말수 * 100 (실패율이 80%이상인 경우, 붉은색으로 표시됩니다.)
                    `}
                  </span>
                </Col>
              </Row>
            </CardFooter>
          </Card>

        </div>
        <div className="modal-footer">
          <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
            닫기
          </Button>
        </div>
      </Modal>
    );
  }

  renderTable = data => {
    const rowData = data.map((row, index) => {
      let failRateTextColor = '#35a6ff';
      let countTextColor = '';

      const failRate = row.failRate.toFixed(2);

      if (failRate >= 80) {
        failRateTextColor = '#FF9C2A';
        countTextColor = failRateTextColor;
      }
      if (failRate >= 90) {
        failRateTextColor = '#FF803E';
        countTextColor = failRateTextColor;
      }
      if (failRate >= 100) {
        failRateTextColor = '#FF5F49';
        countTextColor = failRateTextColor;
      }

      return (
        <tr
          key={index}
        >
          <td className="text-center">{(index + 1).toString()}</td>
          <td className="text-center" style={{ fontWeight: 'bold' }}>{row.date}</td>
          <td className="text-right" style={{ fontWeight: 'bold', color: failRateTextColor }}>{failRate}</td>
          <td className="text-right" style={{ color: countTextColor }}>{row.authTotCount}</td>
          <td className="text-right" style={{ color: countTextColor }}>{row.authFailCount}</td>
          <td className="text-right" >{row.serverErrorCount}</td>
        </tr>
      );
    });

    return rowData;
  };
}


DetailAuthSummaryModal.defaultProps = {
  fetchRefresh: async () => {
    Logger.log('[DetailStatusModal] - refresh function is undefined');
  }
};

export default DetailAuthSummaryModal;
