import React from 'react';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import Logger from 'utils/logger';
import { toJS } from 'mobx';

@inject('searchFilter')
@observer
class TelecomFilter extends React.Component {
  handleSelectChange = async e => {
    const { searchFilter, refreshData } = this.props;
    searchFilter.set('selectTelecom', {
      label: e.label,
      value: e.value
    });
    await searchFilter.set('page', 1);
    refreshData && (await refreshData());
  };

  render() {
    const { searchFilter } = this.props;
    return (
      <FormGroup>
        <Select
          className="react-select text-left"
          classNamePrefix="react-select"
          name="selectTelecom"
          value={searchFilter.selectTelecom}
          onChange={this.handleSelectChange}
          options={searchFilter.telecomList}
          placeholder="통신사"
        />
      </FormGroup>
    );
  }
}

TelecomFilter.defaultProps = {
  refreshData: async () => {
    Logger.log('[TelecomFilter] - refresh function is undefined');
  }
};

export default TelecomFilter;
