import React, { Component } from 'react';
import Loader from 'views/components/loader/Loader';
import { Button, Card, CardBody, CardTitle, FormGroup, FormText, Input, Label, Row, Col, Container } from 'reactstrap';
import { requestGet, requestPost, requestPut } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';
import { autoPhoneFormatter } from 'utils/fomatter';

import { toJS } from 'mobx';
import { observer, inject } from 'mobx-react';

import MiniAlert from 'views/components/alert/miniAlert/MiniAlert';
import { Prompt } from 'react-router-dom';

@inject('userInfo', 'loader', 'miniAlertModal')
@observer
class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertOpen: false,
      promptOpen: false
    };
  }

  componentDidMount() {
    this.fetchGetUserInfo();
    const { userInfo } = this.props;
    // PLAC-641 resetOption이 'MUST'일 경우 Prompt 사용하여 페이지 이동 막음
    if(userInfo.resetOption === 'MUST') {
      this.setState({ promptOpen: true });
    }
  }

  // 'url 직접 변경 후 접근' 막기 위한 코드
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { userInfo } = this.props;
    if(userInfo.resetOption === 'MUST') {
      if (document.location.hash.indexOf('/userinfo') < 0) {
        document.location.hash = '#/userinfo/true';
      }
    }
  }

  componentWillUnmount() {
    const { userInfo } = this.props;
    if(userInfo.resetOption === 'MUST') {
      userInfo.set('editStatus', 'changePassword');
      return;
    }
    userInfo.set('editStatus', 'checkPassword');
  }

  // 유저 정보 조회 API 호출
  fetchGetUserInfo = async () => {
    const { loader, miniAlertModal, userInfo } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/users/myprofile';
    const responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code !== '0000') {
      miniAlertModal.show(responseData.message);
    } else {
      userInfo.set('data', {
        companyName: responseData.data.companyName !== null ? responseData.data.companyName : '',
        id: responseData.data.username !== null ? responseData.data.username : '',
        password: '',
        confirmPassword: '',
        name: responseData.data.name !== null ? responseData.data.name : '',
        tel: responseData.data.tel !== null ? responseData.data.tel : '',
        email: responseData.data.email !== null ? responseData.data.email : ''
      });
    }
  };

  // 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    const { userInfo } = this.props;
    switch (userInfo.editStatus) {
    case 'checkPassword':
      this.fetchCheckPassword();
      break;
    case 'modifyUserInfo':
      this.handleModifyUserInfo();
      break;
    case 'changePassword':
      this.handleModifyPassword();
      break;
    default:
      break;
    }
    userInfo.set('passwordHint', 'normal');
  };

  // 비밀번호 변경 데이터 검증
  handleModifyPassword = async () => {
    const { userInfo, miniAlertModal } = this.props;
    // eslint-disable-next-line no-useless-escape
    const pwdRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+=?.,:;/₩\-`~{}\\\[\]\|]).{8,30}$/;
    if (!pwdRegex.test(userInfo.data.password)) {
      miniAlertModal.show('비밀번호는 영문,숫자, 특수문자를\n포함하여 8자리 이상이여야 합니다.');
      return;
    }
    if (userInfo.data.password !== userInfo.data.confirmPassword) {
      miniAlertModal.show('비밀번호가 다릅니다.\n다시 확인해주세요.');
      return;
    }
    // 비밀번호를 제외한 다른 데이터는 동일하므로 유저 정보 수정 API 호출 함수 사용
    this.fetchModifyUserInfo();
  };

  // 유저 정보 수정 데이터 검증
  handleModifyUserInfo = async () => {
    const { userInfo, miniAlertModal } = this.props;
    // eslint-disable-next-line no-useless-escape
    const pwdRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+=?.,:;/₩\-`~{}\\\[\]\|]).{8,30}$/;
    const telRegex = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    const emailRegex = /^[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}$/;
    if (userInfo.data.name === '' || userInfo.data.tel === '' || userInfo.data.email === '') {
      miniAlertModal.show('비밀번호를 제외한 정보를 모두 입력해주세요.');
      return;
    }
    if (userInfo.data.password !== '' && !pwdRegex.test(userInfo.data.password)) {
      miniAlertModal.show('비밀번호는 영문,숫자, 특수문자를\n포함하여 8자리 이상이여야 합니다.');
      return;
    }
    if (userInfo.data.password !== userInfo.data.confirmPassword) {
      miniAlertModal.show('비밀번호가 다릅니다.\n다시 확인해주세요.');
      return;
    }
    if (!telRegex.test(userInfo.data.tel.split('-').join('')) || userInfo.data.tel.length < 11) {
      miniAlertModal.show('전화번호가 올바르지 않습니다.');
      return;
    }
    if (!emailRegex.test(userInfo.data.email)) {
      miniAlertModal.show('올바른 이메일 주소를 입력해주세요.');
      return;
    }
    this.fetchModifyUserInfo();
  };

  // 유저 정보 수정 API 호출
  fetchModifyUserInfo = async () => {
    const { userInfo, loader, miniAlertModal } = this.props;
    loader.show(true);
    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/users/myprofile';
    const body =
      userInfo.data.password === ''
        ? {
          userId: userInfo.data.id,
          username: userInfo.data.companyName,
          name: userInfo.data.name,
          tel: userInfo.data.tel,
          email: userInfo.data.email
        }
        : {
          userId: userInfo.data.id,
          username: userInfo.data.companyName,
          name: userInfo.data.name,
          tel: userInfo.data.tel,
          email: userInfo.data.email,
          password: userInfo.data.password
        };

    const responseData = await requestPut(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      userInfo.set('expireType', '');
      userInfo.set('resetOption', '');
      miniAlertModal.show('정상적으로 수정되었습니다.');
      await this.fetchGetUserInfo();
      userInfo.set('editStatus', 'checkPassword');
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  // 패스워드 체크 API 호출
  fetchCheckPassword = async () => {
    const { miniAlertModal, userInfo, loader } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/users/checkpassword';
    const body = {
      password: userInfo.data.password
    };

    const responseData = await requestPost(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      userInfo.set('data', {
        ...userInfo.data,
        password: '',
        confirmPassword: ''
      });
      userInfo.set('editStatus', 'modifyUserInfo');
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  // 엔터 키 입력 이벤트 처리
  handleEnterKeyPress = e => {
    if (e.charCode === 13) {
      this.handleSubmitButtonClick();
    }
  };

  // 비밀번호 정규식 체크
  handleCheckValidPassword = () => {
    const { userInfo } = this.props;
    // eslint-disable-next-line no-useless-escape
    const pwdRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+=?.,:;/₩\-`~{}\\\[\]\|]).{8,30}$/;
    let status = 'normal';
    if (userInfo.data.password.length < 1) {
      status = 'normal';
    } else if (pwdRegex.test(userInfo.data.password)) {
      status = 'valid';
    } else {
      status = 'invalid';
    }
    userInfo.set('passwordHint', status);
  };

  // Input 입력 이벤트 처리
  handleInputChanged = async e => {
    if (e.currentTarget.value !== e.target.value) {
      const promptReact = false;
      this.handlePrompt(promptReact);
    }
    const { userInfo } = this.props;
    await userInfo.set('data', {
      ...userInfo.data,
      [e.target.name]: e.target.value
    });
  };

  handlePrompt = (promptReact) => {
    const { userInfo } = this.props;
    if (this.state.promptOpen === true) {
      if (userInfo.resetOption === '') {
        this.setState({ promptOpen: false, alertOpen: false });
        return false;
      }
      this.setState({ alertOpen: true });
      this.setState({ alertOpen: false });
      return false;
    }
    if (!promptReact) {
      return false;
    }
    return false;
  }

  render() {
    const { userInfo, loader, miniAlertModal } = this.props;
    return (
      <>
        {loader.showLoader && <Loader/>}
        {this.state.promptOpen && <Prompt message={this.handlePrompt} when={this.handlePrompt} />}
        {this.state.alertOpen && alert('비밀번호 변경 후 서비스를 이용하실 수 있습니다')}
        <div className="__homeBackground __mypage-font-size">
          <Container>
            <div className="content">
              <Row>
                <Col md="1" />
                <Col md="10">
                  <Card>
                    <CardBody className="__textAlignCenter __mypage-font-size">
                      <Row className="form-horizontal">
                        <Label md="2"></Label>
                        <Col md="8">
                          <CardTitle>
                            {userInfo.expireType === '' ? '마이페이지' : null}
                            {(userInfo.expireType === 'TEMP_PWD' || userInfo.expireType === 'PWDW') ? '비밀번호 변경' : null}
                            {userInfo.expireType === 'PWD' ? '만료된 비밀번호' : null}
                            {userInfo.expireType === 'ACC' ? '계정 잠금 해제' : null}
                          </CardTitle>
                        </Col>
                        <Label md="2"></Label>
                      </Row>
                      <Row>
                        <Col md="12">
                          <p className="__cardMarginBottom">
                            {userInfo.expireType === 'TEMP_PWD' ? '임시 비밀번호를 새 비밀번호로 설정합니다.' : null}
                            {(userInfo.expireType === 'PWD' || userInfo.expireType === 'PWDW') ? '새 비밀번호를 설정합니다.' : null}
                            {userInfo.expireType === 'ACC' ? '만료된 계정으로 비밀번호를 변경하여 활성화합니다.' : null}
                          </p>
                        </Col>
                      </Row>
                      <Row className="form-horizontal">
                        <Label className="__mypage-label" md="3">
                          회사명
                        </Label>
                        <Col md="7">
                          <FormGroup className="__mypage-form-group">
                            <Input
                              readOnly={toJS(userInfo.isReadOnly.companyName)}
                              name="companyName"
                              className="__textAlignCenter __mypage-font-size"
                              type="text"
                              value={userInfo.data.companyName}
                            />
                          </FormGroup>
                        </Col>
                        <Label md="1"></Label>
                      </Row>
                      <Row className="form-horizontal">
                        <Label className="__mypage-label" md="3">
                          아이디
                        </Label>
                        <Col md="7">
                          <FormGroup className="__mypage-form-group">
                            <Input
                              readOnly={toJS(userInfo.isReadOnly.id)}
                              name="id"
                              className="__textAlignCenter __mypage-font-size"
                              type="text"
                              value={userInfo.data.id}
                            />
                          </FormGroup>
                        </Col>
                        <Label md="1"></Label>
                      </Row>
                      <Row className="form-horizontal">
                        <Label className="__mypage-label" md="3">
                          {userInfo.editStatus === 'checkPassword' ? '비밀번호' : '새 비밀번호'}
                        </Label>
                        <Col md="7">
                          <FormGroup className="__mypage-form-group">
                            <Input
                              readOnly={toJS(userInfo.isReadOnly.password)}
                              name="password"
                              className="__textAlignCenter __mypage-font-size"
                              type="password"
                              autoComplete="off"
                              onChange={async e => {
                                // await userInfo.set('data', {
                                //   ...userInfo.data,
                                //   [e.target.name]: e.target.value
                                // });
                                await this.handleInputChanged(e);
                                this.handleCheckValidPassword();
                              }}
                              value={userInfo.data.password}
                              onKeyPress={this.handleEnterKeyPress}
                            />
                          </FormGroup>
                          {userInfo.editStatus !== 'checkPassword' ? (
                            <FormText className={'__mypage-form-text __mypage-password-hint-' + userInfo.passwordHint}>
                              영문, 숫자, 특수문자를 포함하여 8자리 이상이여야 합니다.
                            </FormText>
                          ) : null}
                        </Col>
                        <Label md="1"></Label>
                      </Row>
                      {userInfo.editStatus === 'changePassword' ? (
                        <>
                          <Row className="form-horizontal">
                            <Label className="__mypage-label" md="3">
                              비밀번호 재확인
                            </Label>
                            <Col md="7">
                              <FormGroup className="__mypage-form-group">
                                <Input
                                  readOnly={toJS(userInfo.isReadOnly.confirmPassword)}
                                  name="confirmPassword"
                                  className="__textAlignCenter __mypage-font-size"
                                  type="password"
                                  autoComplete="off"
                                  onChange={this.handleInputChanged}
                                  value={userInfo.data.confirmPassword}
                                  onKeyPress={this.handleEnterKeyPress}
                                />
                              </FormGroup>
                            </Col>
                            <Label md="1"></Label>
                          </Row>
                        </>
                      ) : null}
                      {userInfo.editStatus === 'modifyUserInfo' ? (
                        <>
                          <Row className="form-horizontal">
                            <Label className="__mypage-label" md="3">
                              비밀번호 재확인
                            </Label>
                            <Col md="7">
                              <FormGroup className="__mypage-form-group">
                                <Input
                                  readOnly={toJS(userInfo.isReadOnly.confirmPassword)}
                                  name="confirmPassword"
                                  className="__textAlignCenter __mypage-font-size"
                                  type="password"
                                  autoComplete="off"
                                  onChange={this.handleInputChanged}
                                  value={userInfo.data.confirmPassword}
                                  onKeyPress={this.handleEnterKeyPress}
                                />
                              </FormGroup>
                            </Col>
                            <Label md="1"></Label>
                          </Row>
                          <Row className="form-horizontal">
                            <Label className="__mypage-label" md="3">
                              담당자명
                            </Label>
                            <Col md="7">
                              <FormGroup className="__mypage-form-group">
                                <Input
                                  readOnly={toJS(userInfo.isReadOnly.name)}
                                  name="name"
                                  className="__textAlignCenter __mypage-font-size"
                                  type="text"
                                  maxLength={10}
                                  onChange={this.handleInputChanged}
                                  value={userInfo.data.name}
                                  onKeyPress={this.handleEnterKeyPress}
                                />
                              </FormGroup>
                            </Col>
                            <Label md="1"></Label>
                          </Row>
                          <Row className="form-horizontal">
                            <Label className="__mypage-label" md="3">
                              Tel
                            </Label>
                            <Col md="7">
                              <FormGroup className="__mypage-form-group">
                                <Input
                                  readOnly={toJS(userInfo.isReadOnly.tel)}
                                  name="tel"
                                  className="__textAlignCenter __mypage-font-size"
                                  type="text"
                                  maxLength={13}
                                  onChange={this.handleInputChanged}
                                  value={autoPhoneFormatter(userInfo.data.tel)}
                                  onKeyPress={this.handleEnterKeyPress}
                                />
                              </FormGroup>
                            </Col>
                            <Label md="1"></Label>
                          </Row>
                          <Row className="form-horizontal">
                            <Label className="__mypage-label" md="3">
                              회사 E-mail
                            </Label>
                            <Col md="7">
                              <FormGroup className="__mypage-form-group">
                                <Input
                                  readOnly={toJS(userInfo.isReadOnly.email)}
                                  name="email"
                                  className="__textAlignCenter __mypage-font-size"
                                  type="email"
                                  maxLength={30}
                                  onChange={this.handleInputChanged}
                                  value={userInfo.data.email}
                                  onKeyPress={this.handleEnterKeyPress}
                                />
                              </FormGroup>
                            </Col>
                            <Label md="1"></Label>
                          </Row>
                        </>
                      ) : null}
                      <Row>
                        <Col md="12">
                          <Button onClick={this.handleSubmitButtonClick} className="btn-round" color="info">
                            {userInfo.buttonTitle[userInfo.editStatus]}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="1" />
              </Row>
            </div>
          </Container>

          {/* 알림 팝업 */}
          {miniAlertModal.isOpen && <MiniAlert />}
        </div>
      </>
    );
  }
}

export default UserInfo;
