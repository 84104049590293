import React, { Fragment } from 'react';
import Select from 'react-select';
import { Button, Modal, Table, Row, Col, FormGroup, Input } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import Logger from 'utils/logger';
import { requestPut } from 'utils/fetchHelper';
import { isValidResponse } from 'utils/responseValidChecker';
import { getUserType } from 'utils/tokenHelper';
import { autoBssidFormatter, autoRssiFormatter } from 'utils/fomatter';

// 위치인증 Ap 수정 모달
@inject('ap', 'searchFilter', 'miniAlertModal', 'loader')
@observer
class ModifyApModal extends React.Component {
  toggleModal = () => {
    const { ap } = this.props;
    ap.set('modifyModalIsOpen', !ap.modifyModalIsOpen);
  };

  // 수정 버튼 클릭 이벤트 처리
  handleSubmitButtonClick = () => {
    const { ap, miniAlertModal } = this.props;
    const apData = toJS(ap.editAps);
    if (apData.type === 'NFC') {
      // NFC - SSID null 체크 제외
      if (apData.department === '' || apData.bssid === '') {
        miniAlertModal.show('빈 항목이 있습니다.');
        return false;
      }
    } else {
      // WiFi 및 BLE Beacon - RSSI 필터 값 외 모든 입력 값 null 체크
      if (apData.department === '' || apData.ssid === '' || apData.bssid === '') {
        miniAlertModal.show('빈 항목이 있습니다.');
        return false;
      }
    }

    if (apData.bssid.length <= 16) {
      miniAlertModal.show('BSSID 형식이 올바르지 않습니다.');
      return false;
    }

    this.fetchModifyAps();
  };

  // 위치 인증 Ap 수정 API 처리
  fetchModifyAps = async () => {
    const { ap, searchFilter, miniAlertModal, loader, fetchRefresh } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/devices';
    const body = {
      devices: [
        {
          deviceId: ap.editAps.deviceId,
          companyId: searchFilter.selectCompany.value,
          departmentId: ap.editAps.department.value,
          type: ap.editAps.type,
          ssid: ap.editAps.ssid,
          bssid: ap.editAps.bssid,
          rssiFilter: ap.editAps.rssiFilter !== '' ? parseInt(ap.editAps.rssiFilter) : null,
          useYn: 'Y',
          deletedYn: 'N'
        }
      ]
    };
    const responseData = await requestPut(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      this.toggleModal();
      ap.clearModifyAps();
      await searchFilter.set('page', 1);
      fetchRefresh();
      miniAlertModal.show('수정되었습니다.');
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  renderTableRow = () => {
    let { ap, searchFilter } = this.props;
    return (
      <Fragment>
        <tr>
          <td>
            <FormGroup>
              <Select
                className="react-select __tableInputSelect __tableInputSelectColor"
                classNamePrefix="react-select"
                name="editDepartment"
                value={ap.editAps.department}
                onChange={data => {
                  ap.setEditAps('editAps', 'department', data, null);
                }}
                placeholder="지점을 선택하세요."
                options={searchFilter.departmentList.length > 1 ? searchFilter.departmentList.slice(1) : []}
              />
            </FormGroup>
          </td>
          <td>
            <div className="__tableInputSelect">
              <FormGroup>
                <Input
                  className="__textAlignCenter __inline-input"
                  type="text"
                  name="ssid"
                  value={ap.editAps.ssid}
                  onChange={e => {
                    ap.setEditAps('editAps', 'ssid', e.target.value);
                  }}
                  maxLength={32}
                />
              </FormGroup>
            </div>
          </td>
          <td className="text-center">
            <div className="__tableInputSelect">
              <FormGroup>
                <Input
                  className="__textAlignCenter __inline-input"
                  type="text"
                  name="bssid"
                  value={ap.editAps.bssid}
                  onChange={e => {
                    ap.setEditAps('editAps', 'bssid', autoBssidFormatter(e.target.value.replace(/[^a-f0-9]/gi, '')));
                  }}
                  maxLength={17}
                />
              </FormGroup>
            </div>
          </td>
          {((getUserType().slice(0, 2) === 'SA' ||
            getUserType() === 'CDSU') && (
              <td className="text-center">
                <div className="__tableInputSelect">
                  <FormGroup>
                    <Input
                      className="__textAlignCenter __inline-input"
                      type="text"
                      name="rssiFilter"
                      value={ap.editAps.rssiFilter}
                      onChange={e => {
                        ap.setEditAps('editAps', 'rssiFilter', autoRssiFormatter(e.target.value.replace(/[^0-9]+/,'')));
                      }}
                      maxLength={4}
                    />
                  </FormGroup>
                </div>
              </td>
            ))}
        </tr>
      </Fragment>
    );
  };

  render() {
    const { ap } = this.props;
    return (
      <Modal className="modal-lg" backdrop={'static'} isOpen={ap.modifyModalIsOpen} toggle={this.toggleModal}>
        <div className="modal-header justify-content-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModal}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">디바이스수정</h4>
        </div>
        <div className="modal-body __paddingModalBody">
          <Row>
            <Col md="12">
              <Table bordered>
                <thead>
                  <tr className="text-center">
                    {getUserType().slice(0, 2) === 'SA' || getUserType() === 'CDSU' ? (
                      <>
                        <th width="25%">지점 *</th>
                        <th width="35%">SSID *</th>
                        <th width="30%">BSSID *</th>
                        <th width="10%">RSSI</th>
                      </>
                    ) : (
                      <>
                        <th width="25%">지점 *</th>
                        <th width="40%">SSID *</th>
                        <th width="35%">BSSID *</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>{this.renderTableRow()}</tbody>
              </Table>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.handleSubmitButtonClick}
            >
              수정
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button className="btn-link" color="danger" type="button" onClick={this.toggleModal}>
              닫기
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

ModifyApModal.defaultProps = {
  fetchRefresh: async () => {
    Logger.log('[ModifyApModal] - refresh function is undefined');
  }
};

export default ModifyApModal;
