import React from 'react';
import Select from 'react-select';
import Loader from 'views/components/loader/Loader';
import buildUrl from 'build-url';
import Pagination from 'views/components/pagination/CustomPagination';
import { Button, Card, CardBody, CardFooter, Label, FormGroup, Form, Input, Row, Col } from 'reactstrap';
import { requestGet, requestDelete, requestPut } from 'utils/fetchHelper';

import { CELL_TABLE_HEADER } from 'commons/conts/tableHeader';
import Table from 'views/components/table/checkbox/table';

import RegisterCellTowerModal from './Register';
import ModifyTelecomModal from './Modify';

import ConfirmAlert from 'views/components/alert/confirmAlert/ConfirmAlert';
import MiniAlert from 'views/components/alert/miniAlert/MiniAlert';

import CompanyFilter from 'views/components/searchFilter/CompanyFilter';
import DepartmentFilter from 'views/components/searchFilter/DepartmentFilter';
import TelecomFilter from 'views/components/searchFilter/TelecomFilter';

import { isValidResponse } from 'utils/responseValidChecker';

import { toJS } from 'mobx';
import { observer, inject } from 'mobx-react';

import TotalCount from 'views/components/common/totalCount/TotalCount';
import { getUserType } from 'utils/tokenHelper';


@inject('searchFilter', 'cell', 'confirmAlertModal', 'miniAlertModal', 'loader', 'table')
@observer
class CellTower extends React.Component {
  radioAll = '';

  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { searchFilter } = this.props;
    this.radioAll.checked = true;
    await searchFilter.set('page', 1);
    this.props.searchFilter.companyList.length === 0 && (await this.props.searchFilter.getCompanyList());
    this.props.searchFilter.setTelecomList('select');
    await this.fetchRefresh();
  }

  componentWillUnmount() {
    const { searchFilter, cell, table } = this.props;
    table.set('selectedData', []);
    cell.set('data', null);
    searchFilter.clear();
  }

  // 등록 기지국 조회 API 호출
  fetchRefresh = async () => {
    const { searchFilter, miniAlertModal, loader, cell, table } = this.props;
    loader.show(true);

    let queryParams = {};
    queryParams.companyId = searchFilter.selectCompany.value;
    searchFilter.selectDepartment.value !== '' && (queryParams.departmentId = searchFilter.selectDepartment.value);
    searchFilter.selectTelecom.value !== '' && (queryParams.telecom = searchFilter.selectTelecom.value);
    searchFilter.lac !== '' && (queryParams.lac = searchFilter.lac);
    searchFilter.cellId !== '' && (queryParams.cellId = searchFilter.cellId);
    searchFilter.useYn = 'Y';
    searchFilter.insertType !== '' && (queryParams.insertType = searchFilter.insertType);
    queryParams.page = searchFilter.page !== '' ? searchFilter.page : 1;
    queryParams.size = searchFilter.pageSize.value !== '' ? searchFilter.pageSize.value : 10;

    // dev-server 사용 시
    const url = buildUrl(process.env.REACT_APP_ENDPOINT, {
      path: '/v1/admin/api/cells',
      queryParams: queryParams
    });

    const responseData = await requestGet(url);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    const editedResponseData = await this.addCustomColumn(responseData); // 지점명 + 지점ID 처리
    cell.set('data', editedResponseData.data);
  };

  // 지점명(지점 ID) 컬럼 추가
  addCustomColumn = async response => {
    const editedContent = response.data.content.map(item => {
      return {
        ...item,
        customDepartmentInfo: item.departmentName + ' (' + item.departmentId + ')'
      };
    });
    response.data.content = editedContent;
    return response;
  };

  // 데이터 테이블 클릭 - 등록 기지국 수정 모달
  handleEditRowClick = async data => {
    if (getUserType().slice(2, 4) === 'RO') {
      return;
    }
    const { cell } = this.props;
    cell.setEditCells('editCells', null, null, {
      id: data.id,
      department: { value: data.departmentId, label: data.departmentName },
      telecom: { value: data.telecom, label: data.telecom },
      lac: data.lac !==null ? data.lac : '',
      cellId: data.cellId !== null ? data.cellId : ''
    });

    cell.set('modifyModalIsOpen', !cell.modifyModalIsOpen);
  };

  // 페이징 컴포넌트 콜백 함수
  handlePaginationCallback = async page => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', page);
    await this.fetchRefresh();
  };

  // 등록 버튼 클릭 이벤트 처리
  handleRegisterButtonClick = async () => {
    const { cell } = this.props;
    await cell.clearRegisterCells();
    cell.set('registerModalIsOpen', !cell.registerModalIsOpen);
  };

  // 삭제 버튼 클릭 이벤트 처리
  handleDeleteButtonClick = async () => {
    const { miniAlertModal, confirmAlertModal, table } = this.props;
    if (toJS(table.selectedData).length === 0) {
      miniAlertModal.show('삭제할 기지국를 선택해주세요.');
    } else {
      confirmAlertModal.show(
        '선택한 데이터를 삭제합니다.\n',
        '삭제된 데이터는 복원할 수 없습니다.\n그래도 삭제 하시겠습니까?',
        '',
        this.fetchDeleteCell
      );
    }
  };

  // 등록 기지국 삭제 API 호출
  fetchDeleteCell = async () => {
    const { miniAlertModal, loader, table, searchFilter } = this.props;
    loader.show(true);

    const url = process.env.REACT_APP_ENDPOINT + '/v1/admin/api/cells';
    const body = {
      cells: table.selectedData.map(cell => {
        return {
          companyId: searchFilter.selectCompany.value,
          id: cell.id
        };
      })
    };
    let responseData = await requestDelete(url, body);
    loader.show(false);

    await isValidResponse(responseData, miniAlertModal, this.props.history);

    if (responseData.code === '0000') {
      miniAlertModal.show(`삭제되었습니다. 
      
      성공: ${responseData.data.registCellCount}건, SKIP: ${responseData.data.skipCellCount}건`);
      await searchFilter.set('page', 1);
      this.fetchRefresh();
    } else {
      miniAlertModal.show(responseData.message);
    }
  };

  // 검색 조건 엔터키 입력 이벤트 처리
  handleEnterKeyPress = async e => {
    const { searchFilter } = this.props;
    if (e.key === 'Enter') {
      await searchFilter.set('page', 1);
      this.fetchRefresh();
    }
  };

  // 등록 유형 자동/수동 필터 라디오 버튼 클릭 이벤트 처리
  handleRadioChange = async e => {
    const { searchFilter } = this.props;
    const insertType = e.target.value;
    await searchFilter.set('insertType', insertType);
    await searchFilter.set('page', 1);
    this.fetchRefresh();
  };

  // 검색 버튼 클릭 이벤트 처리
  handleSearchClick = async () => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    this.fetchRefresh();
  };

  // 페이지 당 검색 결과 수 변경 이벤트 함수
  handlePageSizeChanged = async e => {
    const { searchFilter } = this.props;
    await searchFilter.set('page', 1);
    await searchFilter.setPageSize(e);
    await this.fetchRefresh();
  };

  render() {
    let { confirmAlertModal, miniAlertModal, loader, cell, searchFilter } = this.props;
    return (
      <>
        {loader.showLoader && <Loader></Loader>}
        <div className="content">
          <h4>기지국 관리</h4>
          <h6>기지국의 정보를 확인하실 수 있습니다.</h6>
          <Card className="card-stats">
            <CardBody className="__padding">
              <Form className="form-horizontal">
                <Row md="12">
                  <Col md={{ size: 10, offset: 1 }} className="text-center">
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        회사명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <CompanyFilter refreshData={this.fetchRefresh}></CompanyFilter>
                        </FormGroup>
                      </Col>
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        지점명
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <FormGroup>
                          <DepartmentFilter refreshData={this.fetchRefresh}></DepartmentFilter>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Label md="2" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        통신사
                      </Label>
                      <Col md="2" sm="8" xs="8">
                        <FormGroup>
                          <TelecomFilter refreshData={this.fetchRefresh}></TelecomFilter>
                        </FormGroup>
                      </Col>
                      <Label md="1" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        CELL ID
                      </Label>
                      <Col md="2" sm="8" xs="8">
                        <FormGroup>
                          <Input
                            className="__textAlignCenter"
                            type="text"
                            value={this.props.searchFilter.cellId}
                            onChange={e => {
                              this.props.searchFilter.set('cellId', e.target.value);
                            }}
                            onKeyPress={this.handleEnterKeyPress}
                          />
                        </FormGroup>
                      </Col>
                      <Label md="1" sm="4" xs="4" className="text-right text-dark label-font-10 font-weight-bold">
                        등록 유형
                      </Label>
                      <Col md="4" sm="8" xs="8">
                        <div className="form-check-radio form-check-inline">
                          <Label className="form-check-label">
                            <Input
                              innerRef={ref => (this.radioAll = ref)}
                              type="radio"
                              name="near"
                              onChange={this.handleRadioChange}
                              value=""
                            />
                            전체
                            <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div className="form-check-radio form-check-inline">
                          <Label className="form-check-label">
                            <Input type="radio" name="near" onChange={this.handleRadioChange} value="A" />
                            자동
                            <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div className="form-check-radio form-check-inline">
                          <Label className="form-check-label">
                            <Input type="radio" name="near" onChange={this.handleRadioChange} value="M" />
                            수동
                            <span className="form-check-sign" />
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="12">
                        <Button className="btn-round" color="primary" onClick={this.handleSearchClick}>
                          조회
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="form-horizontal __marginBottom5">
                <Col md="12" className="text-right">
                  <div className="__inline-div">
                    <Select
                      className="react-select __pageSelect"
                      classNamePrefix="react-select"
                      name="pageSize"
                      placeholder=""
                      value={searchFilter.pageSize}
                      options={searchFilter.pageSizeList}
                      onChange={this.handlePageSizeChanged}
                    />
                    <Label className="label-page">페이지 사이즈</Label>
                    <TotalCount totalElements={cell.data && cell.data.totalElements} />
                  </div>
                </Col>
              </Row>
              <Row className="form-horizontal">
                <Col md="12">
                  <Table header={CELL_TABLE_HEADER} data={cell.data} handleRowClick={this.handleEditRowClick} />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={{ offset: 3, size: 6 }}>
                  {cell.data && (
                    <Pagination
                      currentPage={searchFilter.page}
                      totalPages={cell.data.totalPages}
                      pageSize={cell.data.pageable.pageSize}
                      callback={this.handlePaginationCallback}
                    />
                  )}
                </Col>
              </Row>
              {getUserType().slice(2, 4) !== 'RO' ? (
                <Row>
                  <Col lg="12" xl="12" className="text-right">
                    <div className="__inline-div __button-margin">
                      <Button
                        className="btn-round __marginTop"
                        onClick={this.handleRegisterButtonClick}
                        color="primary"
                      >
                        등록
                      </Button>
                    </div>
                    <div className="__inline-div __button-margin">
                      <Button
                        className="btn-round __marginTop __button-margin"
                        onClick={this.handleDeleteButtonClick}
                        color="primary"
                      >
                        삭제
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                null
              )}
            </CardFooter>
          </Card>

          {/* 등록 모달 */}
          {cell.registerModalIsOpen && <RegisterCellTowerModal {...this.props} fetchRefresh={this.fetchRefresh} />}

          {/* 수정 모달 */}
          {cell.modifyModalIsOpen && <ModifyTelecomModal {...this.props} fetchRefresh={this.fetchRefresh} />}

          {/* 알림 팝업 */}
          {miniAlertModal.isOpen && <MiniAlert />}

          {/* 사용자 확인 알림 모달 */}
          {confirmAlertModal.isOpen && <ConfirmAlert />}
        </div>
      </>
    );
  }
}

export default CellTower;

