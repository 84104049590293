import { observable, action } from 'mobx';
import { getCompanyList, getDepartmentList, getResultCodeList, getUserType, getJobNameList, getUserList, getHostNameList } from 'utils/tokenHelper';

// 검색 조건
export class MobxStore {
  constructor() {
    this.companyList = [];
    this.departmentList = [];
    this.resultCodeList = [];
    this.userTypeList = [];
    this.telecomList = [];
    this.jobNameList = []; // (리스트는 jobName, params는 jobCode)
    this.jobStatusList = [];
    this.userList = [];
    this.hostNameList = [];
    this.pageSizeList = [
      { label: 10, value: 10 },
      { label: 50, value: 50 },
      { label: 100, value: 100 },
      { label: 200, value: 200 }
    ];

    this.selectCompany = {
      label: '',
      value: ''
    };

    this.selectDepartment = {
      label: '',
      value: ''
    };

    this.selectResultCode = {
      label: '',
      value: ''
    };

    this.selectUserType = {
      label: '',
      value: ''
    };

    this.selectTelecom = {
      label: '',
      value: ''
    };

    this.selectJobCode = {
      label: '',
      value: ''
    };

    this.selectJobStatus = {
      label: '',
      value: ''
    };

    this.selectUser = {
      label: '',
      value: ''
    };

    this.selectHostName = {
      label: '',
      value: ''
    };

    this.phone = '';
    this.txid = '';
    this.startDate = '';
    this.endDate = '';
    this.ipAddress = '';
    this.nearStatus = '';
    this.authStatus = '';
    this.adidStatus = '';
    this.superPassStatus = '';
    this.ssid = '';
    this.bssid = '';
    this.unUsedTime = new Date();
    this.battery = '';
    this.deviceType = 'WIFI';
    this.lac = '';
    this.cellId = '';
    this.page = 1;
    this.pageSize = {
      label: 10,
      value: 10
    };
  }

  @observable companyList; // 기업 리스트
  @observable departmentList; // 지점 리스트
  @observable resultCodeList; // resultCode 리스트
  @observable userTypeList; // resultCode 리스트
  @observable telecomList; // 통신사 리스트
  @observable jobNameList; // Job name 리스트 (리스트는 jobName, params는 jobCode)
  @observable jobStatusList; // Job status 리스트
  @observable userList; // 유저 ID(이름) 리스트
  @observable hostNameList; // 인증 이력 hostName 리스트
  @observable pageSizeList; // 페이지 당 검색 결과 출력 개수 리스트
  @observable selectCompany; // 기업명
  @observable selectDepartment; // 지점명
  @observable selectResultCode; // 선택한 resultCode
  @observable selectUserType; // 선택한 resultCode
  @observable selectTelecom; // 선택한 resultTelecom
  @observable selectJobCode; // 선택한 jobName (리스트는 jobName, params는 jobCode)
  @observable selectJobStatus; // 선택한 jobStatus
  @observable selectUser; // 선택한 user
  @observable selectHostName; // 선택한 hostname
  @observable phone; // 휴대폰 번호
  @observable txid; // TXID
  @observable startDate; // 조회 시작일
  @observable endDate; // 조회 종료일
  @observable ipAddress; // ip 주소
  @observable nearStatus; // 인증 현황 근처 상태 여부
  @observable insertType; // 등록 유형 자동/수동 여부
  @observable authStatus; // 인증 상태
  @observable adidStatus; // 가가 식별자 보유 여부
  @observable superPassStatus; // 슈퍼패스 지정 여부
  @observable ssid; // SSID
  @observable bssid; // BSSID
  @observable unUsedTime; // 미사용 기간 (주 단위)
  @observable battery; // 배터리 잔량 (BLE Beacon)
  @observable deviceType; // 위치인증 Ap 타입
  @observable lac; // 기지국의 Location Area Code
  @observable cellId; // 기지국 정보
  @observable page; // 페이지 번호
  @observable pageSize; // 페이지당 검색 결과 수

  // 기업 리스트 갱신
  @action getCompanyList = async () => {
    const __companyList = await getCompanyList(process.env.REACT_APP_ENDPOINT);
    if (__companyList.length > 0) {
      this.companyList = __companyList;
      this.selectCompany = {
        label: __companyList[0].label,
        value: __companyList[0].value
      };
      this.getDepartmentList();
    }
  };

  // 지점 리스트 갱신
  @action getDepartmentList = async () => {
    const __departmentList = await getDepartmentList(process.env.REACT_APP_ENDPOINT, this.selectCompany.value);
    __departmentList.unshift({ label: '전체 지점', value: '' });
    if (__departmentList.length > 0) {
      this.departmentList = __departmentList;
      this.selectDepartment = {
        label: __departmentList[0].label,
        value: __departmentList[0].value
      };
    }
  };

  @action getResultCodeList = async () => {
    const __resultCodeList = await getResultCodeList();
    if (__resultCodeList.length > 0) {
      this.resultCodeList = __resultCodeList;
      this.selectResultCode = {
        label: __resultCodeList[0].label,
        value: __resultCodeList[0].value
      };
    }
  };

  @action setUserTypeList = type => {
    if (getUserType() === 'SARW') {
      this.userTypeList = [
        { label: '엘핀 관리자', value: 'SARW' },
        { label: '엘핀 운영자', value: 'SARO' },
        { label: '관리자', value: 'CDSU' },
        { label: '운영자(수정 가능)', value: 'CDRW' },
        { label: '운영자(읽기 전용)', value: 'CDRO' }
      ];
    } else {
      this.userTypeList = [
        { label: '관리자', value: 'CDSU' },
        { label: '운영자(수정 가능)', value: 'CDRW' },
        { label: '운영자(읽기 전용)', value: 'CDRO' }
      ];
    }
    if (type === 'select') {
      this.userTypeList.unshift({ label: '전체 조회', value: '' });
    }
    this.selectUserType = this.userTypeList[0];
  };

  @action setTelecomList = type => {
    this.telecomList = [
      { label: 'SKT', value: 'SKT' },
      { label: 'KT', value: 'KT' },
      { label: 'LGT', value: 'LGT' }
    ];
    if (type === 'select') {
      this.telecomList.unshift({ label: '전체 조회', value: '' });
    }
    this.selectTelecom = this.telecomList[0];
  };

  @action getJobNameList = async (type) => {
    const groupId = 'J001';
    const __jobNameList = await getJobNameList(groupId);
    if (__jobNameList.length > 0) {
      this.jobNameList = __jobNameList;
      if (type === 'select') {
        this.jobNameList.unshift({ label: '전체 조회', value: '' });
      }
      this.selectJobCode = this.jobNameList[0];
    }
  };

  @action setJobStatusList = async (type) => {
    const groupId = 'J002';
    const __jobStatusList = await getJobNameList(groupId);
    if (__jobStatusList.length > 0) {
      this.jobStatusList = __jobStatusList;
      if (type === 'select') {
        this.jobStatusList.unshift({ label: '전체 조회', value: '' });
      }
      this.selectJobStatus = this.jobStatusList[0];
    }
  };

  @action getUserList = async () => {
    const __userList = await getUserList(process.env.REACT_APP_ENDPOINT, this.selectCompany.value);
    __userList.unshift({ label: '전체 조회', value: 'ALL' });
    if (__userList.length > 0) {
      this.userList = __userList;
      this.selectUser = {
        label: __userList[0].label,
        value: __userList[0].value
      };
    }
  };

  @action getHostNameList = async () => {
    const __hostNameList = await getHostNameList(process.env.REACT_APP_ENDPOINT, this.selectCompany.value);
    __hostNameList.unshift({ label: '전체 조회', value: '' });
    if (__hostNameList.length > 0) {
      this.hostNameList = __hostNameList;
      this.selectHostName = {
        label: __hostNameList[0].label,
        value: __hostNameList[0].value
      };
    }
  };

  // 검색 조건 변경
  @action set = (key, value) => {
    this[key] = value;
  };

  // 페이지당 검색 결과 수 변경
  @action setPageSize = size => {
    this.pageSize = {
      label: size.label,
      value: size.value
    };
  };

  // 검색 조건 초기화(기업, 지점, 페이지당 검색 결과 수 제외)
  @action clear = async () => {
    this.phone = '';
    this.txid = '';
    this.startDate = '';
    this.endDate = '';
    this.ipAddress = '';
    this.nearStatus = '';
    this.authStatus = '';
    this.adidStatus = '';
    this.superPassStatus = '';
    this.nearStatus = '';
    this.insertType = '';
    this.ssid = '';
    this.bssid = '';
    this.unUsedTime = new Date();
    this.battery = '';
    this.deviceType = 'WIFI';
    this.lac = '';
    this.cellId = '';
    this.page = 1;
  };
}
